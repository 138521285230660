import React, { Component, Fragment } from "react";
import { db } from "apis/config";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CSPTabs from "components/Admin/CSPTabs";
import { gProducts } from "actions/landing";
import Loader from "components/Admin/Loader";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import tdotLogo from "assets/img/csp/logo-tdot.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// import Button from "components/CustomButtons"
import ReactGA from "react-ga";
import IntlMessages from "util/IntlMessages";
import CustomHelmet from "pages/CustomHelmet";
import Button from "components/CustomButtons";
import Slider from "react-slick";
const EMPTY_CONTENT = "<p><br></p>";

class ProductDetail extends Component {
  state = {
    list: "",
    seo: "",
    current: ""
  };

  componentDidMount() {
    this.onLoad(this.props.match.params.id);
    this.onLoadHead(this.props.match.params.id);
    ReactGA.pageview(window.location.pathname);
  }

  async onLoad(id) {
    await this.props.gProducts(id);
    this.setState({ list: this.props.list });
  }

  async onLoadHead(id) {
    const res = await db
      .collection("partner")
      .doc(id)
      .get();
    const data = res.data();
    this.setState({ seo: data });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ list: "", seo: "" });
      this.onLoad(this.props.match.params.id);
      this.onLoadHead(this.props.match.params.id);
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (this.props.match.params.id !== nextProps.match.params.id) {
  //     this.setState({ list: "", seo: "" });
  //     this.onLoad(nextProps.match.params.id);
  //     this.onLoadHead(nextProps.match.params.id);
  //   }
  // }

  handleClickImage = image => {
    this.setState({
      current: image
    });
  };

  handleCloseModal = () => {
    this.setState({ current: "" });
  };

  onRenderBanner = list => {
    const { locale } = this.props;
    return list.map((item, key) => {
      return (
        <div className="product-banner" key={key}>
          <GridContainer alignItems="center">
            <GridItem xs={12} sm={6}>
              <div className="product-banner-image">
                <img
                  className="img-fluid"
                  src={item.banner_img}
                  alt={item.id}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <div className="product-banner-detail">
                <h3 className="title">{item[locale].banner_title}</h3>
                <p className="text">{item[locale].banner_sub}</p>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      );
    });
  };

  onCheckContent = content => {
    let bool = true;
    if (!content || content === "" || content === EMPTY_CONTENT) {
      bool = false;
    }
    return bool;
  };

  settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <Button justIcon>
        <i className="fas fa-angle-right"></i>
      </Button>
    ),
    prevArrow: (
      <Button justIcon>
        <i className="fas fa-angle-left"></i>
      </Button>
    )
  };

  onRenderColumn = list => {
    if (list) {
      const index = Math.ceil(list.length / 2);

      const first = list.slice(0, index);
      const second = list.slice(index);

      return (
        <GridItem xs={12} sm={8}>
          <div className="technical-table">
            <div className="technical-table-column">
              {first.map((row, idx) => (
                <div className="technical-table-row" key={idx}>
                  <div className="title">{row.table_title}</div>
                  <div className="text">{row.table_text}</div>
                </div>
              ))}
            </div>
            <div className="technical-table-column">
              {second.map((row, idx) => (
                <div className="technical-table-row" key={idx}>
                  <div className="title">{row.table_title}</div>
                  <div className="text">{row.table_text}</div>
                </div>
              ))}
            </div>
          </div>
        </GridItem>
      );
    }
  };

  onRenderList = list => {
    const { locale } = this.props;
    if (list) {
      return list.map(prop => {
        const images = prop.images || [];
        return {
          tabButton: prop[locale].name,
          tabContent: (
            <div className="tab-product-detail">
              <GridContainer
                alignItems="center"
                className="tab-product-detail-row"
              >
                {prop.brand === "Id9NjztLRCxLld3UDvcN" && (
                  <GridItem xs={12}>
                    <img
                      src={tdotLogo}
                      alt="tdot-logo"
                      className="img-fluid mb-3"
                    />
                  </GridItem>
                )}
                <GridItem xs={12} md={6}>
                  <div className="blue-heading">
                    <p className="text">{prop[locale].machine_type}</p>
                    <h4 className="title">{prop[locale].p_name}</h4>
                  </div>
                  <div className="description">{prop[locale].desc}</div>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <img
                    src={prop.thumbnail}
                    alt={prop.id}
                    className="img-fluid"
                    onClick={() => this.handleClickImage(prop.thumbnail)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="center"
                className="tab-product-detail-row"
              >
                {prop.brand === "Id9NjztLRCxLld3UDvcN" && (
                  <Fragment>
                    <GridItem xs={12}>
                      <h4 className="tab-product-title">
                        <IntlMessages id="product.options" />
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <div
                        className="option-data"
                        dangerouslySetInnerHTML={{
                          __html: prop[locale].option_data
                        }}
                      ></div>
                    </GridItem>
                  </Fragment>
                )}
              </GridContainer>
              <GridContainer
                justify="center"
                className="tab-product-detail-row"
              >
                <GridItem xs={12}>
                  <h4 className="tab-product-title">
                    <IntlMessages id="product.advantages" />
                  </h4>
                </GridItem>
                {this.onCheckContent(prop[locale].advantages_note) && (
                  <GridItem xs={12} md={8}>
                    <div
                      className="advantage-note"
                      dangerouslySetInnerHTML={{
                        __html: prop[locale].advantages_note
                      }}
                    />
                  </GridItem>
                )}
                {this.onCheckContent(prop[locale].advantages_up) && (
                  <GridItem xs={12}>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: prop[locale].advantages_up
                      }}
                    />
                  </GridItem>
                )}
                {this.onCheckContent(prop[locale].advantages_down) && (
                  <GridItem xs={12}>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: prop[locale].advantages_down
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
              {prop.dimension_plane_img && (
                <GridContainer
                  justify="center"
                  className="tab-product-detail-row"
                >
                  <GridItem xs={12}>
                    <h4 className="tab-product-title">
                      <IntlMessages id="product.dimension" />
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={8}>
                    <div
                      className="dimension-image"
                      onClick={() =>
                        this.handleClickImage(prop.dimension_plane_img)
                      }
                    >
                      <img
                        src={prop.dimension_plane_img}
                        alt={prop.id}
                        className="img-fluid"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              )}
              <GridContainer
                justify="center"
                className="tab-product-detail-row"
              >
                <GridItem xs={12}>
                  <h4 className="tab-product-title">
                    {this.props.match.params.id === "R9TqcnxPl3qUC3u6Tn0i" ? (
                      <IntlMessages id="product.application.bullmer" />
                    ) : (
                      <IntlMessages id="product.application" />
                    )}
                  </h4>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  {this.onCheckContent(prop[locale].application_content) && (
                    <div className="application-content">
                      {prop[locale].application_content}
                    </div>
                  )}
                  <div className="application-images">
                    <GridContainer justify="center">
                      {images.length < 7
                        ? images.map((item, key) => (
                            <GridItem
                              xs={12}
                              md={6}
                              lg={4}
                              key={key}
                              className="img-row"
                            >
                              <img
                                src={item.src}
                                alt={item.name}
                                className="img-fluid"
                                onClick={() => this.handleClickImage(item.src)}
                              />
                            </GridItem>
                          ))
                        : images.map((item, key) => (
                            <GridItem
                              xs={12}
                              md={6}
                              lg={3}
                              key={key}
                              className="img-row"
                            >
                              <img
                                src={item.src}
                                alt={item.name}
                                className="img-fluid"
                                onClick={() => this.handleClickImage(item.src)}
                              />
                            </GridItem>
                          ))}
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer
                justify="center"
                className="tab-product-detail-row"
              >
                <GridItem xs={12}>
                  <h4 className="tab-product-title">
                    <IntlMessages id="product.technical" />
                  </h4>
                </GridItem>
                {this.onRenderColumn(prop[locale].table_data)}
              </GridContainer>
              {prop.brochure && (
                <GridContainer
                  justify="center"
                  className="tab-product-detail-row"
                >
                  <GridItem xs={12} className="text-center">
                    <a
                      href={prop.brochure}
                      className="btn-blue-link"
                      target="blank"
                    >
                      {prop[locale].name} Brochure.pdf{" "}
                    </a>
                  </GridItem>
                </GridContainer>
              )}
            </div>
          )
        };
      });
    }
  };

  onRenderPreviewImageTag = list => {
    if (list) {
      const index = Math.floor(Math.random() * list.length);
      return list[index].banner_img;
    }
  };

  render() {
    const { list, seo, current } = this.state;

    if (!list) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      );
    }

    return (
      <section className="product-detail-page">
        {seo && (
          <CustomHelmet
            detail={seo}
            imageSrc={this.onRenderPreviewImageTag(list)}
          ></CustomHelmet>
        )}
        <section className="product-detail-slider">
          {current && (
            <Lightbox
              mainSrc={current}
              onCloseRequest={this.handleCloseModal}
            />
          )}
          <Slider {...this.settings} className="product-slick">
            {this.onRenderBanner(list)}
          </Slider>
          <div className="csp-container">
            <div className="nav-heading">
              <Breadcrumbs aria-label="breadcrumb" separator=".">
                <Link to="/" className="nav-heading-menu">
                  <IntlMessages id="home.menu.index" />
                </Link>
                <Link to="/about/partner" className="nav-heading-menu">
                  <IntlMessages id="home.menu.product" />
                </Link>
                <div className="nav-heading-breadcrumb">
                  {list[0].brand_name}
                </div>
              </Breadcrumbs>
            </div>
          </div>
        </section>
        <section className="product-detail-list bg-white">
          <CSPTabs
            id={this.props.match.params.id}
            tabs={this.onRenderList(list)}
          />
        </section>
      </section>
    );
  }
}
const mapStateToProps = ({ settings, landing }, ownProps) => {
  return {
    locale: settings.locale.locale,
    list: landing[`product-${ownProps.match.params.id}`]
  };
};
export default connect(
  mapStateToProps,
  { gProducts }
)(ProductDetail);
