import React from "react";

export default function ChatSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__whatsapp"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <circle
        id="prefix__Ellipse_41"
        cx="22"
        cy="22"
        r="22"
        fill="#fff"
        data-name="Ellipse 41"
      />
      <g
        id="prefix__Group_430"
        data-name="Group 430"
        transform="translate(8.901 8.893)"
      >
        <path
          id="prefix__Path_179"
          d="M256.4-152.15a12.573 12.573 0 0 0-3.943.55 12.9 12.9 0 0 0-6.545 4.463 12.859 12.859 0 0 0-2.591 5.909 12.915 12.915 0 0 0 .057 4.6 13.464 13.464 0 0 0 1.235 3.552 1.5 1.5 0 0 1 .1.744 4.046 4.046 0 0 1-.191.748q-.727 2.675-1.45 5.35c-.075.277-.012.337.26.269q1.344-.335 2.692-.66c1.06-.256 2.122-.5 3.181-.763a1.991 1.991 0 0 1 1.449.071 12.431 12.431 0 0 0 5.591 1.239 11.929 11.929 0 0 0 5.024-1.036 12.818 12.818 0 0 0 7.919-11.066 12.138 12.138 0 0 0-.846-5.635 13.04 13.04 0 0 0-11.942-8.335zm10.5 14.166a10.628 10.628 0 0 1-3.276 6.677 10.579 10.579 0 0 1-5.234 2.765 10.663 10.663 0 0 1-4.268.062 10.155 10.155 0 0 1-3.35-1.262 1.359 1.359 0 0 0-1.122-.16c-1.043.289-2.092.552-3.14.824-.087.022-.178.034-.329.063.1-.379.183-.714.272-1.048.189-.707.385-1.412.568-2.121a2.085 2.085 0 0 0-.271-1.585 10.86 10.86 0 0 1-1.371-4.4 10.792 10.792 0 0 1 6.326-10.814 10.339 10.339 0 0 1 5.682-.872 10.581 10.581 0 0 1 8.627 6.316 10.2 10.2 0 0 1 .886 5.555z"
          className="prefix__cls-2"
          data-name="Path 179"
          transform="translate(-243.046 152.153)"
        />
        <path
          id="prefix__Path_180"
          d="M260.815-138.034c-.718-.352-1.439-.7-2.159-1.044-.554-.265-.746-.23-1.148.244-.286.337-.552.69-.831 1.032a.5.5 0 0 1-.7.179 8.793 8.793 0 0 1-4.461-3.837.551.551 0 0 1 .057-.752c.212-.268.428-.533.624-.812a.834.834 0 0 0 .123-.809q-.527-1.309-1.086-2.6a.679.679 0 0 0-.3-.318 1.745 1.745 0 0 0-1.841.317 3.877 3.877 0 0 0-1.173 2.782 4.211 4.211 0 0 0 .677 2.241 15.437 15.437 0 0 0 5.23 5.476 9.6 9.6 0 0 0 4.607 1.465 7.054 7.054 0 0 0 .9-.189 2.873 2.873 0 0 0 1.993-2.38c.101-.561.01-.739-.512-.995z"
          className="prefix__cls-2"
          data-name="Path 180"
          transform="translate(-241.486 153.836)"
        />
      </g>
    </svg>
  );
}
