import { CLIENT } from "actions/constant";

const initialState = {
  list: "",
  op_list: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLIENT.ADD_CLIENT:
      return { ...state };

    case CLIENT.GET_CLIENT:
      return { ...state, list: payload };

    case CLIENT.GET_CLIENT_DETAIL:
      return { ...state, [payload.id]: payload };

    case CLIENT.DELETE_CLIENT:
      return { ...state };

    case CLIENT.UPDATE_CLIENT:
      return { ...state };

    case CLIENT.GET_OP_CLIENT:
      return { ...state, op_list: payload };

    case CLIENT.ADD_OP_CLIENT:
      return { ...state };

    case CLIENT.UPDATE_OP_CLIENT:
      return { ...state };

    case CLIENT.DELETE_OP_CLIENT:
      return { ...state };

    default:
      return state;
  }
};
