import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "cogo-toast";
// MATERIAL
import { CardActionArea, Tooltip, makeStyles } from "@material-ui/core";
import { Edit, Category } from "@material-ui/icons";
// COMPONENT
import Player from "components/Admin/Player";
import Loader from "components/Admin/Loader";
import Dock from "components/Admin/Dock";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import FormItem from "./FormItem";
import styles from "assets/jss/m-dashboard/views/dashboardStyle";
import { getVideos, updateVideo } from "actions/admin/video";
import { TOAST_OPTIONS } from "helpers";

const useStyles = makeStyles(styles);

export default function ListVideo() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const list = useSelector(({ video }) => video.list);
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState("");
  const [detail, setDetail] = useState({
    name: "",
    url: ""
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function onFetch() {
      try {
        dispatch(getVideos());
      } catch (error) {
        console.log("On Fetch failed", error.message);
      }
    }
    onFetch();
  }, [dispatch]);

  const renderVideos = list => {
    return list.map((prop, key) => {
      return (
        <GridItem xs={12} sm={6} lg={4} key={key}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <CardActionArea onClick={() => handleOpen(prop)}>
                <div className="video-wrapper">
                  <div className="overlay-video"></div>
                  <Player url={prop.url} height={240} playing={false} />
                </div>
              </CardActionArea>
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  title="Cập nhật"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="primary"
                    simple
                    justIcon
                    onClick={() => handleOpen(prop)}
                  >
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
            </CardBody>
            <CardFooter product>
              <div className="video-info">
                <Category />
                <span className="video-name">{prop.name}</span>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      );
    });
  };

  const handleClose = () => {
    setOpen(false);
    setUid("");
    setDetail({
      name: "",
      url: ""
    });
  };

  const handleOpen = prop => {
    setDetail({
      name: prop.name,
      url: prop.url
    });
    setUid(prop.id);
    setOpen(true);
  };

  const handleSubmit = value => {
    setLoading(true);
    try {
      dispatch(updateVideo(uid, value)).then(async () => {
        await dispatch(getVideos());
        handleClose();
        toast.success("Video đã được cập nhật", TOAST_OPTIONS);
        setLoading(false);
      });
    } catch (error) {
      handleClose();
      toast.success(error.message, TOAST_OPTIONS);
      setLoading(false);
    }
  };

  if (!list) {
    return <Loader />;
  }
  return (
    <div>
      <Dock
        open={open}
        handleClose={handleClose}
        title={detail.name ? detail.name.toUpperCase() : ""}
        maxWidth="md"
      >
        <div className="position-relative">
          {loading && (
            <div className="full-loading">
              <Loader />
            </div>
          )}
          {detail && (
            <FormItem
              detail={detail}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
            />
          )}
        </div>
      </Dock>
      <GridContainer>{renderVideos(list)}</GridContainer>
    </div>
  );
}
