import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
import ContentForm from "components/Admin/Form/BaseForm/Content";
import Loader from "components/Admin/Loader";
import { TOAST_OPTIONS } from "helpers";
import { getContent, updateContent } from "actions/admin/company";
// INTRO TEAM - ABOUT
const INTRO_ID = "5Chxq1tVK8MkDzqJJsb4";

class IntroHR extends Component {
  state = {
    loading: true,
    content: ""
  };

  async componentDidMount() {
    await this.props.getContent(INTRO_ID);
    this.setState({ loading: false, content: this.props[INTRO_ID] });
  }

  onSubmit = value => {
    this.setState({ loading: true });
    this.props
      .updateContent(INTRO_ID, value)
      .then(async () => {
        await this.props.getContent(INTRO_ID);
        this.setState({ loading: false, content: this.props[INTRO_ID] });
        toast.success(`Nội dung đã được cập nhật`, TOAST_OPTIONS);
      })
      .catch(err => {
        toast.error(err.message, TOAST_OPTIONS);
      });
  };

  render() {
    const { loading, content } = this.state;
    return (
      <div id={INTRO_ID}>
        {loading ? (
          <div className="full-loading">
            <Loader />
          </div>
        ) : null}
        {content ? (
          <Fragment>
            <ContentForm
              label="Nội dung tiếng việt"
              nameInput="content_vi"
              initialValues={this.props[INTRO_ID]}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="Nội dung tiếng anh"
              nameInput="content_en"
              initialValues={this.props[INTRO_ID]}
              onSubmit={this.onSubmit}
            />
          </Fragment>
        ) : (
          <div className="m-3" />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ company }) => {
  return {
    [INTRO_ID]: company[INTRO_ID]
  };
};
export default connect(
  mapStateToProps,
  { getContent, updateContent }
)(IntroHR);
