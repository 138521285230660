import React, { useState, useEffect } from "react";
import { Close, Save } from "@material-ui/icons";
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons";
import Player from "components/Admin/Player";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

export default function FormItem(props) {
  const { detail, handleSubmit, handleClose } = props;
  const [inputValues, setInputValues] = useState({
    url: ""
  });

  useEffect(() => {
    if (detail) {
      setInputValues({
        url: detail.url
      });
    }
  }, [detail]);

  const handleChange = event => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmit = event => {
    event.preventDefault();
    handleSubmit(inputValues);
  };

  return (
    <form onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12}>
          <Player url={inputValues.url} />
        </GridItem>
        <GridItem xs={12}>
          <Input
            labelText="ĐƯỜNG DẪN VIDEO"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              name: "url",
              onChange: handleChange,
              value: inputValues.url,
              fullWidth: true,
              multiline: true
            }}
          />
        </GridItem>
      </GridContainer>
      <div className="actions-wrap">
        <div className="text-right">
          <Button color="danger" simple onClick={handleClose}>
            <Close />
            <span className="btn-text">Đóng</span>
          </Button>
          <Button color="success" simple type="submit">
            <Save />
            <span className="btn-text">Lưu</span>
          </Button>
        </div>
      </div>
    </form>
  );
}
