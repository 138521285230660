import React from "react";
import { Helmet } from "react-helmet";

export default function CustomHelmet({ detail, imageSrc }) {
  return (
    <Helmet>
      <title>{detail.title}</title>
      {/* Primary Meta Tags */}
      <title>{detail.title}</title>
      <meta name="title" content={detail.title} />
      <meta name="description" content={detail.meta_desc} />
      <meta name="keywords" content={detail.meta_key} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title" content={detail.title} />
      <meta property="og:description" content={detail.meta_desc} />
      <meta property="og:image" content={imageSrc} />
      {/* Twitter */}
      <meta property="twitter:card" content="cspco" />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:title" content={detail.title} />
      <meta property="twitter:description" content={detail.meta_desc} />
      <meta property="twitter:image" content={imageSrc} />
    </Helmet>
  );
}
//    <meta property="og:image" content={metaDecorator.hostname + imageUrl} />
