import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Vender from "assets/img/csp/temp/ic-vender.svg";
import Cost from "assets/img/csp/temp/ic-cost.svg";
import Demand from "assets/img/csp/temp/ic-demand.svg";
import Accuracy from "assets/img/csp/temp/ic-accuracy.svg";
import FadeInSection from "components/_App/FadeInSection";
import IntlMessages from "util/IntlMessages";

export default function Widget() {
  return (
    <FadeInSection>
      <section className="optitex-widget">
        <div className="csp-container widget-box">
          <GridContainer justify="center" className="widget-wrap">
            <GridItem xs={12} sm={6} md={3} className="widget-item">
              <div className="wic">
                <img src={Vender} alt="vender" />
              </div>
              <div className="text">
                <IntlMessages id="service.widget.01" />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3} className="widget-item">
              <div className="wic">
                <img src={Cost} alt="Cost" />
              </div>
              <div className="text">
                <IntlMessages id="service.widget.02" />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3} className="widget-item">
              <div className="wic">
                <img src={Demand} alt="Demand" />
              </div>
              <div className="text">
                <IntlMessages id="service.widget.03" />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3} className="widget-item">
              <div className="wic">
                <img src={Accuracy} alt="Accuracy" />
              </div>
              <div className="text">
                <IntlMessages id="service.widget.04" />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </section>
    </FadeInSection>
  );
}
