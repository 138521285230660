import React, { Component } from "react";
import { connect } from "react-redux";
// MATERIAL
import {
  withStyles,
  AppBar,
  IconButton,
  Typography,
  Toolbar
} from "@material-ui/core";
import { PowerSettingsNew, Menu } from "@material-ui/icons";
import styles from "assets/jss/components/topBarStyles";
// COMPONENT
import Button from "components/CustomButtons";
import { onSignOut } from "actions/admin/auth";

class TopBar extends Component {
  toggleDrawer = () => this.props.toggleDrawer();
  onSignOut = () => this.props.onSignOut();
  render() {
    const { classes } = this.props;
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.root}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={this.toggleDrawer}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" className={classes.fontBar}>
            dashboard
          </Typography>
          <Button
            size="sm"
            color="danger"
            className={classes.btnLogout}
            onClick={this.onSignOut}
          >
            <PowerSettingsNew className="m-0" />
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
}

export default connect(
  null,
  {
    onSignOut
  }
)(withStyles(styles)(TopBar));
