import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
// import IntlMessages from "util/IntlMessages";
import Header from "components/landing/Header";
import Footer from "components/landing/Footer";
import AppLocale from "lngProvider";
import OurServices from "pages/Landing/Services";

class ServiceLayout extends Component {
  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <div className="landing-page">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Header />
          <Switch>
            <Route path="/" component={OurServices} />
          </Switch>
          <Footer />
        </IntlProvider>
      </div>
    );
  }
}
const mapStateToProps = ({ settings }) => {
  return {
    locale: settings.locale
  };
};
export default connect(mapStateToProps)(ServiceLayout);
