import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Flags from "country-flag-icons/react/3x2";
import _ from "lodash";
import { Card, Tooltip } from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
// COMPONENT
import Button from "components/CustomButtons";
import Loader from "components/Admin/Loader";
import Modal from "components/Admin/Modal";
import defaultImage from "assets/img/image_placeholder.jpg";
import { getOPClient, deleteClientOP } from "actions/admin/client";
import { TABLE_CONFIG, TOAST_OPTIONS, validURL } from "helpers";
import CreateOP from "./CreateOP";
import DetailOp from "./DetailOp";

class OptitexClientList extends Component {
  state = {
    loading: true,
    addState: false,
    detailState: false,
    detail: null,
    lang: null
  };

  btnConfig = {
    justIcon: true,
    simple: true
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  async onLoad() {
    await this.props.getOPClient();
    this.endLoader();
  }

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  handleOpen = state => {
    this.setState({
      [`${state}State`]: true
    });
  };

  handleClose = () => {
    this.setState({
      addState: false,
      detailState: false,
      detail: null,
      lang: null
    });
  };

  handleDelete = async prop => {
    this.onLoader();
    try {
      await this.props.deleteClientOP(prop.id);
      await this.props.getOPClient();
      toast.success(`Khách hàng ${prop.name} đã được xóa`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  handleEdit = async (prop, lang) => {
    const client_data = _.omit(prop, ["vi", "en"]);
    const dataVersion = _.pick(prop, [lang]);
    const version = dataVersion[lang];
    const detail = {
      ...client_data,
      ...version
    };
    this.setState({
      detailState: true,
      detail,
      lang
    });
  };

  renderList = () => {
    const { list } = this.props;
    if (list) {
      return list.map(prop => {
        return {
          logo: (
            <Card>
              <img
                src={validURL(prop.logo) ? prop.logo : defaultImage}
                alt={`logo-${prop.name}`}
                className="img-fluid"
              />
            </Card>
          ),
          name: (
            <Button
              color="primary"
              simple
              className="btn-title"
              onClick={() => this.handleEdit(prop.id)}
            >
              {prop.name}
            </Button>
          ),
          comment: prop.active ? "HIỂN THỊ" : "KHÔNG HIỂN THỊ",
          language: (
            <div className="actions-left">
              <Tooltip title="Tiếng anh">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop, "en")}
                >
                  <Flags.US />
                </Button>
              </Tooltip>
              <Tooltip title="Tiếng việt">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop, "vi")}
                >
                  <Flags.VN />
                </Button>
              </Tooltip>
            </div>
          ),
          actions: (
            <div className="actions-right">
              <Tooltip title="Xóa khách hàng">
                <Button
                  {...this.btnConfig}
                  color="danger"
                  onClick={() => this.handleDelete(prop)}
                >
                  <Delete />
                </Button>
              </Tooltip>
            </div>
          )
        };
      });
    }
  };

  render() {
    const columns = [
      { Header: "Logo", accessor: "logo", width: 180 },
      { Header: "Tên khách hàng", accessor: "name", minWidth: 150 },
      { Header: "Nhận xét", accessor: "comment", width: 160 },
      { Header: "Ngôn Ngữ", accessor: "language", width: 150 },
      { Header: "Thao tác", accessor: "actions", width: 110 }
    ];
    const { loading, addState, detail, detailState, lang } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Modal
          open={addState}
          onClose={this.handleClose}
          title="KHÁCH HÀNG OPTITEX"
          transitionDuration={700}
          maxWidth="lg"
          fullWidth
        >
          <CreateOP handleClose={this.handleClose} />
        </Modal>
        {detail && (
          <Modal
            open={detailState}
            onClose={this.handleClose}
            title={`KHÁCH HÀNG OPTITEX - ${
              lang === "vi" ? "TIẾNG VIỆT" : "TIẾNG ANH"
            }`}
            transitionDuration={700}
            maxWidth="lg"
            fullWidth
          >
            <DetailOp
              handleClose={this.handleClose}
              detail={detail}
              lang={lang}
            />
          </Modal>
        )}
        <div className="mb-2 text-right">
          <Tooltip title="Thêm khách hàng">
            <Button
              justIconRec
              size="sm"
              color="success"
              onClick={() => this.handleOpen("add")}
            >
              <Add />
            </Button>
          </Tooltip>
        </div>
        <ReactTable
          {...TABLE_CONFIG}
          data={this.renderList()}
          defaultPageSize={10}
          columns={columns}
          loading={loading}
          loadingText={<Loader />}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ client }) => {
  return {
    list: client.op_list
  };
};

export default connect(
  mapStateToProps,
  {
    getOPClient,
    deleteClientOP
  }
)(OptitexClientList);
