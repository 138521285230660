export const COMPANY = {
  PUBLIC_HERO_BANNER: "PUBLIC_HERO_BANNER",
  PUBLIC_CONTACT: "PUBLIC_CONTACT",
  PUBLIC_CLIENT: "PUBLIC_CLIENT",
  PUBLIC_COMMENTS: "PUBLIC_COMMENTS",
  PUBLIC_PRODUCT: "PUBLIC_PRODUCT",
  PUBLIC_PARTNER: "PUBLIC_PARTNER",
  PUBLIC_CONTENT: "PUBLIC_CONTENT",
  PUBLIC_VIDEOS: "PUBLIC_VIDEOS",
  PUBLIC_NEWS: "PUBLIC_NEWS",
  PUBLIC_EVENTS: "PUBLIC_EVENTS",
  PUBLIC_OPTITEX_CATEGORY: "PUBLIC_OPTITEX_CATEGORY",
  PUBLIC_OPTITEX_CATEGORY_DETAIL: "PUBLIC_OPTITEX_CATEGORY_DETAIL"
};

export const SETTINGS = {
  SWITCH_LANGUAGE: "SWITCH_LANGUAGE"
};
