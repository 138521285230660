import { CLIENT } from "../constant";
import ADMIN_URL from "apis/AdminURL";
import _ from "lodash";

// ==== OTHER CLIENTS ====
export const getClient = () => async dispatch => {
  const response = await ADMIN_URL.get("/client/all");
  const payload = _.orderBy(response.data, "created_on", "desc");
  dispatch({
    type: CLIENT.GET_CLIENT,
    payload
  });
};
export const getClientDetail = (id, lang) => async dispatch => {
  const response = await ADMIN_URL.get(`/client/detail/${id}/${lang}`);
  dispatch({
    type: CLIENT.GET_CLIENT_DETAIL,
    payload: response.data
  });
};
export const addClient = dataValue => async dispatch => {
  await ADMIN_URL.post("/client/add", dataValue);
  dispatch({ type: CLIENT.ADD_CLIENT });
};
export const updateClient = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/client/${id}/${lang}`, dataValue);
  dispatch({ type: CLIENT.UPDATE_CLIENT });
};
export const deleteClient = id => async dispatch => {
  await ADMIN_URL.delete(`/client/${id}`);
  dispatch({ type: CLIENT.DELETE_CLIENT });
};

// ==== OPTITEX CLIENT ====
export const getOPClient = () => async dispatch => {
  const response = await ADMIN_URL.get("/client/optitex/all");
  const payload = _.orderBy(response.data, "created_on", "desc");
  dispatch({
    type: CLIENT.GET_OP_CLIENT,
    payload
  });
};
export const addClientOP = dataValue => async dispatch => {
  await ADMIN_URL.post("/client/optitex/add", dataValue);
  dispatch({ type: CLIENT.ADD_OP_CLIENT });
};
export const deleteClientOP = id => async dispatch => {
  await ADMIN_URL.delete(`/client/optitex/${id}`);
  dispatch({ type: CLIENT.DELETE_OP_CLIENT });
};
export const updateClientOP = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/client/optitex/${id}/${lang}`, dataValue);
  dispatch({ type: CLIENT.UPDATE_OP_CLIENT });
};
