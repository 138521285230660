import { IMAGE } from "../constant";
import { deleteStorage } from "helpers";
import ADMIN_URL from "apis/AdminURL";
export const getImages = () => async dispatch => {
  const response = await ADMIN_URL.get("/gallery/all");
  dispatch({
    type: IMAGE.GET_IMAGE,
    payload: response.data
  });
};
export const deletePhoto = (id, url_link) => async dispatch => {
  await ADMIN_URL.delete(`/gallery/${id}`);
  await deleteStorage(url_link);
  dispatch({ type: IMAGE.DELETE_IMAGE });
};
