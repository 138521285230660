import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles, FormControl } from "@material-ui/core";
import selectStyles from "assets/jss/m-dashboard/customSelectStyle";
import { Save, Add, KeyboardBackspace as Back } from "@material-ui/icons";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import Editor from "components/Admin/Form/Wyswyg";
import history from "history.js";
import defaultImage from "assets/img/image_placeholder.jpg";
import Heading from "components/Heading";

class PartnerForm extends Component {
  state = {
    lang: this.props.lang,
    logo: "",
    slogan: "",
    desc: "",
    name: "",
    content: "",
    title: "",
    meta_desc: "",
    meta_key: ""
  };

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues) {
      this.onLoad(initialValues);
    }
  }

  //   ON LOAD FIELD
  onLoad = prop => {
    this.setState({
      name: prop.name,
      content: prop.content,
      logo: prop.logo,
      slogan: prop.slogan,
      desc: prop.desc,
      title: prop.title || "",
      meta_desc: prop.meta_desc || "",
      meta_key: prop.meta_key || ""
    });
  };
  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };
  renderEditor = ({ input, dataValue, placeholder, ...rest }) => {
    return (
      <FormControl fullWidth>
        <Editor
          {...rest}
          content={dataValue}
          placeholder={placeholder}
          onChange={editorState => {
            this.props.change(input.name, editorState);
          }}
        />
      </FormControl>
    );
  };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };
  onSubmit = dataValue => {
    this.props.submitForm(dataValue);
  };
  onBack = () => history.goBack();
  render() {
    const {
      name,
      content,
      logo,
      slogan,
      desc,
      lang,
      meta_desc,
      meta_key,
      title
    } = this.state;
    const { initialValues } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <div className="image-section">
          <GridContainer>
            <GridItem xs={12} lg={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <Card className="image-wrap">
                    <img src={logo || defaultImage} alt="represent" />
                  </Card>
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    component={this.renderField}
                    multiline
                    label="LOGO HÃNG"
                    name="logo"
                    dataValue={logo}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} lg={6}>
              <GridContainer>
                <GridItem xs={12}>
                  <Heading
                    title={`Đối tác: ${name} - Tiếng ${
                      lang === "vi" ? "Việt" : "Anh"
                    }`}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <Field
                    component={this.renderField}
                    label="SLOGAN"
                    name="slogan"
                    dataValue={slogan}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    component={this.renderField}
                    label="MÔ TẢ"
                    name="desc"
                    dataValue={desc}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    component={this.renderEditor}
                    placeholder="THÔNG TIN ĐỐI TÁC"
                    name="content"
                    dataValue={content}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <div className="mt-4">
                <Heading title="NỘI DUNG SEO" />
              </div>
              <Field
                component={this.renderField}
                label="TIÊU ĐỀ"
                name="title"
                dataValue={title}
              />
              <Field
                component={this.renderField}
                label="TỪ KHÓA"
                name="meta_key"
                dataValue={meta_key}
              />
              <Field
                component={this.renderField}
                label="MÔ TẢ"
                name="meta_desc"
                dataValue={meta_desc}
              />
            </GridItem>
          </GridContainer>
        </div>
        <div className="mBtn-wrap actions-wrap">
          <Button color="warning" simple onClick={this.onBack}>
            <Back />
            <span className="btn-text">quay lại</span>
          </Button>
          {initialValues ? (
            <Button type="submit" simple color="success">
              <Save />
              <span className="btn-text">Lưu</span>
            </Button>
          ) : (
            <Button type="submit" simple color="success">
              <Add />
              <span className="btn-text">tạo</span>
            </Button>
          )}
        </div>
      </form>
    );
  }
}
const styled = withStyles(selectStyles)(PartnerForm);
export default reduxForm({ form: "basePartner" })(styled);
