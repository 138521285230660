import React, { Component } from "react";
// import ReactTable from "react-table";
import { connect } from "react-redux";
// import toast from "cogo-toast";
import Flags from "country-flag-icons/react/3x2";
import _ from "lodash";
// MATERIAL
// import { Add, Delete, Edit } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
// COMPONENT
// import Loader from "components/Admin/Loader";
import Button from "components/CustomButtons";
import Modal from "components/Admin/Modal";
// import CategoryAdd from "./CategoryAdd";
import CategoryDetail from "./CategoryDetail";
// import { TABLE_CONFIG, TOAST_OPTIONS } from "helpers";
import { getCateOP, deleteCateOP } from "actions/admin/product";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

class CategoryOP extends Component {
  state = {
    loading: false,
    addState: false,
    detailState: false,
    detail: null,
    lang: null
  };

  btnConfig = {
    justIcon: true,
    simple: true
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  async onLoad() {
    await this.props.getCateOP();
    this.setState({ loading: false });
  }

  onRenderList = () => {
    const { list } = this.props;
    if (list) {
      return list.map((item, key) => {
        return (
          <GridItem xs={12} sm={4} key={key} className="category-optitex-item">
            <Card className="list-box category-optitex-box">
              <CardBody>
                <div className="title">
                  <Button className="btn-title" color="white" simple size="lg">
                    {item.name}
                  </Button>
                </div>
                <div className="language">
                  <Tooltip title="Tiếng anh">
                    <Button
                      {...this.btnConfig}
                      onClick={() => this.handleEdit(item, "en")}
                    >
                      <Flags.US />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Tiếng việt">
                    <Button
                      {...this.btnConfig}
                      onClick={() => this.handleEdit(item, "vi")}
                    >
                      <Flags.VN />
                    </Button>
                  </Tooltip>
                  {/* <Tooltip title="Xóa">
                      <Button
                        {...this.btnConfig}
                        color="danger"
                        onClick={() => this.onDeleteCate(item)}
                      >
                        <Delete />
                      </Button>
                    </Tooltip> */}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        );
      });
    }
  };

  handleOpen = async state => {
    this.setState({ [`${state}State`]: true });
  };

  handleClose = () => {
    this.setState({
      addState: false,
      detailState: false,
      detail: null,
      lang: null
    });
  };

  handleEdit = async (prop, lang) => {
    const product_data = _.omit(prop, ["vi", "en"]);
    const dataVersion = _.pick(prop, [lang]);
    const version = dataVersion[lang];
    const detail = {
      ...product_data,
      ...version
    };
    this.setState({
      detailState: true,
      detail,
      lang
    });
  };

  // === DELETE CATE ===
  // onDeleteCate = async prop => {
  //   this.setState({ loading: true });
  //   try {
  //     await this.props.deleteCateOP(prop.id);
  //     await this.onLoad();
  //     toast.success("Mục sản phẩm đã được xóa", TOAST_OPTIONS);
  //   } catch (error) {
  //     this.setState({ loading: false });
  //     toast.error(error.message, TOAST_OPTIONS);
  //   }
  // };

  render() {
    // const columns = [
    //   { Header: "Tên danh mục", accessor: "name" },
    //   { Header: "Ngôn ngữ", accessor: "language", width: 150 },
    //   { Header: "Thao tác", accessor: "actions", width: 110 }
    // ];
    const {
      // loading, addState,
      detailState,
      detail,
      lang
    } = this.state;
    return (
      <div style={{ width: "100%" }}>
        {/* <Modal
          open={addState}
          onClose={this.handleClose}
          title="DANH MỤC CỦA OPTITEX"
          transitionDuration={700}
          maxWidth="md"
          fullWidth
        >
          <CategoryAdd handleClose={this.handleClose} />
        </Modal> */}
        {detail && (
          <Modal
            open={detailState}
            onClose={this.handleClose}
            title="DANH MỤC CỦA OPTITEX"
            transitionDuration={700}
            maxWidth="md"
            fullWidth
          >
            <CategoryDetail
              handleClose={this.handleClose}
              detail={detail}
              lang={lang}
            />
          </Modal>
        )}
        {/* <div className="mb-2 text-right">
          <Tooltip title="Thêm danh mục của Optitex">
            <Button
              justIconRec
              size="sm"
              color="success"
              onClick={() => this.handleOpen("add", "")}
            >
              <Add />
            </Button>
          </Tooltip>
        </div> */}
        <GridContainer>{this.onRenderList()}</GridContainer>
        {/* <ReactTable
          {...TABLE_CONFIG}
          data={this.renderList()}
          columns={columns}
          loading={loading}
          loadingText={<Loader />}
          defaultPageSize={5}
        /> */}
      </div>
    );
  }
}
const mapStateToProps = ({ product }) => {
  return {
    list: product.cate_list
  };
};

export default connect(
  mapStateToProps,
  {
    getCateOP,
    deleteCateOP
  }
)(CategoryOP);
