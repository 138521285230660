import React, { Component } from "react";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Map from "pages/Admin/Company/Info/components/Contact/Map";
import { getContact } from "actions/landing";
import IconHeading from "assets/img/csp/ic-heading.png";
import IntlMessages from "util/IntlMessages";
import FadeInSection from "components/_App/FadeInSection";
import Location from "assets/img/csp/ic-location-tran.png";
import Time from "assets/img/csp/ic-time.png";

class ContactPage extends Component {
  state = {
    contact: ""
  };

  componentDidMount() {
    this.onLoad();
  }

  async onLoad() {
    await this.props.getContact();
    this.setState({ contact: this.props.list });
  }

  onRender = list => {
    const { locale } = this.props;
    return list.map((prop, key) => {
      return (
        <div className="col-sm-12 col-md-6 mb-4" key={key}>
          <div className="iconic ic-location">
            <img src={Location} alt="location" className="img-fluid" />
          </div>
          <div className="contact-content">
            <p className="city">{prop[`city_${locale}`]}</p>
            <p className="address">
              {prop[`building_${locale}`]}, {prop[`address_${locale}`]}
            </p>
            <p className="telephone">
              <IntlMessages id="contact.string.tel" />: {prop.tel}
            </p>
            <p className="fax">Fax: {prop.fax}</p>
            <p className="tax-number">
              <IntlMessages id="contact.string.taxNumber" />: {prop.tax_number}
            </p>
            <p className="email">Email: {prop.email}</p>
          </div>
        </div>
      );
    });
  };

  render() {
    const { contact } = this.state;

    if (!contact) {
      return null;
    }
    return (
      <FadeInSection>
        <GridContainer>
          <GridItem xs={12} md={6} className="position-relative">
            <div className="edge-box">
              <div className="main-title-wrap rear-left">
                <h2 className="main-title contact-title">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                  <span>
                    <IntlMessages id="home.menu.contact" />
                  </span>
                </h2>
              </div>
              <div className="row contact-box_inner">
                {this.onRender(contact)}
              </div>
              <div className="contact-line" />
              <div className="contact-time">
                <div className="iconic ic-time">
                  <img src={Time} alt="time" className="img-fluid" />
                </div>
                <div className="contact-content ">
                  <p className="date">
                    <IntlMessages id="home.contact.weekday" />
                  </p>
                  <p className="time">8:00 - 17:00</p>
                </div>
                <div className="contact-content">
                  <p className="date">
                    <IntlMessages id="home.contact.weekend" />
                  </p>
                  <p className="time">8:00 - 12:00</p>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <Map places={contact} />
          </GridItem>
        </GridContainer>
      </FadeInSection>
    );
  }
}

const mapStateToProps = ({ landing, settings }) => {
  return {
    list: landing.contact,
    locale: settings.locale.locale
  };
};

export default connect(
  mapStateToProps,
  {
    getContact
  }
)(ContactPage);
