import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Loader from "components/Admin/Loader";
import BasePartner from "components/Admin/Form/BaseForm/Partner";
import history from "history.js";
import { getPartnerDetail, updatePartner } from "actions/admin/partner";
import { TOAST_OPTIONS } from "helpers";

class DetailPartner extends Component {
  state = {
    loading: false,
    id: this.props.match.params.id,
    lang: this.props.match.params.lang,
    detail: ""
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  onLoad = async () => {
    const { lang, id } = this.state;
    await this.props.getPartnerDetail(id, lang);
    this.setState({ detail: this.props.detail });
    this.endLoader();
  };

  onSubmit = async dataValue => {
    const { lang, id } = this.state;
    this.onLoader();
    try {
      await this.props.updatePartner(id, lang, dataValue);
      toast.success(`Thông tin đối tác đã được cập nhật`, TOAST_OPTIONS);
      history.push("/dashboard/partner");
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  render() {
    const { loading, lang, detail } = this.state;
    return (
      <Card>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        {detail ? (
          <CardBody>
            <BasePartner
              submitForm={this.onSubmit}
              initialValues={detail}
              lang={lang}
            />
          </CardBody>
        ) : (
          <CardBody className="p-5">
            <div className="full-loading">
              <Loader />
            </div>
          </CardBody>
        )}
      </Card>
    );
  }
}
const mapStateToProps = ({ partner }, ownProps) => {
  return {
    detail: partner[ownProps.match.params.id]
  };
};
export default connect(
  mapStateToProps,
  {
    getPartnerDetail,
    updatePartner
  }
)(DetailPartner);
