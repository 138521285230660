import { COMPANY } from "../constant";
import ADMIN_URL from "apis/AdminURL";
import { deleteStorage } from "helpers";
// --- HISTORY
export const getHistory = () => async dispatch => {
  const response = await ADMIN_URL.get(`/company/history`);
  dispatch({
    type: COMPANY.GET_HISTORY,
    payload: response.data
  });
};
export const updateHistory = (id, dataValue, lang) => async dispatch => {
  const response = await ADMIN_URL.put(
    `/company/history/${id}/${lang}`,
    dataValue
  );
  dispatch({ type: COMPANY.UPDATE_HISTORY });
  return response;
};
export const addHistory = dataValue => async dispatch => {
  const response = await ADMIN_URL.post(`/company/history/add`, dataValue);
  dispatch({ type: COMPANY.UPDATE_HISTORY });
  return response;
};
export const deleteHistory = id => async dispatch => {
  const response = await ADMIN_URL.delete(`/company/history/${id}`);
  dispatch({ type: COMPANY.DELETE_HISTORY });
  return response;
};

// --- CONTACT
export const getContact = () => async dispatch => {
  const response = await ADMIN_URL.get(`/company/contact`);
  dispatch({
    type: COMPANY.GET_CONTACT,
    payload: response.data
  });
};
export const updateContact = (id, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/company/contact/${id}`, dataValue);
  dispatch({ type: COMPANY.UPDATE_CONTACT });
};
// ----- EMPLOYEES
export const getEmployees = () => async dispatch => {
  const response = await ADMIN_URL.get(`/company/employees`);
  dispatch({
    type: COMPANY.GET_EMPLOYEE,
    payload: response.data
  });
};
export const addEmployee = obj => async dispatch => {
  await ADMIN_URL.post(`/company/employee`, obj);
  dispatch({ type: COMPANY.ADD_EMPLOYEE });
};
export const updateEmployee = (id, lang, obj) => async dispatch => {
  await ADMIN_URL.put(`/company/employee/${id}/${lang}`, obj);
  dispatch({ type: COMPANY.UPDATE_EMPLOYEE });
};
export const deleteEmployee = id => async dispatch => {
  await ADMIN_URL.delete(`/company/employee/${id}`);
  dispatch({ type: COMPANY.DELETE_EMPLOYEE });
};
// ----- BROCHURES
export const getBrochures = () => async dispatch => {
  const response = await ADMIN_URL.get("/company/brochures");
  dispatch({
    type: COMPANY.GET_BROCHURE,
    payload: response.data
  });
};
export const deleteBrochure = (id, url_link) => async dispatch => {
  await ADMIN_URL.delete(`/company/brochure/${id}`);
  await deleteStorage(url_link);
  dispatch({ type: COMPANY.DELETE_BROCHURE });
};
// BANNER
export const getHeroBanner = () => async dispatch => {
  const response = await ADMIN_URL.get(`/company/banner`);
  dispatch({
    type: COMPANY.GET_HERO_BANNER,
    payload: response.data
  });
};
export const updateHeroBanner = dataValue => async dispatch => {
  await ADMIN_URL.put(`/company/banner/update`, dataValue);
  dispatch({ type: COMPANY.UPDATE_HERO_BANNER });
};
// ---- CONTENT
export const getContent = id => async dispatch => {
  const response = await ADMIN_URL.get(`/company/content/${id}`);
  dispatch({
    type: COMPANY.GET_CONTENT,
    payload: response.data
  });
};
export const updateContent = (id, updateValue) => async dispatch => {
  await ADMIN_URL.put(`/company/content/${id}`, updateValue);
  dispatch({ type: COMPANY.UPDATE_CONTENT });
};
