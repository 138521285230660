import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
// MATERIAL
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { withStyles } from "@material-ui/core/styles";
import autoCompleteStyles from "assets/jss/Form/autoCompleteStyles";
// COMPONENT
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Input from "components/Admin/Form/Input";
import Loader from "components/Admin/Loader";

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = { address: props.address };
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    this.setState({
      address: prevProps.address
    });
  }

  handleChange = address => {
    this.props.handleGetAddress(address);
    this.setState({ address });
  };

  handleSelect = address => {
    this.props.handleGetAddress(address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.handleGetLatLng(latLng);
      })
      .catch(error => console.error("Error", error));
  };

  render() {
    const { address } = this.state;
    const { label, name, disabled, classes } = this.props;
    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={800}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="place-autocomplete">
            <Input
              multiline
              {...getInputProps({
                name,
                label: label || "Add a location",
                disabled
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <Loader />}
              {suggestions.map((suggestion, key) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    key={key}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <GridContainer container alignItems="center">
                      <GridItem>
                        <LocationOnIcon className={classes.icon} />
                      </GridItem>
                      <GridItem xs>
                        <Typography variant="body2">
                          {suggestion.description}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default withStyles(autoCompleteStyles)(AutoComplete);
