import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Save,
  Edit,
  KeyboardBackspace as Back,
  Visibility,
  Close
} from "@material-ui/icons";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons";
import Adornment from "components/Adornment";
import Modal from "components/Admin/Modal";
import defaultImage from "assets/img/image_placeholder.jpg";

class BaseContentWImg extends Component {
  state = {
    isEdit: true,
    open: false,
    [this.props.nameInput]: ""
  };

  componentDidMount() {
    if (this.props.initialValues) {
      this.setState({
        [this.props.nameInput]: this.props.initialValues[this.props.nameInput]
      });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  handleBack = () => this.setState({ isEdit: true });
  handleEdit = () => this.setState({ isEdit: false });

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  renderField = ({ input, label, disabled, dataValue }) => {
    return (
      <GridItem xs={12}>
        <Input
          labelText={label}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            name: input.name,
            onChange: this.handleChange,
            value: dataValue,
            fullWidth: true,
            multiline: true,
            disabled,
            rowsMax: "5",
            endAdornment: (
              <Adornment onClick={this.handleOpen}>
                <Visibility />
              </Adornment>
            )
          }}
        />
      </GridItem>
    );
  };

  onSubmit = async value => {
    const data = _.pick(value, [this.props.nameInput]);
    await this.props.onSubmit(data);
    this.handleBack();
  };

  render() {
    const { isEdit, open } = this.state;
    const { label, nameInput } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Modal
          open={open}
          onClose={this.handleClose}
          fullWidth
          transitionDuration={700}
        >
          <img
            alt="preview"
            src={this.state[nameInput] ? this.state[nameInput] : defaultImage}
            className="img-fluid"
          />
          <div className="mBtn-wrap">
            <Button simple color="danger" onClick={this.handleClose}>
              <Close />
              Đóng
            </Button>
          </div>
        </Modal>
        <GridContainer>
          <Field
            component={this.renderField}
            name={nameInput}
            label={label}
            dataValue={this.state[nameInput]}
            disabled={isEdit}
          />
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          {!isEdit ? (
            <Fragment>
              <Button color="warning" simple onClick={this.handleBack}>
                <Back />
                <span className="btn-text">trở lại</span>
              </Button>
              <Button color="success" simple type="submit">
                <Save />
                <span className="btn-text">Lưu</span>
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button color="primary" simple onClick={this.handleEdit}>
                <Edit />
                <span className="btn-text">CHỈNH sửa</span>
              </Button>
            </Fragment>
          )}
        </div>
      </form>
    );
  }
}
BaseContentWImg.propTypes = {
  label: PropTypes.string.isRequired,
  nameInput: PropTypes.string.isRequired
};
export default reduxForm({ form: "contentWImg" })(BaseContentWImg);
