import React, { Component } from "react";
// MATERIAL
import { Info, LocationCity, Timeline } from "@material-ui/icons";
// COMPONENT
import NavPills from "components/NavPills/NavPills";
import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";

import TimeLine from "./components/TimeLine";

class InfoCompany extends Component {
  render() {
    return (
      <NavPills
        color="primary"
        tabs={[
          {
            tabButton: "thông tin",
            tabIcon: Info,
            tabContent: <AboutUs />
          },
          {
            tabButton: "lịch sử",
            tabIcon: Timeline,
            tabContent: <TimeLine />
          },
          {
            tabButton: "liên hệ",
            tabIcon: LocationCity,
            tabContent: <Contact />
          }
        ]}
      />
    );
  }
}
export default InfoCompany;
