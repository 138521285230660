import React, { useEffect, useState } from "react";
import PURL from "apis/PublicURL";
import { useSelector } from "react-redux";
import optitexClient from "assets/img/csp/optitex-client.jpg";
import aboutOverlay from "assets/img/csp/about-overlay.png";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import IntlMessages from "util/IntlMessages";
import IconHeading from "assets/img/csp/ic-heading.png";
import FadeInSection from "components/_App/FadeInSection";
import Button from "components/CustomButtons";
import Slider from "react-slick";

export default function Client() {
  const [list, setList] = useState("");
  const locale = useSelector(({ settings }) => settings.locale.locale);

  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <Button justIcon>
        <i className="fas fa-angle-right"></i>
      </Button>
    ),
    prevArrow: (
      <Button justIcon>
        <i className="fas fa-angle-left"></i>
      </Button>
    )
  };

  useEffect(() => {
    async function onFetch() {
      const res = await PURL.get("/company/optitex/comments");
      setList(res.data.reverse());
    }
    onFetch();
  }, []);

  return (
    <FadeInSection>
      <section className="optitex-client">
        <div
          className="bg-section"
          style={{ backgroundImage: `url(${optitexClient})` }}
        >
          <img src={aboutOverlay} alt="bg_overlay" className="bg-overlay" />
          <div className="csp-container">
            <div className="main-title-wrap">
              <h2 className="main-title">
                <div className="img-wrap">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                </div>
                <span>
                  <IntlMessages id="optitex.client.text" />
                </span>
              </h2>
            </div>
            {list && (
              <Slider {...settings} className="optitex-client-slick">
                {list.map(item => (
                  <GridContainer
                    justify="center"
                    alignItems="center"
                    className="optitex-client-item"
                    key={item.id}
                  >
                    <GridItem xs={12} sm={8} lg={4}>
                      <div className="client-identity">
                        <div className="name">{item.deputy}</div>
                        <div className="role">{item.role}</div>
                        <div className="brand">{item.name}</div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} lg={4}>
                      <div className="client-logo">
                        <img
                          src={item.logo}
                          className="img-fluid"
                          alt={item.id}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={8} lg={4}>
                      <div
                        className="client-feedback"
                        dangerouslySetInnerHTML={{
                          __html: item[locale].comment
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}
