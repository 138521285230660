import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Loader from "components/Admin/Loader";
import ProductPart from "components/Admin/Form/BaseForm/ProductPart";
import { addPart, getParts } from "actions/admin/parts";
import { TOAST_OPTIONS } from "helpers";

class CreatePart extends Component {
  state = {
    loading: false
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onSubmit = async dataValue => {
    this.onLoader();
    try {
      await this.props.addPart(dataValue);
      await this.props.getParts();
      toast.success(`Sản phẩm đã được tạo thành công`, TOAST_OPTIONS);
      this.onClose();
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  onClose = () => {
    this.props.handleClose();
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <ProductPart submitForm={this.onSubmit} handleClose={this.onClose} />
      </div>
    );
  }
}
export default connect(
  null,
  { addPart, getParts }
)(CreatePart);
