import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "cogo-toast";
import { TOAST_OPTIONS } from "helpers";
import { getContent, updateContent } from "actions/admin/company";
import CardBody from "components/Card/CardBody";
import LineScaleLoader from "components/Admin/Loader";
import ContentForm from "components/Admin/Form/BaseForm/Content";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// ACHIEVEMENT - HOME ID
const ACHIEVEMENT_ID = "C1QEOgseyhg8haPIQXTK";

export default function Achievement() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const detail = useSelector(({ company }) => company[ACHIEVEMENT_ID]);

  useEffect(() => {
    async function onFetch() {
      dispatch(getContent(ACHIEVEMENT_ID));
    }
    onFetch();
  }, [dispatch]);

  const onSubmit = async value => {
    setLoading(true);
    try {
      await dispatch(updateContent(ACHIEVEMENT_ID, value));
      await dispatch(getContent(ACHIEVEMENT_ID));
      toast.success(`Nội dung đã được cập nhật`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    setLoading(false);
  };

  if (!detail) {
    return (
      <CardBody className="p-5">
        <div className="full-loading">
          <LineScaleLoader />
        </div>
      </CardBody>
    );
  }

  return (
    <div id={ACHIEVEMENT_ID} style={{ width: "100%" }}>
      {loading ? (
        <div className="full-loading">
          <LineScaleLoader />
        </div>
      ) : null}
      <ContentForm
        label="NỘI DUNG (EN)"
        nameInput="content_en"
        initialValues={detail}
        onSubmit={onSubmit}
      />
      <ContentForm
        label="NỘI DUNG (VI)"
        nameInput="content_vi"
        initialValues={detail}
        onSubmit={onSubmit}
      />
      <GridContainer>
        <GridItem xs={12} md={6} lg={3}>
          <ContentForm
            label="Năm"
            nameInput="years"
            initialValues={detail}
            onSubmit={onSubmit}
          />
        </GridItem>
        <GridItem xs={12} md={6} lg={3}>
          <ContentForm
            label="Khách hàng"
            nameInput="clients"
            initialValues={detail}
            onSubmit={onSubmit}
          />
        </GridItem>
        <GridItem xs={12} md={6} lg={3}>
          <ContentForm
            label="Dự án"
            nameInput="deals"
            initialValues={detail}
            onSubmit={onSubmit}
          />
        </GridItem>
        <GridItem xs={12} md={6} lg={3}>
          <ContentForm
            label="Nước"
            nameInput="countries"
            initialValues={detail}
            onSubmit={onSubmit}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
