import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function RecentItem({ src, title, time, content, refRouter }) {
  return (
    <div className="recent-item">
      <Link className="recent-item_thumbnail" to={refRouter || "/"}>
        <img src={src} alt="recent-item" />
      </Link>
      <div className="recent-item_content">
        <div className="recent-item_content__inner">
          {title && (
            <div className="recent-item_title">
              <Link to={refRouter || "/"}>{title}</Link>
            </div>
          )}
          {content && (
            <div className="recent-item_text">
              <Link to={refRouter || "/"}>{content}</Link>
            </div>
          )}
          {time && <div className="recent-item_date">{time}</div>}
        </div>
      </div>
    </div>
  );
}

RecentItem.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  time: PropTypes.string,
  content: PropTypes.string,
  refRouter: PropTypes.string
};
