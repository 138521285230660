import React from "react";

export default function ChatSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Skype"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <circle
        id="prefix__Ellipse_43"
        cx="22"
        cy="22"
        r="22"
        fill="#fff"
        data-name="Ellipse 43"
      />
      <path
        id="prefix__Path_183"
        fill="#01acf2"
        d="M358.327-137.515a.748.748 0 0 1-.087-.489 10.545 10.545 0 0 0 .038-3.274c-.014-.128-.022-.258-.044-.384a11.3 11.3 0 0 0-12.991-9.29.6.6 0 0 1-.392-.073 6.568 6.568 0 0 0-3.917-.682 6.673 6.673 0 0 0-5.124 9.548c.027.055.052.108.073.161l.023.068.016.055.012.046a.712.712 0 0 1-.007.334c-.022.18-.049.358-.069.537-.008.061-.014.121-.018.181a10.63 10.63 0 0 0 1.6 6.84 11.078 11.078 0 0 0 11.441 5.3.771.771 0 0 1 .467.077 6.527 6.527 0 0 0 4.907.461 6.671 6.671 0 0 0 4.072-9.416zm-7.365 3.952a5.032 5.032 0 0 1-1.774.7 9.331 9.331 0 0 1-5.541-.441 4.146 4.146 0 0 1-1.853-1.451 2.4 2.4 0 0 1-.458-1.429 1.253 1.253 0 0 1 1.537-1.182 1.839 1.839 0 0 1 1.241.932 3.616 3.616 0 0 0 4.163 1.32 1.778 1.778 0 0 0 1.373-1.342 1.279 1.279 0 0 0-.635-1.433 2.723 2.723 0 0 0-.722-.278q-1.858-.465-3.716-.934a6.365 6.365 0 0 1-.665-.213 3.636 3.636 0 0 1-2.351-2.172 4.957 4.957 0 0 1-.141-2 3.346 3.346 0 0 1 .9-1.86 4.894 4.894 0 0 1 2.587-1.385 7.94 7.94 0 0 1 5.586.671 3.813 3.813 0 0 1 1.4 1.166 1.791 1.791 0 0 1 .371 1.239 1.4 1.4 0 0 1-2.15.906 3.862 3.862 0 0 1-.638-.642 3.68 3.68 0 0 0-3.953-.985 1.512 1.512 0 0 0-1.031 1.01 1.085 1.085 0 0 0 .492 1.322 2.39 2.39 0 0 0 .459.236 17.31 17.31 0 0 0 2.586.634c.57.139 1.145.259 1.707.42a4.8 4.8 0 0 1 2.023 1.085 3.423 3.423 0 0 1 1.046 2.4 3.832 3.832 0 0 1-1.843 3.706z"
        data-name="Path 183"
        transform="translate(-325.086 161.791)"
      />
    </svg>
  );
}
