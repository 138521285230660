import React from "react";
// MATERIAL
import { Pageview, List } from "@material-ui/icons";
// COMPONENT
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import NavPills from "components/NavPills/NavPills";
import ListClient from "./component/ListPartner";
import SEOIndex from "./component/SEOIndex";
import Optitex from "./Optitex";

export default function Client() {
  return (
    <NavPills
      color="primary"
      tabs={[
        {
          tabButton: "SEO",
          tabIcon: Pageview,
          tabContent: (
            <Card>
              <CardBody>
                <SEOIndex />
              </CardBody>
            </Card>
          )
        },
        {
          tabButton: "ĐỐI TÁC Optitex",
          tabIcon: List,
          tabContent: (
            <Card>
              <CardBody>
                <Optitex />
              </CardBody>
            </Card>
          )
        },
        {
          tabButton: "ĐỐI TÁC KHÁC",
          tabIcon: List,
          tabContent: (
            <Card>
              <CardBody>
                <ListClient />
              </CardBody>
            </Card>
          )
        }
      ]}
    />
  );
}
