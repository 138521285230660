import React from "react";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import svQuote1 from "assets/img/csp/sv-quote01.jpg";
import svQuote2 from "assets/img/csp/sv-quote02.jpg";
import IconHeading from "assets/img/csp/ic-heading.png";
import FadeInSection from "components/_App/FadeInSection";
import history from "history.js";

export default function DoServices() {
  const onQuote = () => {
    history.push("/contact");
  };

  return (
    <FadeInSection>
      <section className="do-service section-box bg-white">
        <div className="csp-container">
          <div className="main-title-wrap">
            <h2 className="main-title">
              <img
                src={IconHeading}
                className="ic-heading"
                alt="icon-heading"
              />
              <span>
                <IntlMessages id="service.title" />
              </span>
            </h2>
            <p className="sub-text">
              <IntlMessages id="service.sub" />
            </p>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10}>
              <GridContainer>
                <GridItem xs={12} md={6} className="service-quote-row">
                  <div className="do-service-quote">
                    <img src={svQuote1} alt="quote1" className="img-fluid" />
                    <div className="quote-detail">
                      <IntlMessages id="service.quote.training" />
                    </div>
                    <div className="quote-end">
                      <button className="btn-quote" onClick={onQuote}>
                        <IntlMessages id="service.quote.btn" />
                      </button>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} md={6} className="service-quote-row">
                  <div className="do-service-quote second">
                    <img src={svQuote2} alt="quote2" className="img-fluid" />
                    <div className="quote-detail">
                      <IntlMessages id="service.quote.fix" />
                    </div>
                    <div className="quote-end">
                      <button className="btn-quote" onClick={onQuote}>
                        <IntlMessages id="service.quote.btn" />
                      </button>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </section>
    </FadeInSection>
  );
}
