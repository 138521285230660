import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { gContents } from "actions/landing";
import IconHeading from "assets/img/csp/ic-heading.png";
import QuoteImg from "assets/img/csp/quote.png";
const introTeamId = "5Chxq1tVK8MkDzqJJsb4";

export default function Intro() {
  const dispatch = useDispatch();
  const locale = useSelector(({ settings }) => settings.locale.locale);
  const introContent = useSelector(({ landing }) => landing[introTeamId]);
  useEffect(() => {
    async function onFetch() {
      try {
        dispatch(gContents(introTeamId));
      } catch (error) {
        console.log("Failed to fetch list", error.message);
      }
    }
    onFetch();
  }, [dispatch]);

  return (
    <section>
      <div className="main-title-wrap">
        <h2 className="main-title">
          <img src={IconHeading} className="ic-heading" alt="icon-heading" />
          <span>
            <IntlMessages id="about.team.staff" />
          </span>
        </h2>
      </div>
      <div className="intro">
        {introContent && introContent[`content_${locale}`]}
      </div>
      <div className="quote-team">
        <div className="quote-inner">
          <div className="quote-img">
            <img src={QuoteImg} alt="quote" className="img-fluid" />
          </div>
          <p className="text">
            <IntlMessages id="about.team.quote" />
          </p>
        </div>
      </div>
    </section>
  );
}
