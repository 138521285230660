import { VIDEO } from "../constant";
import ADMIN_URL from "apis/AdminURL";
export const getVideos = () => async dispatch => {
  const response = await ADMIN_URL.get("/video/all");
  dispatch({
    type: VIDEO.GET_VIDEO,
    payload: response.data
  });
};
export const addVideo = dataValue => async dispatch => {
  await ADMIN_URL.post("/video/add", dataValue);
  dispatch({ type: VIDEO.ADD_VIDEO });
};
export const deleteVideo = id => async dispatch => {
  await ADMIN_URL.delete(`/video/${id}`);
  dispatch({ type: VIDEO.DELETE_VIDEO });
};

export const updateVideo = (id, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/video/${id}`, dataValue);
  dispatch({ type: VIDEO.UPDATE_VIDEO });
};
