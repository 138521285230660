import { PRODUCT_PART } from "actions/constant";

const initialState = {
  list: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // == PRODUCT ==
    case PRODUCT_PART.GET_PARTS:
      return { ...state, list: payload };

    case PRODUCT_PART.ADD_PART:
      return { ...state };

    case PRODUCT_PART.DELETE_PART:
      return { ...state };

    case PRODUCT_PART.UPDATE_PART:
      return { ...state };

    default:
      return state;
  }
};
