import React, { Component } from "react";
import { connect } from "react-redux";
import Flags from "country-flag-icons/react/3x2";
import _ from "lodash";
// MATERIAL
import { withStyles, Tooltip } from "@material-ui/core";
import styles from "assets/jss/m-dashboard/views/dashboardStyle";
// COMPONENT
import Loader from "components/Admin/Loader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Modal from "components/Admin/Modal";
import { getEmployees, deleteEmployee } from "actions/admin/company";
import { validURL } from "helpers";
import defaultImage from "assets/img/image_placeholder.jpg";
import DetailHR from "./DetailHR";
class ListVideo extends Component {
  state = {
    loading: false,
    addState: false,
    detailState: false,
    lang: null,
    detail: ""
  };

  componentDidMount() {
    this.props.getEmployees();
  }

  handleOpen = async (state, prop, lang) => {
    let detail = "";
    if (state === "detail") {
      const langData = _.pick(prop, [lang]);
      const detailData = _.omit(prop, ["vi", "en"]);
      detail = {
        ...detailData,
        ...langData[lang]
      };
    }

    this.setState({
      [`${state}State`]: true,
      detail,
      lang
    });
  };

  handleClose = () => {
    this.setState({
      addState: false,
      detailState: false,
      lang: null
    });
  };

  // RENDER LIST
  renderList = list => {
    const { classes } = this.props;
    return list.map((prop, key) => {
      return (
        <GridItem xs={12} key={key}>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <Card>
                <img
                  src={validURL(prop.img_url) ? prop.img_url : defaultImage}
                  className="img-fluid"
                  alt="employee"
                />
              </Card>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <Card className="employee-box">
                <CardBody>
                  <h5 className="title">{prop["vi"].name}</h5>
                  <p className="sub-title">{prop.nickname}</p>
                  <p className="role">{prop["vi"].role}</p>
                  <div className="mBtn-wrap">
                    <Tooltip
                      title="Tiếng việt"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="success"
                        simple
                        justIcon
                        onClick={() => this.handleOpen("detail", prop, "vi")}
                      >
                        <Flags.VN />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title="Tiếng anh"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="success"
                        simple
                        justIcon
                        onClick={() => this.handleOpen("detail", prop, "en")}
                      >
                        <Flags.US />
                      </Button>
                    </Tooltip>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    });
  };

  render() {
    const { list } = this.props;
    const { detailState, detail, lang } = this.state;
    if (!list) {
      return <Loader />;
    }
    return (
      <Card>
        <Modal
          open={detailState}
          onClose={this.handleClose}
          title={`THÔNG TIN - ${lang === "vi" ? "VIỆT" : "ANH"}`}
        >
          <DetailHR
            detail={detail}
            lang={lang}
            handleClose={this.handleClose}
          />
        </Modal>
        <CardBody>
          <GridContainer>{this.renderList(list)}</GridContainer>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = ({ company }) => {
  return {
    list: company.employee
  };
};
const styled = withStyles(styles)(ListVideo);
export default connect(
  mapStateToProps,
  {
    getEmployees,
    deleteEmployee
  }
)(styled);
