import React, { useState, useEffect } from "react";
import PURL from "apis/PublicURL";
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Admin/Loader";
import FadeInSection from "components/_App/FadeInSection";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";
const OptitexIdContent = "KMdMPB9RnLZOEI531z6Q";
// OUR PARTNERS SEO
const SEO_ID = "NFn5wVNtprbOYaukEcwZ";

export default function OurPartner() {
  const [partnerList, setPartnerList] = useState([]);
  const [optitexData, setOptitexData] = useState("");
  const [detail, setDetail] = useState("");
  const locale = useSelector(({ settings }) => settings.locale.locale);

  useEffect(() => {
    async function onFetch() {
      try {
        const res = await PURL.get(`company/seo/${SEO_ID}`);
        setDetail(res.data);
        const optitex = await PURL.get(`/company/contents/${OptitexIdContent}`);
        const response = await PURL.get("/company/partners");
        setOptitexData(optitex.data);
        setPartnerList(response.data.reverse());
      } catch (error) {
        console.log("Failed to fetch list", error.message);
      }
    }
    onFetch();
    ReactGA.pageview(window.location.pathname);
  }, []);

  if (!partnerList) {
    return (
      <div className="page-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="col-sm-12 col-lg-9 company-partner">
      {detail && (
        <CustomHelmet
          detail={detail}
          imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/wwa-logo.jpeg?alt=media&token=09bf3a41-a108-4177-9978-8db442a8a338"
        />
      )}
      <FadeInSection>
        <div className="partner-wrap">
          <GridContainer>
            {optitexData && (
              <GridItem xs={12} className="partner-item-box">
                <div className="partner-item">
                  <div className="logo">
                    <img
                      src={optitexData.logo}
                      className="img-fluid"
                      alt="OPTITEX"
                    />
                  </div>
                  <div className="detail">
                    <div className="name">Optitex</div>
                    <div className="slogan">
                      {optitexData[`slogan_${locale}`]}
                    </div>
                    <div className="desc">{optitexData[`desc_${locale}`]}</div>
                    <div className="content">
                      {optitexData[`content_${locale}`]}
                    </div>
                    <a className="link" href={optitexData.url}>
                      {optitexData.url}
                    </a>
                  </div>
                </div>
              </GridItem>
            )}
            {partnerList.map(item => (
              <GridItem xs={12} key={item.id} className="partner-item-box">
                <div className="partner-item">
                  <div className="logo">
                    <img
                      src={item.logo}
                      className="img-fluid"
                      alt={item.name}
                    />
                  </div>
                  <div className="detail">
                    <div className="name">{item.name}</div>
                    <div className="slogan">{item[locale].slogan}</div>
                    <div className="desc">{item[locale].desc}</div>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: item[locale].content
                      }}
                    />
                  </div>
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </FadeInSection>
    </div>
  );
}
