import React, { PureComponent } from "react";
import { connect } from "react-redux";
import productOverlay from "assets/img/csp/optitex-design.jpg";
import optitexOverlay from "assets/img/csp/optitex-overlay.png";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import IntlMessages from "util/IntlMessages";
import swal from "sweetalert";
import FadeInSection from "components/_App/FadeInSection";
import PURL from "apis/PublicURL";

class Design extends PureComponent {
  state = {
    name: "",
    phone: "",
    messages: ""
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { name, phone, messages } = this.state;

    return PURL.post("/email/request", { name, phone, messages }).then(() => {
      this.setState({
        name: "",
        phone: "",
        messages: ""
      });
      swal({
        text: "Chúng tôi sẽ phản hồi lại yêu cầu của bạn sớm nhất có thể",
        icon: "success",
        button: "OK"
      });
    });
  };
  render() {
    const { name, phone, messages } = this.state;
    const { locale } = this.props;
    return (
      <FadeInSection>
        <section className="optitex-design">
          <div
            style={{
              backgroundImage: `url(${productOverlay})`,
              position: "relative",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}
          >
            <img src={optitexOverlay} alt="bg_overlay" className="bg-overlay" />
            <div className="csp-container">
              <div className="design-quote">
                <GridContainer>
                  <GridItem xs={12} md={6}>
                    <div className="quote-intro">
                      <h4 className="title">
                        <IntlMessages id="optitex.request.title" />
                      </h4>
                      <p className="text">
                        <IntlMessages id="optitex.request.sub" />
                      </p>
                    </div>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <form className="quote-form" onSubmit={this.onSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} className="form-row">
                          <input
                            placeholder={
                              locale === "en" ? "Full name" : "Họ và tên"
                            }
                            type="text"
                            name="name"
                            autoComplete="off"
                            onChange={this.handleChange}
                            value={name}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} className="form-row">
                          <input
                            placeholder={
                              locale === "en" ? "Phone Number" : "Số điện thoại"
                            }
                            type="text"
                            name="phone"
                            autoComplete="off"
                            onChange={this.handleChange}
                            value={phone}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} className="form-row">
                          <input
                            placeholder={
                              locale === "en"
                                ? "Your messages & email"
                                : "Email và yêu cầu của bạn"
                            }
                            type="text"
                            name="messages"
                            autoComplete="off"
                            onChange={this.handleChange}
                            value={messages}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} className="form-row">
                          <button type="submit">
                            <IntlMessages id="button.submit" />
                          </button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </section>
      </FadeInSection>
    );
  }
}
const mapStateToProps = ({ settings }) => {
  return {
    locale: settings.locale.locale
  };
};
export default connect(mapStateToProps)(Design);
