import React, { Component, Fragment } from "react";
import Hamburger from "hamburger-react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { Tooltip, Hidden, Drawer, Collapse, List } from "@material-ui/core";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Flags from "country-flag-icons/react/3x2";
import IntlMessages from "util/IntlMessages";
import Button from "components/CustomButtons";
import LanguageSwitcher from "components/LanguageSwitcher";
import { switchLanguage, gActivePartners } from "actions/landing";
import logo from "assets/img/csp/logo-header.png";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const menu = [
  { id: "home.menu.index", path: "home" },
  { id: "home.menu.about", path: "about" },
  { id: "home.menu.product", path: "product" },
  { id: "home.menu.service", path: "services" },
  { id: "home.menu.newsevent", path: "news-event" },
  { id: "home.menu.contact", path: "contact" }
];

const aboutMenu = [
  { id: "home.menu.about.company", path: "company-profile" },
  { id: "about.history.title", path: "history" },
  { id: "about.partner.title", path: "partner" },
  { id: "about.team.title", path: "team" }
];

class Header extends Component {
  state = {
    openMobile: false,
    langSwitcher: false,
    anchorEl: undefined,
    isHover: false,
    partners: "",
    productState: false,
    aboutState: false,
    aboutDropdown: false,
    productDropdown: false
  };

  componentDidMount() {
    this.onCall = this.onLoad();
  }

  componentWillUnmount() {
    this.onCall = null;
  }

  async onLoad() {
    await this.props.gActivePartners();
    this.setState({ partners: this.props.partners.reverse() });
  }

  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  onHover = name => {
    this.setState({ [`${name}State`]: true });
  };

  onLeave = name => {
    this.setState({ [`${name}State`]: false });
  };

  onRenderMenu = menu => {
    if (menu) {
      return menu.map((item, key) => {
        return (
          <li key={key} className="menu-button">
            <Tooltip title={<IntlMessages id={item.id} />}>
              {item.path === "product" || item.path === "about" ? (
                <Fragment>
                  <button
                    onMouseEnter={() => this.onHover(item.path)}
                    onMouseLeave={() => this.onLeave(item.path)}
                    className={cx("menu-link", this.activeRoute(item.path))}
                  >
                    <IntlMessages id={item.id} />
                  </button>
                  <div
                    onMouseEnter={() => this.onHover(item.path)}
                    onMouseLeave={() => this.onLeave(item.path)}
                    className={cx(
                      "menu-product",
                      this.state[`${item.path}State`] ? "show" : null
                    )}
                  >
                    <ul>{this.onRenderDropdown(item.path)}</ul>
                  </div>
                </Fragment>
              ) : (
                <NavLink
                  activeClassName="active"
                  to={`/${item.path}`}
                  className="menu-link"
                >
                  <IntlMessages id={item.id} />
                </NavLink>
              )}
            </Tooltip>
          </li>
        );
      });
    }
  };

  onRenderDropdown = name => {
    const { partners } = this.state;
    switch (name) {
      case "about":
        if (aboutMenu) {
          return aboutMenu.map((item, key) => {
            return (
              <NavLink
                key={key}
                activeClassName="active"
                to={`/about/${item.path}`}
                className="menu-product-link"
              >
                <IntlMessages id={item.id} />
              </NavLink>
            );
          });
        }
        break;

      case "product":
        if (partners) {
          let newPartner = [{ name: "optitex" }, ...partners];
          return newPartner.map((item, key) => {
            if (item.name === "optitex") {
              return (
                <NavLink
                  key={key}
                  activeClassName="active"
                  to={`/product/${item.name}`}
                  className="menu-product-link"
                >
                  Optitex
                </NavLink>
              );
            } else {
              return (
                <NavLink
                  key={key}
                  activeClassName="active"
                  to={`/product/${item.slug}/${item.id}`}
                  className="menu-product-link"
                >
                  {item.name}{" "}
                </NavLink>
              );
            }
          });
        }
        break;

      default:
        return null;
    }
  };

  renderLngSwitch = () => {
    const { langSwitcher } = this.state;
    const { locale } = this.props;
    return (
      <li className="menu-button">
        <Dropdown
          isOpen={langSwitcher}
          toggle={this.onLangSwitcherSelect.bind(this)}
        >
          <DropdownToggle
            className="d-inline-block"
            tag="span"
            data-toggle="dropdown"
          >
            <Button justIcon simple>
              {locale.icon === "vn" ? <Flags.VN /> : <Flags.US />}
            </Button>
          </DropdownToggle>
          <DropdownMenu right className="w-50">
            <LanguageSwitcher
              switchLanguage={this.props.switchLanguage}
              handleRequestClose={this.handleRequestClose}
            />
          </DropdownMenu>
        </Dropdown>
      </li>
    );
  };

  toggleDrawer = () => {
    this.setState({ openMobile: !this.state.openMobile });
  };

  onLangSwitcherSelect = event => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    });
  };

  handleRequestClose = () => {
    this.setState({ langSwitcher: false });
  };

  toggleDropdown = name => {
    this.setState({ [`${name}Dropdown`]: !this.state[`${name}Dropdown`] });
  };

  render() {
    const { openMobile, partners } = this.state;
    return (
      <header id="header">
        <div className="csp-container">
          <div className="main-header">
            <div className="brand-logo">
              <img src={logo} alt="logo" className="img-fluid" />
            </div>
            <Hidden mdDown implementation="css">
              <div className="desktop-nav">
                <ul className="nav-wrapper">
                  {this.onRenderMenu(menu)}
                  {this.renderLngSwitch()}
                </ul>
              </div>
            </Hidden>
            <Hidden lgUp implementation="css">
              <Hamburger
                toggled={openMobile}
                toggle={this.toggleDrawer}
                duration={0.8}
                color="#fff"
              />
            </Hidden>
            <Drawer
              anchor="right"
              transitionDuration={800}
              open={openMobile}
              onClose={this.toggleDrawer}
            >
              <div className="mobile-nav">
                <Hamburger
                  toggled={openMobile}
                  toggle={this.toggleDrawer}
                  color="#005e89"
                  duration={10}
                />
                <ul className="nav-wrapper">
                  <li className="menu-button">
                    <Tooltip title={<IntlMessages id="home.menu.index" />}>
                      <NavLink
                        activeClassName="active"
                        to="/home"
                        className="menu-link"
                      >
                        <IntlMessages id="home.menu.index" />
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li className="menu-button">
                    <Tooltip title={<IntlMessages id="home.menu.about" />}>
                      <button
                        className={cx("menu-link", this.activeRoute("about"))}
                        onClick={() => this.toggleDropdown("about")}
                      >
                        <div className="icon-caret">
                          {this.state.aboutDropdown ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </div>
                        <IntlMessages id="home.menu.about" />
                      </button>
                    </Tooltip>
                  </li>
                  <Collapse
                    in={this.state.aboutDropdown}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div">
                      {aboutMenu.map((item, key) => (
                        <NavLink
                          key={key}
                          activeClassName="active"
                          to={`/about/${item.path}`}
                          className="menu-product-link"
                        >
                          <IntlMessages id={item.id} />
                        </NavLink>
                      ))}
                    </List>
                  </Collapse>
                  <li className="menu-button">
                    <Tooltip title={<IntlMessages id="home.menu.product" />}>
                      <button
                        className={cx("menu-link", this.activeRoute("product"))}
                        onClick={() => this.toggleDropdown("product")}
                      >
                        <div className="icon-caret">
                          {this.state.productDropdown ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </div>
                        <IntlMessages id="home.menu.product" />
                      </button>
                    </Tooltip>
                  </li>
                  <Collapse
                    in={this.state.productDropdown}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div">
                      <NavLink
                        activeClassName="active"
                        to={`/product/optitex`}
                        className="menu-product-link"
                      >
                        Optitex
                      </NavLink>
                      {partners &&
                        partners.map((item, key) => (
                          <NavLink
                            key={key}
                            activeClassName="active"
                            to={`/product/${item.slug}/${item.id}`}
                            className="menu-product-link"
                          >
                            {item.name}
                          </NavLink>
                        ))}
                    </List>
                  </Collapse>
                  <li className="menu-button">
                    <Tooltip title={<IntlMessages id="home.menu.service" />}>
                      <NavLink
                        activeClassName="active"
                        to="/services"
                        className="menu-link"
                      >
                        <IntlMessages id="home.menu.service" />
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li className="menu-button">
                    <Tooltip title={<IntlMessages id="home.menu.newsevent" />}>
                      <NavLink
                        activeClassName="active"
                        to="/news-event"
                        className="menu-link"
                      >
                        <IntlMessages id="home.menu.newsevent" />
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li className="menu-button">
                    <Tooltip title={<IntlMessages id="home.menu.contact" />}>
                      <NavLink
                        activeClassName="active"
                        to="/contact"
                        className="menu-link"
                      >
                        <IntlMessages id="home.menu.contact" />
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li className="language-wrapper">
                    <LanguageSwitcher
                      switchLanguage={this.props.switchLanguage}
                      handleRequestClose={this.handleRequestClose}
                    />
                  </li>
                </ul>
              </div>
            </Drawer>
          </div>
        </div>
      </header>
    );
  }
}
const mapStateToProps = ({ settings, landing }) => {
  return {
    locale: settings.locale,
    partners: landing.partner
  };
};
export default connect(
  mapStateToProps,
  {
    switchLanguage,
    gActivePartners
  }
)(Header);
