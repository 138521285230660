import {SEO} from "actions/constant";

export default (state = "", action) => {
    const {type, payload} = action
    switch (type) {
        case SEO.GET_SEO_LIST:
            return {...state, [payload.id]: payload};
        
        case SEO.UPDATE_SEO_LIST:
            return {...state}

        default:
            return state;
    }
};