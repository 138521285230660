import React, { Component } from "react";
import { connect } from "react-redux";
import FileUploader from "react-firebase-file-uploader";
import toast from "cogo-toast";
import _ from "lodash";
// Material UI
import { Publish } from "@material-ui/icons";
// App
import Heading from "components/Heading";
import Button from "components/CustomButtons";
import { bucket, db } from "apis/config";
import { TOAST_OPTIONS, removeDot } from "helpers";
import { getBrochures } from "actions/admin/company";

class UploadImage extends Component {
  state = {
    isUploading: false,
    progress: 0
  };

  handleUploadSuccess = filename => {
    const brochureDB = db.collection("brochure");
    const brochureBucket = bucket.ref("brochure");
    const createdAt = _.now();
    const name = removeDot(filename);
    return brochureBucket
      .child(filename)
      .getDownloadURL()
      .then(url => {
        let obj = {
          createdAt,
          name,
          src: url
        };
        return brochureDB.add(obj).then(docRef => {
          const id = docRef.id;
          this.props.getBrochures();
          toast.success(`Brochure ${filename} đã được tải lên`, TOAST_OPTIONS);
          this.props.handleUploading(false, 100);
          return brochureDB.doc(id).update({ id });
        });
      })
      .catch(error => {
        this.props.handleUploading(false, 0);
        toast.error(error, TOAST_OPTIONS);
      });
  };

  handleUploadStart = () => this.props.handleUploading(true, 0);
  handleProgress = progress => this.props.handleProgress(progress);

  render() {
    return (
      <div className="position-relative">
        <Heading title="DANH SÁCH BROCHURE" />
        <Button color="primary" className="btn-float">
          <Publish />
          <FileUploader
            className="input-upload"
            accept="application/pdf"
            name="image"
            multiple
            storageRef={bucket.ref("brochure")}
            onUploadSuccess={this.handleUploadSuccess}
            onUploadStart={this.handleUploadStart}
            onProgress={this.handleProgress}
          />
        </Button>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getBrochures
  }
)(UploadImage);
