import React from "react";
import ReactQuill from "react-quill";
import firebase from "apis/config";

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { content } = this.props;
    if (content) {
      this.setState({ editorHtml: content });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (this.props.content !== nextProps.content) {
      this.setState({ editorHtml: nextProps.content });
    }
  }

  handleChange(html) {
    this.setState({ editorHtml: html }, () => {
      this.props.onChange(html);
    });
  }

  imageHandler() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input.files[0]) {
        const image = input.files[0];
        const img_time = new Date().getTime();
        const year = new Date().getFullYear();
        const uploadTask = firebase
          .storage()
          .ref(`blog/${year}/photo_${img_time}`)
          .put(image);
        const range = this.quill.getSelection(true);
        uploadTask.on(
          "state_changed",
          snapshot => {
            console.log(snapshot);
          },
          error => console.log(error),
          () => {
            firebase
              .storage()
              .ref(`blog/${year}`)
              .child(`photo_${img_time}`)
              .getDownloadURL()
              .then(url => {
                console.log(url);
                this.quill.insertEmbed(range.index, "image", url);
              });
          }
        );
      }
    };
  }

  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6] }, { font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { color: [] }],
        ["link", this.props.photo && "image", this.props.media && "video"]
      ],
      handlers: {
        image: this.imageHandler
      }
    }
  };

  render() {
    return (
      <ReactQuill
        ref={el => {
          this.quill = el;
        }}
        onChange={this.handleChange}
        value={this.state.editorHtml}
        modules={this.modules}
        placeholder={this.props.placeholder}
      />
    );
  }
}

export default Editor;
