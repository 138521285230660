import React, { Component } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import swal from "sweetalert";
import rqcb from "assets/img/csp/about-request-team.jpg";
import aboutOverlay from "assets/img/csp/about-overlay.png";
import PURL from "apis/PublicURL";

class RQCBAbout extends Component {
  state = {
    name: "",
    phone: "",
    messages: ""
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { name, phone, messages } = this.state;

    return PURL.post("/email/request", { name, phone, messages }).then(() => {
      this.setState({
        name: "",
        phone: "",
        messages: ""
      });
      swal({
        text: "Chúng tôi sẽ phản hồi lại yêu cầu của bạn sớm nhất có thể",
        icon: "success",
        button: "OK"
      });
    });
  };

  render() {
    const { name, phone, messages } = this.state;
    const { locale } = this.props;
    return (
      <div className="quote-box-about">
        <div className="bg-section" style={{ backgroundImage: `url(${rqcb})` }}>
          <img src={aboutOverlay} alt="bg_overlay" className="bg-overlay" />
          <div className="csp-container">
            <div className="quote-wrap">
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <div className="quote-intro">
                    <h4 className="title">
                      <IntlMessages id="contact.request.title" />
                    </h4>
                    <p className="text">
                      <IntlMessages id="contact.request.text" />
                    </p>
                  </div>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <form className="quote-form" onSubmit={this.onSubmit}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} className="form-row">
                        <input
                          placeholder={
                            locale === "en" ? "Full name" : "Họ và tên"
                          }
                          type="text"
                          name="name"
                          autoComplete="off"
                          onChange={this.handleChange}
                          value={name}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} className="form-row">
                        <input
                          placeholder={
                            locale === "en" ? "Phone Number" : "Số điện thoại"
                          }
                          type="text"
                          name="phone"
                          autoComplete="off"
                          onChange={this.handleChange}
                          value={phone}
                        />
                      </GridItem>
                      <GridItem xs={12} className="form-row">
                        <textarea
                          placeholder={
                            locale === "en"
                              ? "Your messages & email"
                              : "Email và yêu cầu của bạn"
                          }
                          type="text"
                          name="messages"
                          autoComplete="off"
                          onChange={this.handleChange}
                          value={messages}
                        />
                      </GridItem>
                      <GridItem xs={12} className="form-row">
                        <button type="submit">
                          <IntlMessages id="button.submit" />
                        </button>
                      </GridItem>
                    </GridContainer>
                  </form>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  return {
    locale: settings.locale.locale
  };
};

export default connect(mapStateToProps)(RQCBAbout);
