import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import Flags from "country-flag-icons/react/3x2";
import toast from "cogo-toast";
// MATERIAL
import { Card, Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
// COMPONENT
import Loader from "components/Admin/Loader";
import Button from "components/CustomButtons";
import history from "history.js";
import defaultImage from "assets/img/image_placeholder.jpg";
import { convertTimeStamp, TABLE_CONFIG, TOAST_OPTIONS } from "helpers";
import { getPost, deletePost } from "actions/admin/post";

class ListPost extends Component {
  state = {
    loading: true
  };

  btnConfig = {
    justIcon: true,
    simple: true
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  onLoad = async () => {
    await this.props.getPost();
    this.setState({ loading: false });
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  handleDelete = async id => {
    this.onLoader();
    try {
      await this.props.deletePost(id);
      await this.props.getPost();
      toast.success(`Bài viết đã được xóa`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  handleEdit = (id, lang) => history.push(`/dashboard/blog/${id}/${lang}`);

  renderList = () => {
    const { list } = this.props;
    if (list) {
      return list.map(prop => {
        return {
          image: (
            <Card>
              <img
                src={prop.images ? prop.images[0] : defaultImage}
                alt="thumbnail"
                className="img-fluid"
              />
            </Card>
          ),
          name: (
            <Button className="btn-title" color="primary" simple>
              {prop["vi"].title}
            </Button>
          ),
          cate: (
            <Button className="btn-title" color="info" simple>
              {prop.category}
            </Button>
          ),
          time_created: (
            <Button className="btn-title" color="success" simple>
              {convertTimeStamp(prop.created_on)}
            </Button>
          ),
          language: (
            <div className="actions-left">
              <Tooltip title="Tiếng anh">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop.id, "en")}
                >
                  <Flags.US />
                </Button>
              </Tooltip>
              <Tooltip title="Tiếng việt">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop.id, "vi")}
                >
                  <Flags.VN />
                </Button>
              </Tooltip>
            </div>
          ),
          actions: (
            <div className="actions-right">
              <Tooltip title="Xóa bài viết">
                <Button
                  {...this.btnConfig}
                  color="danger"
                  onClick={() => this.handleDelete(prop.id, prop.name)}
                >
                  <Delete />
                </Button>
              </Tooltip>
            </div>
          )
        };
      });
    }
  };

  render() {
    const columns = [
      { Header: "Ảnh", accessor: "image", width: 150 },
      { Header: "Tên bài", accessor: "name", minWidth: 150 },
      { Header: "Mục", accessor: "cate" },
      { Header: "Thời gian tạo", accessor: "time_created", width: 220 },
      { Header: "Ngôn Ngữ", accessor: "language", width: 150 },
      { Header: "Thao tác", accessor: "actions", width: 110 }
    ];
    const { loading } = this.state;
    return (
      <ReactTable
        {...TABLE_CONFIG}
        data={this.renderList()}
        columns={columns}
        loading={loading}
        loadingText={<Loader />}
      />
    );
  }
}
const mapStateToProps = ({ post }) => {
  return {
    list: post.list
  };
};
export default connect(
  mapStateToProps,
  {
    getPost,
    deletePost
  }
)(ListPost);
