import { PRODUCT_PART } from "../constant";
import ADMIN_URL from "apis/AdminURL";

// == PART ==
export const getParts = () => async dispatch => {
  const response = await ADMIN_URL.get("/parts/all");
  dispatch({
    type: PRODUCT_PART.GET_PARTS,
    payload: response.data
  });
};
export const addPart = dataValue => async dispatch => {
  await ADMIN_URL.post("/parts/add", dataValue);
  dispatch({ type: PRODUCT_PART.ADD_PART });
};
export const deletePart = id => async dispatch => {
  await ADMIN_URL.delete(`/parts/delete/${id}`);
  dispatch({ type: PRODUCT_PART.DELETE_PART });
};
export const updatePart = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/parts/${id}/lang/${lang}`, dataValue);
  dispatch({ type: PRODUCT_PART.UPDATE_PART });
};
