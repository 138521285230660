import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import inputStyles from "./inputStyles";
class CustomInput extends Component {
  render() {
    const { classes, className } = this.props;
    return (
      <TextField
        fullWidth
        className={className ? `${classes.root} ${className}` : classes.root}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        {...this.props}
      />
    );
  }
}

export default withStyles(inputStyles)(CustomInput);
