import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Loader from "components/Admin/Loader";
import CateOP from "components/Admin/Form/BaseForm/CateOP";
import { updateCateOP, getCateOP } from "actions/admin/product";
import { TOAST_OPTIONS } from "helpers";

class CateDetail extends Component {
  state = {
    loading: false,
    detail: this.props.detail,
    lang: this.props.lang
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onSubmit = async dataValue => {
    const { detail, lang } = this.state;
    this.onLoader();
    let name = dataValue.name.toLowerCase();

    if (!name) {
      toast.warn("Cần điền tên của danh mục", TOAST_OPTIONS);
      this.endLoader();
      return null;
    }

    try {
      await this.props.updateCateOP(detail.id, lang, dataValue);
      await this.props.getCateOP();
      toast.success(`Danh mục cập nhật thành công`, TOAST_OPTIONS);
      this.onClose();
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  onClose = () => {
    this.props.handleClose();
  };

  render() {
    const { loading, detail } = this.state;
    return (
      <div>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <CateOP
          submitForm={this.onSubmit}
          handleClose={this.onClose}
          initialValues={detail}
        />
      </div>
    );
  }
}
export default connect(
  null,
  { updateCateOP, getCateOP }
)(CateDetail);
