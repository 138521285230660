import { SETTINGS } from "actions/landing/type";

const initialState = {
  locale: {
    languageId: "vietnamese",
    locale: "vi",
    name: "Vietnam",
    icon: "vn"
  }
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS.SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload
      };
    default:
      return state;
  }
};

export default settings;
