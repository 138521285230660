import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FileUploader from "react-firebase-file-uploader";
import { bucket } from "apis/config";
import _ from "lodash";
import toast from "cogo-toast";
// MATERIAL
import {
  withStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  LinearProgress,
  Tooltip
} from "@material-ui/core";
import {
  Save,
  Add,
  KeyboardBackspace,
  Visibility,
  Close
} from "@material-ui/icons";
import selectStyles from "assets/jss/m-dashboard/customSelectStyle";
// COMPONENT
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import Editor from "components/Admin/Form/Wyswyg";
import Modal from "components/Admin/Modal";
import Adornment from "components/Adornment";
import history from "history.js";
import defaultImage from "assets/img/image_placeholder.jpg";
import { getPartner } from "actions/admin/partner";
import { removeDot, TOAST_OPTIONS } from "helpers";

class ProductForm extends Component {
  state = {
    open: false,
    name: "",
    p_name: "",
    brand: "",
    brochure: "",
    thumbnail: "",
    preview_img: defaultImage,
    banner_title: "",
    banner_sub: "",
    banner_img: "",
    machine_type: "",
    desc: "",
    dimension_plane_img: "",
    application_content: "",
    advantages_note: "",
    advantages_up: "",
    advantages_down: "",
    option_data: "",
    images: [],
    table_data: [{ table_title: "", table_text: "" }]
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.getPartner();
    if (this.props.initialValues) {
      this.onLoadField(this.props.initialValues);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //   ON LOAD FIELD
  onLoadField = prop => {
    if (this._isMounted) {
      this.setState({
        name: prop.name || "",
        p_name: prop.p_name || "",
        brand: prop.brand || "",
        brochure: prop.brochure || "",
        thumbnail: prop.thumbnail || "",
        banner_title: prop.banner_title || "",
        banner_sub: prop.banner_sub || "",
        banner_img: prop.banner_img || "",
        machine_type: prop.machine_type || "",
        desc: prop.desc || "",
        technical_data_img: prop.technical_data_img || "",
        technical_data: prop.technical_data || "",
        dimension_plane_img: prop.dimension_plane_img || "",
        application_content: prop.application_content || "",
        advantages_down: prop.advantages_down || "",
        advantages_up: prop.advantages_up || "",
        advantages_note: prop.advantages_note || "",
        option_data: prop.option_data || "",
        images: prop.images || [],
        table_data: prop.table_data || [{ table_title: "", table_text: "" }]
      });
    }
  };

  onChangeDynamicField = e => {
    const { className } = e.target;
    if (["table_title", "table_text"].includes(className)) {
      let table_data = [...this.state.table_data];
      table_data[e.target.dataset.id][e.target.className] = e.target.value;
      this.setState({ table_data }, () => {
        this.props.change("table_data", table_data);
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  addRow = () => {
    this.setState(prevState => ({
      table_data: [...prevState.table_data, { table_title: "", table_text: "" }]
    }));
  };

  removeRow = index => {
    const { table_data } = this.state;
    const values = [...table_data];
    if (index < 0) return;
    values.splice(index, 1);
    this.setState({ table_data: values }, () =>
      this.props.change("table_data", values)
    );
  };

  renderDynamicField = ({ input, label, dataValue, multiline, ...rest }) => {
    return (
      <div className="table-row">
        <label htmlFor={rest.id}>{label}</label>
        {multiline ? (
          <textarea
            {...rest}
            name={input.name}
            value={dataValue}
            onChange={this.onChangeDynamicField}
            rows={2}
          />
        ) : (
          <input
            {...rest}
            name={input.name}
            value={dataValue}
            onChange={this.onChangeDynamicField}
          />
        )}
      </div>
    );
  };

  //   RENDER COMPONENT
  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };

  renderEditor = ({ input, dataValue, placeholder, ...rest }) => {
    return (
      <FormControl fullWidth>
        <Editor
          {...rest}
          content={dataValue}
          placeholder={placeholder}
          onChange={editorState => {
            this.props.change(input.name, editorState);
          }}
        />
      </FormControl>
    );
  };

  renderSelect = ({ input, label, children, dataValue }) => {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          inputProps={{ name: input.name, value: dataValue }}
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          onChange={this.handleChange}
        >
          {children}
        </Select>
      </FormControl>
    );
  };

  renderBrand = () => {
    const { brand, classes } = this.props;
    if (brand) {
      return brand.map((prop, key) => (
        <MenuItem
          key={key}
          value={prop.id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
        >
          {prop.name.toUpperCase()}
        </MenuItem>
      ));
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  onSubmit = dataValue => {
    const { images } = this.state;

    if (!dataValue.brand) {
      toast.warn("Xin chọn hãng sản xuất", TOAST_OPTIONS);
      return null;
    }

    const init_data = {
      ...dataValue,
      images
    };

    this.props.submitForm(init_data);
  };
  onBack = () => history.goBack();
  handleOpen = img => {
    if (img) {
      this.setState({ open: true, preview_img: img });
    }
  };
  handleClose = () => {
    this.setState({ open: false }, () =>
      setTimeout(this.setState({ preview_img: defaultImage }), 1000)
    );
  };

  // UPLOAD IMAGE
  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });

  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });
  handleUploadSuccess = async filename => {
    const name = removeDot(filename.split("_")[1]);
    const imgURL = await bucket
      .ref("product")
      .child(filename)
      .getDownloadURL();
    const image = {
      src: imgURL,
      name
    };
    this.setState(oldState => ({
      images: [...oldState.images, image],
      uploadProgress: 100,
      isUploading: false
    }));
  };

  onDeletePhoto = async url => {
    const { images } = this.state;
    const index = images.findIndex(x => x.src === url);
    if (index < 0) return;
    const update_images = [...images];
    update_images.splice(index, 1);
    this.setState({ images: update_images });
  };

  render() {
    const {
      open,
      preview_img,
      name,
      p_name,
      brand,
      desc,
      banner_img,
      thumbnail,
      brochure,
      banner_title,
      banner_sub,
      machine_type,
      dimension_plane_img,
      application_content,
      advantages_down,
      advantages_up,
      advantages_note,
      option_data,
      isUploading,
      uploadProgress,
      images,
      table_data
    } = this.state;
    const { initialValues, handleSubmit, classes } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Modal open={open} onClose={this.handleClose}>
          <img src={preview_img} alt="specifications" className="img-fluid" />
        </Modal>
        <GridContainer justify="center">
          <GridItem xs={12} sm={4}>
            <Field
              component={this.renderField}
              label="TÊN SẢN PHẨM"
              name="name"
              dataValue={name}
              required
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              component={this.renderSelect}
              label="HÃNG SẢN XUẤT"
              name="brand"
              dataValue={brand}
              required
            >
              <MenuItem
                value=""
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                CHỌN HÃNG SẢN XUẤT
              </MenuItem>
              {this.renderBrand()}
            </Field>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              component={this.renderField}
              label="TIÊU ĐỀ TRÊN"
              name="machine_type"
              dataValue={machine_type}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <Field
              component={this.renderField}
              label="TIÊU ĐỀ BANNER"
              name="banner_title"
              dataValue={banner_title}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <Field
              component={this.renderField}
              label="MÔ TẢ BANNER"
              name="banner_sub"
              dataValue={banner_sub}
            />
          </GridItem>
          <GridItem xs={12} md={4}>
            <Field
              component={this.renderField}
              label="TIÊU ĐỀ DƯỚI"
              name="p_name"
              dataValue={p_name}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              label="MÔ TẢ SẢN PHẨM"
              name="desc"
              dataValue={desc}
              multiline
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              name="banner_img"
              label="HÌNH BANNER"
              dataValue={banner_img}
              multiline
              endAdornment={
                <Adornment
                  onClick={() => this.handleOpen(banner_img)}
                  disabled={banner_img ? true : false}
                >
                  <Visibility />
                </Adornment>
              }
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              name="thumbnail"
              label="HÌNH SẢN PHẨM"
              dataValue={thumbnail}
              multiline
              endAdornment={
                <Adornment onClick={() => this.handleOpen(thumbnail)}>
                  <Visibility />
                </Adornment>
              }
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              name="dimension_plane_img"
              label="HÌNH DIMENSION PLAN"
              dataValue={dimension_plane_img}
              endAdornment={
                <Adornment onClick={() => this.handleOpen(dimension_plane_img)}>
                  <Visibility />
                </Adornment>
              }
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              name="brochure"
              label="ĐƯỜNG DẪN"
              dataValue={brochure}
              multiline
            />
          </GridItem>
        </GridContainer>
        {brand === "Id9NjztLRCxLld3UDvcN" && (
          <GridContainer>
            <GridItem xs={12}>
              <div className="form-title">Options</div>
            </GridItem>
            <GridItem xs={12}>
              <Field
                photo
                component={this.renderEditor}
                placeholder="NỘI DUNG"
                name="option_data"
                dataValue={option_data}
              />
            </GridItem>
          </GridContainer>
        )}
        <GridContainer>
          <GridItem xs={12}>
            <div className="form-title">Advantages</div>
          </GridItem>
          <GridItem xs={12} className="mb-4">
            <Field
              photo
              placeholder="KHUNG GHI CHÚ"
              component={this.renderEditor}
              name="advantages_note"
              dataValue={advantages_note}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <Field
              component={this.renderEditor}
              placeholder="KHUNG TRÊN"
              name="advantages_up"
              dataValue={advantages_up}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <Field
              component={this.renderEditor}
              placeholder="KHUNG DƯỚI"
              name="advantages_down"
              dataValue={advantages_down}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <div className="form-title">Application</div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <Field
              component={this.renderField}
              placeholder="Application"
              name="application_content"
              dataValue={application_content}
              multiline
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <label className="btn-labelImg">
              Hình ảnh Application
              <FileUploader
                hidden
                multiple
                accept="image/*"
                name="images"
                filename={file => `${_.now()}_${file.name}`}
                storageRef={bucket.ref("product")}
                onUploadSuccess={this.handleUploadSuccess}
                onUploadStart={this.handleUploadStart}
                onProgress={this.handleProgress}
              />
            </label>
            {isUploading && (
              <LinearProgress variant="determinate" value={uploadProgress} />
            )}
            <GridContainer>
              {images &&
                images.map((item, key) => (
                  <GridItem xs={12} md={3} key={key}>
                    <div className="pr-img text-center mb-4">
                      <img src={item.src} className="img-fluid" alt="preview" />
                      <Button
                        simple
                        color="danger"
                        onClick={() => this.onDeletePhoto(item.src)}
                      >
                        <Close /> xóa ảnh
                      </Button>
                    </div>
                  </GridItem>
                ))}
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <div className="form-title">Technical Data</div>
          </GridItem>
          {/* <GridItem xs={12} md={6}>
            <Field
              component={this.renderEditor}
              placeholder="Dữ liệu"
              name="technical_data"
              dataValue={technical_data}
            />
          </GridItem> */}
          {/* <GridItem xs={12}>
            <Field
              component={this.renderField}
              name="technical_data_img"
              label="HÌNH TECHNICAL DATA"
              dataValue={technical_data_img}
              endAdornment={
                <Adornment onClick={() => this.handleOpen(technical_data_img)}>
                  <Visibility />
                </Adornment>
              }
            />
          </GridItem> */}
          <GridItem xs={12}>
            <Button onClick={this.addRow} color="primary">
              THÊM DÒNG
            </Button>
            <GridContainer>
              {table_data.map((item, idx) => {
                let titleId = `table_title-${idx}`,
                  textId = `table_text-${idx}`;
                return (
                  <GridItem xs={12} md={6} className="table-data-row" key={idx}>
                    <Field
                      label={`Tiêu đề #${idx + 1}`}
                      component={this.renderDynamicField}
                      name={titleId}
                      dataValue={table_data[idx].table_title}
                      data-id={idx}
                      id={titleId}
                      className="table_title"
                      autoComplete="off"
                    />
                    <Field
                      label={`Dữ liệu #${idx + 1}`}
                      component={this.renderDynamicField}
                      name={textId}
                      dataValue={table_data[idx].table_text}
                      data-id={idx}
                      id={textId}
                      className="table_text"
                      autoComplete="off"
                      multiline
                    />
                    <Tooltip title="Xóa">
                      <Button
                        color="danger"
                        className="btn-remove-row"
                        onClick={() => this.removeRow(idx)}
                      >
                        Xóa Dòng
                      </Button>
                    </Tooltip>
                  </GridItem>
                );
              })}
            </GridContainer>
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          <Button color="warning" simple onClick={this.onBack}>
            <KeyboardBackspace />
            <span className="btn-text">quay lại</span>
          </Button>
          {initialValues ? (
            <Button type="submit" simple color="success">
              <Save />
              <span className="btn-text">Lưu</span>
            </Button>
          ) : (
            <Button type="submit" simple color="success">
              <Add />
              <span className="btn-text">tạo</span>
            </Button>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ partner }) => {
  return {
    brand: partner.list
  };
};
const styled = withStyles(selectStyles)(ProductForm);
const formBlock = reduxForm({ form: "baseProduct" })(styled);
export default connect(
  mapStateToProps,
  { getPartner }
)(formBlock);
