import {AUTH} from "actions/constant";
const initialState = {
    token: "",
    auth_state: null,
    // isAdmin: false
}

export default (state = initialState, action) => {
    const {type, token, payload} = action;
    switch (type) {

        case AUTH.LOGIN:
            return {...state}

        case AUTH.LOGIN_SUCCESS:
            localStorage.setItem("token", token);
            return {...state, token}

        case AUTH.LOGIN_FAILED:
            return {...state};

        case AUTH.LOGOUT:
            localStorage.removeItem("token");
            return {...state, token: ""};

        case AUTH.AUTH_STATE_SUCCESS:
            return {...state, auth_state: payload, token }

        case AUTH.AUTH_STATE_FAILED:
                return {...state, auth_state: null, token: ""}

        default:
            return {...state};
    }
}