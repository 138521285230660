import React, { Component } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";

class AboutRequestContact extends Component {
  state = {};
  render() {
    return (
      <div className="support-box">
        <div className="support-wrap">
          <div className="support-heading">
            <h4>
              <IntlMessages id="support.title" />
            </h4>
          </div>
          <div className="support-body">
            <p className="support-text">
              <IntlMessages id="support.text" />
            </p>
            <Link to="/contact" className="btn-support">
              <IntlMessages id="support.button" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutRequestContact;
