import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core";
import SideBar from "components/Admin/SideBar";
import TopBar from "components/Admin/TopBar";
import Loader from "components/Admin/Loader";
import routes from "routes.js";
import { authStateChange } from "actions/admin/auth";

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(2)
  }
}));

export default function AdminLayout(props) {
  const classes = useStyles();
  const { rest } = props;
  const [mobile, setMobile] = useState(false);
  const auth_state = useSelector(({ auth }) => auth.auth_state);
  const dispatch = useDispatch();

  const toggleDrawer = () => setMobile(!mobile);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/dashboard") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    async function onFetch() {
      dispatch(authStateChange());
    }
    onFetch();
  }, [dispatch]);

  if (!auth_state) {
    return (
      <div className="full-loading white">
        <Loader />
      </div>
    );
  }

  return (
    <div className={`${classes.root} admin-page`}>
      <Helmet>
        <title>CSP - DASHBOARD</title>
      </Helmet>
      <CssBaseline />
      <TopBar toggleDrawer={toggleDrawer} />
      <SideBar
        mobileOpen={mobile}
        toggleDrawer={toggleDrawer}
        userInfo={auth_state}
        routes={routes}
        {...rest}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>{getRoutes(routes)}</Switch>
      </main>
    </div>
  );
}
