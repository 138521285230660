import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Flags from "country-flag-icons/react/3x2";
import _ from "lodash";
import { Add, Close } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Modal from "components/Admin/Modal";
import Heading from "components/Heading";
import Loader from "components/Admin/Loader";
import Form from "./Form";
import SEOIndex from "./SEOIndex";
import {
  getHistory,
  addHistory,
  deleteHistory,
  updateHistory
} from "actions/admin/company";
import { TOAST_OPTIONS } from "helpers";

class Timeline extends Component {
  state = {
    list: null,
    loading: false,
    loading_list: false,
    detail: "",
    lang: null,
    addState: false,
    detailState: false
  };

  _isMounted = false;

  componentDidMount() {
    this.onLoad();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async onLoad() {
    this._isMounted = true;
    await this.props.getHistory();
    if (this._isMounted) {
      this.setState({ list: this.props.list });
    }
  }

  // RENDER LISTING
  renderList = list => {
    return list.map((hist, key) => {
      return (
        <GridItem xs={12} key={key}>
          <div className="timeline-item">
            <div className="heading">
              <div className="year">{hist.year}</div>
              <div className="title">{hist["vi"].title}</div>
            </div>
            <div className="actions">
              <Tooltip title="Tiếng anh">
                <Button
                  simple
                  justIcon
                  onClick={() => this.handleOpen("detail", hist, "en")}
                >
                  <Flags.US />
                </Button>
              </Tooltip>
              <Tooltip title="Tiếng việt">
                <Button
                  simple
                  justIcon
                  onClick={() => this.handleOpen("detail", hist, "vi")}
                >
                  <Flags.VN />
                </Button>
              </Tooltip>
              <Tooltip title="Xóa">
                <Button
                  simple
                  justIcon
                  color="danger"
                  onClick={() => this.handleDelete(hist.id)}
                >
                  <Close />
                </Button>
              </Tooltip>
            </div>
          </div>
        </GridItem>
      );
    });
  };

  // ACTIONS
  handleAdd = async dataValue => {
    this.onLoader();
    try {
      const response = await this.props.addHistory(dataValue);
      const { message } = response.data;
      this.onLoad();
      this.handleClose();
      toast.success(message, TOAST_OPTIONS);
    } catch (error) {
      const { data } = error.response;
      toast.error(data.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  handleDelete = async id => {
    this.setState({ loading_list: true });
    try {
      const response = await this.props.deleteHistory(id);
      const { message } = response.data;
      this.onLoad();
      toast.success(message, TOAST_OPTIONS);
    } catch (error) {
      const { data } = error.response;
      toast.error(data.message, TOAST_OPTIONS);
    }
    this.setState({ loading_list: false });
  };

  handleUpdate = async dataValue => {
    const { lang } = this.state;
    this.onLoader();
    try {
      const response = await this.props.updateHistory(
        dataValue.id,
        dataValue,
        lang
      );
      const { message } = response.data;
      this.onLoad();
      this.handleClose();
      toast.success(message, TOAST_OPTIONS);
    } catch (error) {
      const { data } = error.response;
      toast.error(data.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  // ----------HANDLE OPEN
  handleOpen = (name, detail, lang) => {
    const langDetail = _.pick(detail, [lang]);
    const currDetail = _.omit(detail, ["vi", "en"]);
    const newDetail = {
      ...currDetail,
      ...langDetail[lang]
    };
    this.setState({
      [`${name}State`]: true,
      detail: newDetail,
      lang
    });
  };
  // ---------HANDLE CLOSE
  handleClose = () => {
    this.setState(
      {
        addState: false,
        detailState: false
      },
      () => {
        setTimeout(() => this.setState({ detail: "", lang: null }), 500);
      }
    );
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  // RENDER UI
  render() {
    const {
      list,
      detail,
      lang,
      addState,
      detailState,
      loading,
      loading_list
    } = this.state;
    const splashScreen = (
      <div className="full-loading">
        <Loader />
      </div>
    );
    return (
      <Card>
        <CardBody>
          <div className="mb-4">
            <SEOIndex />
          </div>
          <div className="position-relative">
            <Heading title="LỊCH SỬ CÔNG TY" />
            <Tooltip title="Thêm mốc thời gian">
              <Button
                color="success"
                className="btn-float"
                onClick={() => this.handleOpen("add")}
              >
                <Add />
              </Button>
            </Tooltip>
          </div>
          <Modal
            open={addState}
            onClose={this.handleClose}
            title="THÊM MỐC THỜI GIAN MỚI"
            transitionDuration={700}
          >
            {loading && splashScreen}
            <Form onSubmit={this.handleAdd} handleClose={this.handleClose} />
          </Modal>
          <Modal
            open={detailState}
            onClose={this.handleClose}
            title={`CHI TIẾT MỐC THỜI GIAN - ${lang === "vi" ? "VIỆT" : "ANH"}`}
            transitionDuration={700}
          >
            {detail && (
              <Form
                initialValues={detail}
                onSubmit={this.handleUpdate}
                handleClose={this.handleClose}
              />
            )}
          </Modal>
          <GridContainer>
            {loading_list && splashScreen}
            {list ? this.renderList(list) : splashScreen}
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = ({ company }) => {
  return {
    list: company.history_anchor
  };
};
export default connect(
  mapStateToProps,
  {
    getHistory,
    addHistory,
    deleteHistory,
    updateHistory
  }
)(Timeline);
