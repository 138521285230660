import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Flags from "country-flag-icons/react/3x2";
import _ from "lodash";
// MATERIAL
import { Card, Tooltip } from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
// COMPONENT
import Button from "components/CustomButtons";
import Loader from "components/Admin/Loader";
import Modal from "components/Admin/Modal";
import CreatePart from "./CreatePart";
import DetailPart from "./DetailPart";
import { getParts, deletePart } from "actions/admin/parts";
import defaultImage from "assets/img/image_placeholder.jpg";
import { TABLE_CONFIG, TOAST_OPTIONS } from "helpers";
class ProductPart extends Component {
  state = {
    loading: true,
    addState: false,
    detailState: false,
    detail: null,
    lang: null
  };

  btnConfig = {
    justIcon: true,
    simple: true
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  async onLoad() {
    await this.props.getParts();
    this.setState({ loading: false });
  }

  renderList = () => {
    const { list } = this.props;
    if (list) {
      return list.map(prop => {
        return {
          logo: (
            <Card>
              <img
                src={prop.thumbnail || defaultImage}
                alt={`logo-${prop.name}`}
                className="img-fluid"
              />
            </Card>
          ),
          product_name: (
            <Button className="btn-title" color="primary" simple>
              {prop["vi"].name}
            </Button>
          ),
          brand_name: (
            <Button className="btn-title" color="primary" simple>
              {prop.brand_name}
            </Button>
          ),
          language: (
            <div className="actions-left">
              <Tooltip title="Tiếng anh">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop, "en")}
                >
                  <Flags.US />
                </Button>
              </Tooltip>
              <Tooltip title="Tiếng việt">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop, "vi")}
                >
                  <Flags.VN />
                </Button>
              </Tooltip>
            </div>
          ),
          actions: (
            <div className="actions-right">
              <Tooltip title="Xóa sản phẩm">
                <Button
                  {...this.btnConfig}
                  color="danger"
                  onClick={() => this.handleDelete(prop)}
                >
                  <Delete />
                </Button>
              </Tooltip>
            </div>
          )
        };
      });
    }
  };

  handleOpen = async state => {
    this.setState({
      [`${state}State`]: true
    });
  };

  handleClose = () => {
    this.setState({
      addState: false,
      detailState: false,
      detail: null,
      lang: null
    });
  };

  handleDelete = async prop => {
    this.setState({ loading: true });
    try {
      await this.props.deletePart(prop.id);
      await this.props.getParts();
      toast.success("Sản phẩm đã được xóa", TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.setState({ loading: false });
  };

  handleEdit = async (prop, lang) => {
    const product_data = _.omit(prop, ["vi", "en"]);
    const dataVersion = _.pick(prop, [lang]);
    const version = dataVersion[lang];
    const detail = {
      ...product_data,
      ...version
    };
    this.setState({
      detailState: true,
      detail,
      lang
    });
  };

  render() {
    const columns = [
      { Header: "Logo", accessor: "logo", width: 180 },
      { Header: "Tên sản phẩm", accessor: "product_name", minWidth: 150 },
      { Header: "Hãng", accessor: "brand_name", minWidth: 150 },
      { Header: "Ngôn Ngữ", accessor: "language", width: 150 },
      { Header: "Thao tác", accessor: "actions", width: 110 }
    ];
    const { loading,addState, detail, detailState, lang } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Modal
          open={addState}
          onClose={this.handleClose}
          title="LINH KIỆN"
          transitionDuration={700}
          maxWidth="md"
          fullWidth
        >
          <CreatePart handleClose={this.handleClose} />
        </Modal>
        {detail && (
          <Modal
            open={detailState}
            onClose={this.handleClose}
            title={`LINH KIỆN- ${lang === "vi" ? "TIẾNG VIỆT" : "TIẾNG ANH"}`}
            transitionDuration={700}
            maxWidth="md"
            fullWidth
          >
            <DetailPart
              handleClose={this.handleClose}
              detail={detail}
              lang={lang}
            />
          </Modal>
        )}
        <div className="mb-2 text-right">
          <Tooltip title="Thêm sản phẩm">
            <Button
              justIconRec
              size="sm"
              color="success"
              onClick={() => this.handleOpen("add")}
            >
              <Add />
            </Button>
          </Tooltip>
        </div>
        <ReactTable
          {...TABLE_CONFIG}
          data={this.renderList()}
          columns={columns}
          loading={loading}
          loadingText={<Loader />}
          defaultPageSize={5}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ product_part }) => {
  return {
    list: product_part.list
  };
};

export default connect(
  mapStateToProps,
  {
    getParts,
    deletePart
  }
)(ProductPart);
