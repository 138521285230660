import React, { Component } from "react";
import PURL from "apis/PublicURL";
import _ from "lodash";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import svProvide from "assets/img/csp/sv-provide.jpg";
import IconHeading from "assets/img/csp/ic-heading.png";
import FadeInSection from "components/_App/FadeInSection";
import IntlMessages from "util/IntlMessages";

const bullmerId = "R9TqcnxPl3qUC3u6Tn0i";
const ATPId = "BHaLGtW8SkTNNC8QHdOH";
const TODTId = "Id9NjztLRCxLld3UDvcN";
const graphtecId = "gkKI7oeXMPLjJaW8TvYl";
const others = "others";

class Providing extends Component {
  state = {
    bullmer: [],
    graphtec: [],
    tdot: [],
    atp: [],
    others: []
  };

  componentDidMount() {
    this.onLoadProducts(bullmerId, "bullmer");
    this.onLoadProducts(graphtecId, "graphtec");
    this.onLoadProducts(TODTId, "tdot");
    this.onLoadProducts(ATPId, "atp");
    this.onLoadProducts(others, "others");
  }

  async onLoadProducts(id, name) {
    const response = await PURL.get(`/company/parts/${id}`);
    const list = _.orderBy(response.data, ["created_on"]);
    this.setState({ [name]: list });
  }

  onRenderList(list) {
    const { locale } = this.props;
    return list.map((item, key) => {
      return (
        <GridItem xs={12} sm={3} key={key} className="part-row">
          <div className="part-item">
            <img src={item.thumbnail} alt={item.id} className="img-fluid" />
            <p className="text">{item[locale].name}</p>
          </div>
        </GridItem>
      );
    });
  }

  render() {
    const { bullmer, graphtec, tdot, atp, others } = this.state;
    return (
      <FadeInSection>
        <section className="provide-section">
          <div className="provide-header">
            <GridContainer>
              <GridItem xs={12} sm={6} className="provide-header-col">
                <GridItem xs={10} lg={9} xl={10}>
                  <div className="provide-text">
                    <span>
                      <IntlMessages id="service.provide.title" />
                    </span>
                    <span>
                      <IntlMessages id="service.provide.sub" />
                    </span>
                  </div>
                </GridItem>
              </GridItem>
              <GridItem xs={12} sm={6} className="provide-header-col">
                <img src={svProvide} alt="provide" />
              </GridItem>
            </GridContainer>
          </div>
          <div className="provide-list bg-white section-box">
            <div className="csp-container">
              <div className="main-title-wrap">
                <h2 className="main-title">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                  <span>Bullmer</span>
                </h2>
              </div>
              <GridContainer>
                {bullmer && this.onRenderList(bullmer)}
              </GridContainer>
              <div className="main-title-wrap">
                <h2 className="main-title">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                  <span>Graphtec</span>
                </h2>
              </div>
              <GridContainer>
                {graphtec && this.onRenderList(graphtec)}
              </GridContainer>
              <div className="main-title-wrap">
                <h2 className="main-title">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                  <span>TDOT</span>
                </h2>
              </div>
              <GridContainer>{tdot && this.onRenderList(tdot)}</GridContainer>
              <div className="main-title-wrap">
                <h2 className="main-title">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                  <span>ATP</span>
                </h2>
              </div>
              <GridContainer>{atp && this.onRenderList(atp)}</GridContainer>
              <div className="main-title-wrap">
                <h2 className="main-title">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                  <span>
                    <IntlMessages id="service.products.rest" />
                  </span>
                </h2>
              </div>
              <GridContainer>
                {others && this.onRenderList(others)}
              </GridContainer>
            </div>
          </div>
        </section>
      </FadeInSection>
    );
  }
}
const mapStateToProps = ({ settings }) => {
  return {
    locale: settings.locale.locale
  };
};
export default connect(mapStateToProps)(Providing);
