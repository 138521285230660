import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Admin/Loader";
import Phone from "assets/img/csp/phone.png";
import Mail from "assets/img/csp/envelope.png";
import Clock from "assets/img/csp/clock.png";
import Map from "pages/Admin/Company/Info/components/Contact/Map";
import RequestACallBack from "components/_App/RqCb/Contact";
import { getContact } from "actions/landing";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

class Contact extends Component {
  state = {
    contact: ""
  };

  componentDidMount() {
    this.onLoad();
    ReactGA.pageview(window.location.pathname);
  }

  async onLoad() {
    await this.props.getContact();
    this.setState({ contact: this.props.list });
  }

  onRender = list => {
    let idIntlMessage = "contact.main.office";
    const { locale } = this.props;

    return list.map((prop, key) => {
      if (prop.id !== "9102jCK5v6gsO6ogNSNK") {
        idIntlMessage = "contact.sub.office";
      }
      return (
        <GridItem xs={12} sm={6} md={12} key={key} className="address-row">
          <p className="address-title">
            <IntlMessages id={idIntlMessage} />
          </p>
          <p>{prop[`building_${locale}`]}</p>
          <p>{prop[`address_${locale}`]}</p>
          <p className="telephone">
            <IntlMessages id="contact.string.tel" />: {prop.tel}
          </p>
          <p className="fax">Fax: {prop.fax}</p>
          <p className="tax-number">
            <IntlMessages id="contact.string.taxNumber" />: {prop.tax_number}
          </p>
          <p className="email">Email: {prop.email}</p>
          <p>
            Website:{" "}
            <span>
              <a href="http://cspco.com.vn">http://cspco.com.vn</a>
            </span>
          </p>
        </GridItem>
      );
    });
  };

  render() {
    const { contact } = this.state;
    if (!contact) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      );
    }
    return (
      <Fragment>
        <Helmet>
          <title>CONTACT - CSPCO</title>
          <meta name="title" content="CONTACT - CSPCO" />
          <meta name="description" content="CONTACT - CSPCO" />
          <meta name="keywords" content="CONTACT,CSPCO" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className="csp-contact bg-white section-box">
          <div className="csp-container">
            <div className="info-box">
              <GridContainer>
                <GridItem xs={12} sm={6} md={3} className="contact-item">
                  <div className="contact-icon">
                    <img src={Phone} alt="phone" />
                  </div>
                  <div className="contact-content">
                    <p>Hotline</p>
                    <p>Nguyen Hoang Nam </p>
                    <p>
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=nam.nh@cspco.com.vn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        nam.nh@cspco.com.vn
                      </a>
                    </p>
                    <p>+ 84 (0) 918305152</p>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} className="contact-item">
                  <div className="contact-icon">
                    <img src={Phone} alt="phone" />
                  </div>
                  <div className="contact-content">
                    <p>
                      <IntlMessages id="contact.support" />
                    </p>
                    <p>
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=salesadmin@cspco.com.vn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        salesadmin@cspco.com.vn
                      </a>
                    </p>
                    <p>+ 84 (0) 911087091</p>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} className="contact-item">
                  <div className="contact-icon">
                    <img src={Mail} alt="mail" />
                  </div>
                  <div className="contact-content">
                    <p>
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=info@cspco.com.vn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@cspco.com.vn
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=ketoan@cspco.com.vn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ketoan@cspco.com.vn
                      </a>
                    </p>
                    <p>
                      <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=invoicecsp@cspco.com.vn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        invoicecsp@cspco.com.vn
                      </a>
                    </p>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} className="contact-item">
                  <div className="contact-icon">
                    <img src={Clock} alt="clock" />
                  </div>
                  <div className="contact-content">
                    <p>
                      <IntlMessages id="contact.weekdays" />
                    </p>
                    <p>
                      <IntlMessages id="contact.saturday" />
                    </p>
                    <p>
                      <IntlMessages id="contact.weekend" />
                    </p>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
            <div className="address-box">
              <GridContainer>
                <GridItem xs={12} md={8}>
                  <div className="address-heading">
                    <IntlMessages id="contact.heading.map" />
                  </div>
                  <div className="address-map">
                    <Map places={contact} />
                  </div>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <div className="address-heading">
                    <IntlMessages id="contact.heading.office" />
                  </div>
                  <GridContainer className="address-company">
                    {this.onRender(contact)}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <RequestACallBack />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ landing, settings }) => {
  return {
    list: landing.contact,
    locale: settings.locale.locale
  };
};

export default connect(
  mapStateToProps,
  {
    getContact
  }
)(Contact);
