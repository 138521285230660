import React, { useEffect, useState } from "react";
import PURL from "apis/PublicURL";
import { useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import certOp from "assets/img/csp/cert-optitex.jpg";
import certBullmer from "assets/img/csp/cert-bullmer.jpg";
import certGraphtec from "assets/img/csp/cert-graphtec.jpg";
import IconHeading from "assets/img/csp/ic-heading.png";
import Loader from "components/Admin/Loader";
import FadeInSection from "components/_App/FadeInSection";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";

// SEO ID OUR PROFILE
const SEO_ID = "2hPYnfdgKYOg0vpELYV0";

const ProfileRow = ({ id, children }) => {
  return (
    <GridContainer className="company-profile-row">
      <GridItem xs={12} sm={4} className="company-profile-title">
        <IntlMessages id={id} />
      </GridItem>
      <GridItem xs={12} sm={8} className="company-profile-text">
        {children}
      </GridItem>
    </GridContainer>
  );
};

export default function Profile() {
  const [info, setInfo] = useState("");
  const [detail, setDetail] = useState("");
  const locale = useSelector(({ settings }) => settings.locale.locale);
  useEffect(() => {
    async function onFetch() {
      try {
        const response = await PURL.get(`/company/information`);
        setInfo(response.data);
        const res = await PURL.get(`company/seo/${SEO_ID}`);
        setDetail(res.data);
      } catch (error) {
        console.log("Failed to fetch list", error.message);
      }
    }
    onFetch();
    ReactGA.pageview(window.location.pathname);
  }, []);

  if (!info) {
    return (
      <div className="page-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="col-sm-12 col-lg-9 company-profile">
      {detail && (
        <CustomHelmet
          detail={detail}
          imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/wwa-logo.jpeg?alt=media&token=09bf3a41-a108-4177-9978-8db442a8a338"
        />
      )}
      <FadeInSection>
        <div className="company-profile-wrap">
          <div className="company-profile-box">
            <ProfileRow id="about.profile.table.name">
              {info[locale].name}
            </ProfileRow>
            <ProfileRow id="about.profile.table.head">
              {info[locale].hq} {info.hq_tel} {info.hq_mail}
            </ProfileRow>
            <ProfileRow id="about.profile.table.director">
              {info[locale].ceo}
            </ProfileRow>
            <ProfileRow id="about.profile.table.establish">
              {info.year}
            </ProfileRow>
            <ProfileRow id="about.profile.table.branch">
              {info[locale].br} {info.br_tel} {info.br_mail}
            </ProfileRow>
            <ProfileRow id="about.profile.table.employee">
              {info.employees}
            </ProfileRow>
            <ProfileRow id="about.profile.table.major">
              {info[locale].major}
            </ProfileRow>
          </div>
          <div className="company-profile-honor">
            <div className="main-title-wrap">
              <h2 className="main-title">
                <img
                  src={IconHeading}
                  className="ic-heading"
                  alt="icon-heading"
                />
                <span>
                  <IntlMessages id="about.profile.cert" />
                </span>
              </h2>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12}>
                <img src={certOp} alt="cert-optitex" className="img-fluid" />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <img
                  src={certGraphtec}
                  alt="cert-graphtec"
                  className="img-fluid"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <img
                  src={certBullmer}
                  alt="cert-bullmer"
                  className="img-fluid"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </FadeInSection>
    </div>
  );
}
