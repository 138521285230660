import React, { Component } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTable from "react-table";
import toast from "cogo-toast";
// Material
import { Card, Tooltip } from "@material-ui/core";
import { Delete, Link } from "@material-ui/icons";
// Component
import Button from "components/CustomButtons";
import Modal from "components/Admin/Modal";
import Loader from "components/Admin/Loader";
import { TABLE_CONFIG, TOAST_OPTIONS } from "helpers";
import { getImages, deletePhoto } from "actions/admin/photo";

class ListImage extends Component {
  state = {
    imgSrc: null,
    open: false,
    loading: true
  };

  btnConfig = {
    simple: true,
    justIcon: true
  };

  async componentDidMount() {
    await this.props.getImages();
    this.setState({ loading: false });
  }

  handleOpen = imgSrc => this.setState({ open: true, imgSrc });
  handleClose = () => this.setState({ open: false });

  renderImageList = () => {
    const { list } = this.props;
    if (list) {
      return list.map(prop => {
        return {
          image: (
            <Card onClick={() => this.handleOpen(prop.src)}>
              <img src={prop.src} alt={prop.alt} className="img-fluid" />
            </Card>
          ),
          name: prop.name,
          actions: (
            <div className="actions-right">
              <Tooltip title="Sao chép đường dẫn">
                <CopyToClipboard text={prop.src}>
                  <Button
                    {...this.btnConfig}
                    color="success"
                    onClick={() => this.showMessage(prop.name)}
                  >
                    <Link />
                  </Button>
                </CopyToClipboard>
              </Tooltip>
              <Tooltip title="Xóa hình ảnh">
                <Button
                  {...this.btnConfig}
                  color="danger"
                  onClick={() => this.handleDelete(prop)}
                >
                  <Delete />
                </Button>
              </Tooltip>
            </div>
          )
        };
      });
    }
  };

  showMessage = imgName => {
    toast.success(
      <div className="message-text">
        <span>Đã sao chép thành công đường dẫn hình: </span>
        <p>{imgName}</p>
      </div>,
      TOAST_OPTIONS
    );
  };

  handleDelete = imgProps => {
    const { id, src, name } = imgProps;
    this.props
      .deletePhoto(id, src)
      .then(() => {
        this.props.getImages();
        toast.success(
          <div className="message-text">
            <span>Đã xóa hình ảnh</span>
            <p>{name}</p>
          </div>,
          TOAST_OPTIONS
        );
      })
      .catch(err => {
        toast.error(err, TOAST_OPTIONS);
      });
  };

  render() {
    const columns = [
      { Header: "Ảnh", accessor: "image", width: 180 },
      { Header: "Tên ảnh", accessor: "name", minWidth: 250 },
      { Header: "Thao tác", accessor: "actions", width: 150 }
    ];
    const { imgSrc, open, loading } = this.state;
    return (
      <div>
        <Modal open={open} onClose={this.handleClose}>
          <img src={imgSrc} alt="preview" className="img-fluid" />
        </Modal>
        <ReactTable
          {...TABLE_CONFIG}
          defaultPageSize={5}
          data={this.renderImageList()}
          columns={columns}
          loading={loading}
          loadingText={<Loader />}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ photo }) => {
  return {
    list: photo.list
  };
};
export default connect(
  mapStateToProps,
  {
    getImages,
    deletePhoto
  }
)(ListImage);
