import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { Save, Edit, KeyboardBackspace } from "@material-ui/icons";
import Input from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons";
import defaultImage from "assets/img/image_placeholder.jpg";

class BaseBannerForm extends Component {
  state = {
    url: "",
    background_url: "",
    title_vi: "",
    title_en: "",
    content_en: "",
    content_vi: "",
    isEdit: true
  };

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues) {
      this.onLoad(initialValues);
    }
  }

  onLoad(prop) {
    this.setState({
      url: prop.url || "",
      background_url: prop.background_url || "",
      title_vi: prop.title_vi || "",
      title_en: prop.title_en || "",
      content_en: prop.content_en || "",
      content_vi: prop.content_vi || ""
    });
  }

  renderField = ({ input, dataValue, label, disabled }) => {
    return (
      <Input
        labelText={label}
        formControlProps={{ fullWidth: true }}
        inputProps={{
          name: input.name,
          onChange: this.handleChange,
          value: dataValue,
          fullWidth: true,
          multiline: true,
          disabled
        }}
      />
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  handleBack = () => this.setState({ isEdit: true });
  handleEdit = () => this.setState({ isEdit: false });

  onSubmit = value => {
    this.props.handleSubmitForm(value);
    this.handleBack();
  };

  getBgImage = url => {
    if (url) {
      return url;
    }
    return defaultImage;
  };

  render() {
    const {
      url,
      background_url,
      title_vi,
      title_en,
      content_en,
      content_vi,
      isEdit
    } = this.state;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <GridContainer justify="center">
          <GridItem xs={12} md={4} className="text-center">
            <img
              src={this.getBgImage(url)}
              alt="banner-preview"
              className="img-fluid banner-preview"
            />
            <Field
              component={this.renderField}
              name="url"
              label="HÌNH ẢNH"
              dataValue={url}
              disabled={isEdit}
            />
          </GridItem>
          <GridItem xs={12} md={4} className="text-center">
            <img
              src={this.getBgImage(background_url)}
              alt="banner-preview"
              className="img-fluid banner-preview"
            />
            <Field
              component={this.renderField}
              name="background_url"
              label="HÌNH ẢNH NỀN"
              dataValue={background_url}
              disabled={isEdit}
            />
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={12}>
                <Field
                  component={this.renderField}
                  name="title_en"
                  label="TIÊU ĐỀ (EN)"
                  dataValue={title_en}
                  disabled={isEdit}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  component={this.renderField}
                  name="title_vi"
                  label="TIÊU ĐỀ (VI)"
                  dataValue={title_vi}
                  disabled={isEdit}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  component={this.renderField}
                  name="content_en"
                  label="THÔNG TIN (EN)"
                  dataValue={content_en}
                  disabled={isEdit}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  component={this.renderField}
                  name="content_vi"
                  label="THÔNG TIN (VI)"
                  dataValue={content_vi}
                  disabled={isEdit}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <div className="actions-wrap">
          <div className="text-right">
            {!isEdit ? (
              <Fragment>
                <Button color="warning" simple onClick={this.handleBack}>
                  <KeyboardBackspace />
                  <span className="btn-text">Quay lại</span>
                </Button>
                <Button color="success" simple type="submit">
                  <Save />
                  <span className="btn-text">Lưu</span>
                </Button>
              </Fragment>
            ) : (
              <Button color="primary" simple onClick={this.handleEdit}>
                <Edit />
                <span className="btn-text">Chỉnh sửa</span>
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({ form: "BaseBannerForm" })(BaseBannerForm);
