import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
// MATERIAL
import {
  withStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core";
import { Close, Add } from "@material-ui/icons";
import selectStyles from "assets/jss/m-dashboard/customSelectStyle";
// COMPONENT
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import defaultImage from "assets/img/image_placeholder.jpg";
import { getPartner } from "actions/admin/partner";

class ProductPartForm extends Component {
  state = {
    open: false,
    name: "",
    brand: "",
    thumbnail: "",
    preview_img: defaultImage
  };

  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;
    await this.props.getPartner();
    if (this.props.initialValues) {
      this.onLoadField(this.props.initialValues);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //   ON LOAD FIELD
  onLoadField = prop => {
    if (this._isMounted) {
      this.setState({
        name: prop.name || "",
        brand: prop.brand || "",
        thumbnail: prop.thumbnail || ""
      });
    }
  };

  //   RENDER COMPONENT
  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };

  renderSelect = ({ input, label, children, dataValue }) => {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          inputProps={{ name: input.name, value: dataValue }}
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          onChange={this.handleChange}
        >
          {children}
        </Select>
      </FormControl>
    );
  };

  renderBrand = () => {
    const { brand, classes } = this.props;
    if (brand) {
      const list = [...brand, { id: "others", name: "KHÁC" }];
      return list.map((prop, key) => (
        <MenuItem
          key={key}
          value={prop.id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
        >
          {prop.name.toUpperCase()}
        </MenuItem>
      ));
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  onSubmit = dataValue => this.props.submitForm(dataValue);

  render() {
    const { name, brand, thumbnail } = this.state;
    const { initialValues, handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <img
              src={thumbnail || defaultImage}
              alt="thumbnail"
              className="img-fluid"
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={4}>
            <Field
              component={this.renderField}
              label="Tên sản phẩm"
              name="name"
              dataValue={name}
              required
            />
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Field
              component={this.renderSelect}
              label="Hãng sản xuất"
              name="brand"
              dataValue={brand}
            >
              {this.renderBrand()}
            </Field>
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              name="thumbnail"
              label="Hình sản phẩm"
              dataValue={thumbnail}
              multiline
            />
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap">
          <Button
            simple
            color="danger"
            onClick={() => this.props.handleClose()}
          >
            <Close /> Đóng
          </Button>
          {initialValues ? (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Add /> lưu
            </Button>
          ) : (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Add /> tạo
            </Button>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ partner }) => {
  return {
    brand: partner.list
  };
};
const styled = withStyles(selectStyles)(ProductPartForm);
const formBlock = reduxForm({ form: "baseProductPart" })(styled);
export default connect(
  mapStateToProps,
  { getPartner }
)(formBlock);
