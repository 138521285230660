import { AUTH } from "../constant";
import { auth, PROVIDER, db } from "apis/config";
import history from "history.js";
import toast from "cogo-toast";
import { TOAST_OPTIONS } from "helpers";
// SignIn
export const onSignIn = () => {
  return dispatch => {
    dispatch({ type: AUTH.LOGIN });
    auth
      .signInWithPopup(PROVIDER.GOOGLE)
      .then(res => {
        const email = res.user.email;
        db.collection("users")
          .doc(email)
          .get()
          .then(async doc => {
            if (doc.exists) {
              const token = await auth.currentUser.getIdToken();
              const body = { email, token };
              dispatch(onSignInSuccess(body));
              history.push("/dashboard/gallery");
            } else {
              auth.signOut();
              toast.error(
                `Tài khoản ${email} không có trong hệ thống`,
                TOAST_OPTIONS
              );
            }
          });
      })
      .catch(err => {
        dispatch(onSignInFailed(err));
      });
  };
};
const onSignInSuccess = res => {
  return dispatch => {
    dispatch({
      type: AUTH.LOGIN_SUCCESS,
      token: res.token
    });
    toast.success(`Tài khoản ${res.email} đăng nhập thành công`, TOAST_OPTIONS);
  };
};
const onSignInFailed = err => async dispatch =>
  dispatch({ type: AUTH.LOGIN_FAILED });
export const onSignOut = () => async dispatch => {
  await auth.signOut();
  history.push("/auth");
  dispatch({ type: AUTH.LOGOUT });
};

// Auth State Change
export const authStateChange = () => {
  return dispatch => {
    dispatch({ type: AUTH.AUTH_STATE });
    auth.onAuthStateChanged(async res => {
      if (res) {
        const token = await auth.currentUser.getIdToken();
        const data = await res.getIdTokenResult();
        const body = { token, data };
        dispatch(authStateSuccess(body));
      } else {
        dispatch(authStateFailed());
      }
    });
  };
};
const authStateSuccess = res => {
  return {
    type: AUTH.AUTH_STATE_SUCCESS,
    payload: res.data,
    token: res.token
  };
};
const authStateFailed = () => {
  return { type: AUTH.AUTH_STATE_FAILED };
};
