import { PRODUCT } from "../constant";
import ADMIN_URL from "apis/AdminURL";

// ===== PRODUCT =====
export const getProduct = () => async dispatch => {
  const response = await ADMIN_URL.get("/product/all");
  dispatch({
    type: PRODUCT.GET_PRODUCT,
    payload: response.data
  });
};
export const getProductDetail = (id, lang) => async dispatch => {
  const response = await ADMIN_URL.get(`/product/${id}/${lang}`);
  dispatch({
    type: PRODUCT.GET_PRODUCT_DETAIL,
    payload: response.data
  });
};
export const addProduct = dataValue => async dispatch => {
  await ADMIN_URL.post("/product/add", dataValue);
  dispatch({ type: PRODUCT.ADD_PRODUCT });
};
export const deleteProduct = id => async dispatch => {
  await ADMIN_URL.delete(`/product/${id}`);
  dispatch({ type: PRODUCT.DELETE_PRODUCT });
};
export const updateProduct = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/product/${id}/${lang}`, dataValue);
  dispatch({ type: PRODUCT.UPDATE_PRODUCT });
};

// === OPTITEX ===

// == CATEGORY ==
export const getCateOP = () => async dispatch => {
  const response = await ADMIN_URL.get("/cate/optitex");
  dispatch({
    type: PRODUCT.GET_OP_CATE,
    payload: response.data
  });
};
export const addCateOP = dataValue => async dispatch => {
  await ADMIN_URL.post("/cate/optitex", dataValue);
  dispatch({ type: PRODUCT.ADD_OP_CATE });
};
export const deleteCateOP = id => async dispatch => {
  await ADMIN_URL.delete(`/cate/optitex/${id}`);
  dispatch({ type: PRODUCT.DELETE_OP_CATE });
};
export const updateCateOP = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/cate/optitex/${id}/lang/${lang}`, dataValue);
  dispatch({ type: PRODUCT.UPDATE_OP_CATE });
};

// == PRODUCT ==
export const getProductOP = () => async dispatch => {
  const response = await ADMIN_URL.get("/product/optitex");
  dispatch({
    type: PRODUCT.GET_OP_PRODUCT,
    payload: response.data
  });
};
export const addProductOP = dataValue => async dispatch => {
  await ADMIN_URL.post("/product/optitex/add", dataValue);
  dispatch({ type: PRODUCT.ADD_OP_PRODUCT });
};
export const deleteProductOP = id => async dispatch => {
  await ADMIN_URL.delete(`/product/optitex/${id}`);
  dispatch({ type: PRODUCT.DELETE_OP_PRODUCT });
};
export const updateProductOP = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/product/optitex/${id}/lang/${lang}`, dataValue);
  dispatch({ type: PRODUCT.UPDATE_OP_PRODUCT });
};
