import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { ExpandLess } from "@material-ui/icons";
// import RecentNews from "./RecentNews";
// import RecentEvents from "./RecentEvents";
import Popover from "@material-ui/core/Popover";
// import Tooltip from "@material-ui/core/Tooltip";
import swal from "sweetalert";
import cx from "classnames";
import logo from "assets/img/csp/logo-trans.png";
import IconChat from "./ChatIcon";
import FbIcon from "./FbIcon";
import LinkInIcon from "./LinkInIcon";
import ZaloIcon from "./ZaloIcon";
import WAIcon from "./WAIcon";
import SkypeIcon from "./SkypeIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PURL from "apis/PublicURL";

// const aboutCSP = [
//   { id: "about.profile.title", path: "/about/company-profile" },
//   { id: "about.history.title", path: "/about/history" },
//   { id: "about.partner.menu", path: "/about/partner" },
//   { id: "about.team.title", path: "/about/team" }
// ];

const social = [
  {
    icon: <LinkInIcon />,
    linkProps: {
      href: "https://www.linkedin.com/in/csp-cong-ty-tnhh-43a248196/",
      target: "_blank",
      rel: "noopener noreferrer"
    }
  },
  {
    icon: <ZaloIcon />,
    linkProps: {
      href: "https://zalo.me/0938623889",
      target: "_blank",
      rel: "noopener noreferrer"
    }
  },
  {
    icon: <FbIcon />,
    linkProps: {
      href: "https://facebook.com/csp.congtytnhh?ref=bookmarks",
      target: "_blank",
      rel: "noopener noreferrer"
    }
  },
  {
    icon: <WAIcon />,
    tooltip: true,
    linkProps: {
      title: "WhatsApp",
      href: "+84 (0)938623889",
      anchor: "whatAppEl"
    }
  },
  {
    icon: <IconChat />,
    tooltip: true,
    linkProps: {
      title: "Wechat ID",
      href: "victoria-cspco",
      anchor: "weChatEl"
    }
  },
  {
    icon: <SkypeIcon />,
    linkProps: {
      href: "skype:minh.tho3?chat"
    }
  }
];

// function FooterHead() {
//   return (
//     <footer id="footer-head" className="section-box">
//       <div className="csp-container">
//         <div className="row">
//           <div className="col-xs-12 col-md-6 col-lg-3">
//             <div className="title">
//               <IntlMessages id="home.menu.product.type2" />
//             </div>
//             <ul className="list">
//               <li>
//                 <Tooltip title="Bullmer">
//                   <NavLink
//                     activeClassName="active"
//                     to="/product/bullmer/R9TqcnxPl3qUC3u6Tn0i"
//                     className="sub-link"
//                   >
//                     Bullmer
//                   </NavLink>
//                 </Tooltip>
//               </li>
//               <li>
//                 <Tooltip title="Optitex">
//                   <NavLink
//                     activeClassName="active"
//                     to="/product/optitex"
//                     className="sub-link"
//                   >
//                     Optitex
//                   </NavLink>
//                 </Tooltip>
//               </li>
//               <li>
//                 <Tooltip title="TDOT">
//                   <NavLink
//                     activeClassName="active"
//                     to="/product/tdot/Id9NjztLRCxLld3UDvcN"
//                     className="sub-link"
//                   >
//                     TDOT
//                   </NavLink>
//                 </Tooltip>
//               </li>
//               <li>
//                 <Tooltip title="Graphtec">
//                   <NavLink
//                     activeClassName="active"
//                     to="/product/graphtec/gkKI7oeXMPLjJaW8TvYl"
//                     className="sub-link"
//                   >
//                     Graphtec
//                   </NavLink>
//                 </Tooltip>
//               </li>
//               <li>
//                 <Tooltip title="iDigit">
//                   <NavLink
//                     activeClassName="active"
//                     to="/product/idgit/VqYkzy7x54SDJTXTjt6G"
//                     className="sub-link"
//                   >
//                     iDigit
//                   </NavLink>
//                 </Tooltip>
//               </li>
//               <li>
//                 <Tooltip title="ATP">
//                   <NavLink
//                     activeClassName="active"
//                     to="/product/atp/BHaLGtW8SkTNNC8QHdOH"
//                     className="sub-link"
//                   >
//                     ATP
//                   </NavLink>
//                 </Tooltip>
//               </li>
//             </ul>
//           </div>
//           <div className="col-xs-12 col-md-6 col-lg-3">
//             <div className="title">
//               <IntlMessages id="home.menu.about" />
//             </div>
//             <ul className="list">
//               {aboutCSP.map((item, key) => (
//                 <li key={key}>
//                   <NavLink
//                     activeClassName="active"
//                     to={item.path}
//                     className="sub-link"
//                   >
//                     <IntlMessages id={item.id} />
//                   </NavLink>
//                 </li>
//               ))}
//             </ul>
//           </div>
//           <RecentNews />
//           <RecentEvents />
//         </div>
//       </div>
//     </footer>
//   );
// }

class FooterEnd extends Component {
  state = {
    showScroll: false,
    anchorEl: null,
    whatAppEl: null,
    weChatEl: null,
    name: "",
    phone: "",
    company: ""
  };

  componentDidMount() {
    window.addEventListener("scroll", this.checkScrollTop);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkScrollTop);
  }

  checkScrollTop = () => {
    const { showScroll } = this.state;
    const offsetTop = 800;
    if (!showScroll && window.pageYOffset > offsetTop) {
      this.setState({ showScroll: true });
    } else if (showScroll && window.pageYOffset <= offsetTop) {
      this.setState({ showScroll: false });
    }
  };

  getCurrentYear = () => {
    const cDate = new Date();
    return cDate.getFullYear();
  };

  scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  whatAppClick = event => {
    this.setState({ whatAppEl: event.currentTarget });
  };

  whatAppClose = () => {
    this.setState({ whatAppEl: null });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { name, phone, company } = this.state;

    return PURL.post("/email/messages", { name, phone, company }).then(() => {
      this.setState({
        name: "",
        phone: "",
        company: ""
      });
      swal({
        text: "Chúng tôi sẽ phản hồi lại yêu cầu của bạn sớm nhất có thể",
        icon: "success",
        button: "OK"
      });
    });
  };
  render() {
    const { name, company, phone } = this.state;
    return (
      <footer id="footer-end">
        <div className="csp-container position-relative">
          <div className="row align-items-center mb-2">
            <div className="col-md-12 col-lg-2">
              <div className="brand-logo">
                <img src={logo} alt="logo" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <p className="head-text">
                <IntlMessages id="footer.end.head" />
              </p>
            </div>
            <div className="col-md-12 col-lg-4 social-wrap">
              {social.map((item, key) => {
                return item.tooltip ? (
                  <div key={key}>
                    <button
                      className="btn-icon"
                      onClick={event =>
                        this.setState({
                          [[item.linkProps.anchor]]: event.currentTarget
                        })
                      }
                    >
                      {item.icon}
                    </button>
                    <Popover
                      open={Boolean(this.state[item.linkProps.anchor])}
                      anchorEl={this.state[item.linkProps.anchor]}
                      onClose={() =>
                        this.setState({ [[item.linkProps.anchor]]: null })
                      }
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                      }}
                    >
                      <p className="whatapp-title">{item.linkProps.title}</p>
                      <p className="whatapp-text">{item.linkProps.href}</p>
                    </Popover>
                  </div>
                ) : (
                  <a className="btn-icon" key={key} {...item.linkProps}>
                    {item.icon}
                  </a>
                );
              })}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <p className="end-text">
                <IntlMessages id="footer.end.end" /> {this.getCurrentYear()}
              </p>
            </div>
          </div>
          <div
            className={cx("message-box", this.state.showScroll ? "show" : null)}
          >
            <button
              className="csp-btn csp-main-blue"
              onClick={this.handleClick}
            >
              <span>
                <IntlMessages id="popup.phone" />
              </span>
              <span>
                - <IntlMessages id="popup.callback" />
              </span>
            </button>
            <Popover
              open={Boolean(this.state.anchorEl)}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
            >
              <div className="message-form">
                <h6 className="title">
                  <span>
                    <IntlMessages id="popup.phone" />{" "}
                  </span>
                  <span>
                    - <IntlMessages id="popup.callback" />
                  </span>
                </h6>
                <form className="wrap" onSubmit={this.onSubmit}>
                  <GridContainer alignItems="center">
                    <GridItem xs={12}>
                      <input
                        name="company"
                        value={company}
                        placeholder="Tên công ty"
                        onChange={this.handleChange}
                        autoComplete="off"
                        type="text"
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <input
                        name="name"
                        value={name}
                        placeholder="Tên khách hàng"
                        onChange={this.handleChange}
                        autoComplete="off"
                        type="text"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <input
                        className="mb-0"
                        type="tel"
                        name="phone"
                        value={phone}
                        placeholder="Số điện thoại"
                        onChange={this.handleChange}
                        autoComplete="off"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <button className="csp-btn csp-main-blue btn-leavephone">
                        <IntlMessages id="btn.send" />
                      </button>
                    </GridItem>
                  </GridContainer>
                </form>
              </div>
            </Popover>
            <div className="srcoll-top-btn" onClick={this.scrollTop}>
              <ExpandLess />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default function Footer() {
  return (
    <footer id="footer">
      {/* <FooterHead /> */}
      <FooterEnd />
    </footer>
  );
}
