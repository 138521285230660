import React, { Component } from "react";
import PURL from "apis/PublicURL";
import { connect } from "react-redux";
import wcuBalloon from "assets/img/csp/wcu-balloon.jpg";
import wcuLaptop from "assets/img/csp/wcu-laptop.jpg";
import wcuWork from "assets/img/csp/wcu-work.jpg";
import IconHeading from "assets/img/csp/ic-heading.png";
import IntlMessages from "util/IntlMessages";
import FadeInSection from "components/_App/FadeInSection";
// WHY CHOOSE US - HOME ID
const WCU_ID = "YHST7BOOmHNZFs6TMkMa";
const slide = [1, 2, 3];
class WhyChooseUs extends Component {
  state = {
    detail: "",
    block1Hover: true,
    block2Hover: false,
    block3Hover: false
  };

  componentDidMount() {
    this.subscribe = this.onLoad();
  }

  componentWillUnmount() {
    this.subscribe = null;
  }

  async onLoad() {
    const response = await PURL.get(`/company/contents/${WCU_ID}`);
    this.setState({ detail: response.data });
  }

  onRender = detail => {
    const { locale } = this.props;
    return slide.map(item => {
      return (
        <div
          key={item}
          className={`slide-box_item ${
            this.state[`block${item}Hover`] ? "active" : "unactive"
          } ${item === 2 ? "slide-trans" : null}`}
          style={{
            backgroundImage: `url(${this.getImage(item)})`
          }}
          onMouseEnter={() => this.onHover(item)}
        >
          <div className="slide-box_overlay" />
          <h3 className="slide-box_number">0{item}.</h3>
          <h3 className="slide-box_title">
            {detail[`title_0${item}_${locale}`]}
          </h3>
          <p className="slide-box_text">{detail[`desc_0${item}_${locale}`]}</p>
        </div>
      );
    });
  };

  onHover = key => {
    let number = parseInt(key);
    switch (number) {
      case 1:
        this.setState({
          block1Hover: true,
          block2Hover: false,
          block3Hover: false
        });
        break;
      case 2:
        this.setState({
          block1Hover: false,
          block2Hover: true,
          block3Hover: false
        });
        break;
      case 3:
        this.setState({
          block1Hover: false,
          block2Hover: false,
          block3Hover: true
        });
        break;
      default:
        break;
    }
  };

  getImage = key => {
    let number = parseInt(key);
    switch (number) {
      case 1:
        return wcuBalloon;
      case 2:
        return wcuLaptop;
      case 3:
        return wcuWork;
      default:
        break;
    }
  };

  render() {
    const { detail } = this.state;

    if (!detail) {
      return null;
    }

    return (
      <FadeInSection>
        <section className="why-choose-us section-box bg-white">
          <div className="csp-container">
            <div className="main-title-wrap">
              <h2 className="main-title">
                <img
                  src={IconHeading}
                  className="ic-heading"
                  alt="icon-heading"
                />
                <span>
                  <IntlMessages id="home.wcu.title" />
                </span>
              </h2>
            </div>
            <div className="slide-box">{this.onRender(detail)}</div>
          </div>
        </section>
      </FadeInSection>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  return {
    locale: settings.locale.locale
  };
};
export default connect(mapStateToProps)(WhyChooseUs);
