import React from "react";
import IntlMessages from "util/IntlMessages";
import hist from "history.js";

export default function index() {
  return (
    <div className="request-box">
      <div className="csp-container">
        <div className="get-demo-box module-box">
          <p>
            <IntlMessages id="home.demo" />
          </p>
          <button
            className="csp-btn csp-main-blue"
            onClick={() => hist.push("/contact")}
          >
            <IntlMessages id="btn.getdemo" />
          </button>
        </div>
      </div>
    </div>
  );
}
