import React, { useEffect, useState } from "react";
import PURL from "apis/PublicURL";
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Admin/Loader";
import FadeInSection from "components/_App/FadeInSection";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";

// SEO ID OUR HISTORY
const SEO_ID = "ueoKHlq7CK5SENSHGzIr";

export default function History() {
  const [historyList, setHistoryList] = useState([]);
  const [detail, setDetail] = useState("");
  const locale = useSelector(({ settings }) => settings.locale.locale);

  useEffect(() => {
    async function onFetch() {
      try {
        const response = await PURL.get("/company/history");
        setHistoryList(response.data);
        const res = await PURL.get(`company/seo/${SEO_ID}`);
        setDetail(res.data);
      } catch (error) {
        console.log("Failed to fetch list", error.message);
      }
    }
    onFetch();
    ReactGA.pageview(window.location.pathname);
  }, []);

  if (!historyList) {
    return (
      <div className="page-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="col-sm-12 col-lg-9 company-history">
      {detail && (
        <CustomHelmet
          detail={detail}
          imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/wwa-logo.jpeg?alt=media&token=09bf3a41-a108-4177-9978-8db442a8a338"
        />
      )}
      <FadeInSection>
        <GridContainer className="history-list">
          {historyList.map(history => (
            <GridItem xs={12} key={history.id}>
              <div className="timeline-item">
                <div className="heading">
                  <div className="year">{history.year}</div>
                  <div className="title">{history[locale].title}</div>
                </div>
                <div className="description">{history[locale].content}</div>
              </div>
            </GridItem>
          ))}
        </GridContainer>
      </FadeInSection>
    </div>
  );
}
