import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Card from "components/Card/Card";
import Loader from "components/Admin/Loader";
import CardBody from "components/Card/CardBody";
import BaseProduct from "components/Admin/Form/BaseForm/Product";
import history from "history.js";
import { addProduct } from "actions/admin/product";
import { TOAST_OPTIONS } from "helpers";

class CreateProduct extends Component {
  state = {
    loading: false
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onSubmit = async dataValue => {
    this.onLoader();
    try {
      await this.props.addProduct(dataValue);
      toast.success(`Sản phẩm đã được tạo thành công`, TOAST_OPTIONS);
      history.push("/dashboard/product");
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };
  render() {
    const { loading } = this.state;
    return (
      <Card>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <CardBody>
          <BaseProduct submitForm={this.onSubmit} />
        </CardBody>
      </Card>
    );
  }
}
export default connect(
  null,
  { addProduct }
)(CreateProduct);
