import React, { useState, useEffect } from "react";
import ADMIN_URL from "apis/AdminURL";
import SEOForm from "components/Admin/Form/BaseForm/SEO";
import Loader from "components/Admin/Loader";
import toast from "cogo-toast";
import { TOAST_OPTIONS } from "helpers";
import Heading from "components/Heading";

// OUR PARTNERS SEO
const SEO_ID = "NFn5wVNtprbOYaukEcwZ";

export default function SEOIndex() {
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState("");

  useEffect(() => {
    async function onFetch() {
      try {
        const res = await ADMIN_URL.get(`/seo/detail/${SEO_ID}`);
        setDetail(res.data);
        setLoading(false);
      } catch (error) {
        console.log("On fetch error", error.message);
      }
    }
    onFetch();
  }, []);

  const onSubmit = async dataValue => {
    setLoading(true);
    try {
      await ADMIN_URL.put(`/seo/detail/${SEO_ID}`, dataValue);
      const res = await ADMIN_URL.get(`/seo/detail/${SEO_ID}`);
      setDetail(res.data);
      toast.success("Thông tin SEO đã được cập nhật", TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    setLoading(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Heading title="NỘI DUNG SEO" />
      {loading ? (
        <div className="full-loading">
          <Loader />
        </div>
      ) : null}
      {detail && <SEOForm submitForm={onSubmit} initialValues={detail} />}
    </div>
  );
}
