import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { gContents } from "actions/landing";
import logo from "assets/img/csp/optitex-logo.svg";
import Loader from "components/Admin/Loader";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import FadeInSection from "components/_App/FadeInSection";
import IntlMessages from "util/IntlMessages";

const OptitexBannerId = "peigIZQXJOPuu136jq19";

class Banner extends PureComponent {
  state = {
    banner: ""
  };

  componentDidMount() {
    this.onCall = this.onLoad();
  }

  componentWillUnmount() {
    this.onCall = null;
  }

  async onLoad() {
    await this.props.gContents(OptitexBannerId);
    this.setState({ banner: this.props.banner });
  }

  render() {
    const { banner } = this.state;
    const { locale } = this.props;
    if (!banner) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      );
    }
    return (
      <FadeInSection>
        <section className="optitex-banner">
          <div className="optitex-banner-wrap">
            <div
              className="bg-section"
              style={{ backgroundImage: `url(${banner.url})` }}
            ></div>
            <div className="optitex-banner-detail">
              <div className="optitex-banner-content">
                <img src={logo} alt="logo-optitex" />
                <h4>{banner[`content_${locale}`]}</h4>
              </div>
            </div>
            <div className="nav-heading">
              <Breadcrumbs aria-label="breadcrumb" separator=".">
                <Link to="/" className="nav-heading-menu">
                  <IntlMessages id="home.menu.index" />
                </Link>
                <Link to="/about/partner" className="nav-heading-menu">
                  <IntlMessages id="home.menu.product" />
                </Link>
                <div className="nav-heading-breadcrumb">Optitex</div>
              </Breadcrumbs>
            </div>
          </div>
        </section>
      </FadeInSection>
    );
  }
}
const mapStateToProps = ({ settings, landing }) => {
  return {
    locale: settings.locale.locale,
    banner: landing[OptitexBannerId]
  };
};

export default connect(
  mapStateToProps,
  { gContents }
)(Banner);
