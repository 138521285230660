import React, { useEffect, useState } from "react";
import PURL from "apis/PublicURL";
import HeroBanner from "./component/Banner";
import RequestDemo from "components/_App/RequestDemo";
import WhatWeOffer from "./component/WhatWeOffer";
import WhoWeAre from "./component/WhoWeAre";
import WhyChooseUs from "./component/WhyChooseUs";
import Achievement from "./component/Achievement";
import Client from "./component/Client";
import Contact from "./component/Contact";
import CaseStudy from "./component/CaseStudy";
import GetDemo from "components/_App/GetDemo";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";

// SEO ID HOME
const SEO_ID = "Tn3MBrLOTSyhxiPjHgeR";

export default function HomePage() {
  const [detail, setDetail] = useState("");
  useEffect(() => {
    async function onFetch() {
      try {
        const res = await PURL.get(`company/seo/${SEO_ID}`);
        setDetail(res.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    onFetch();
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className="home-page">
      {detail && (
        <CustomHelmet
          detail={detail}
          imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/wwa-logo.jpeg?alt=media&token=09bf3a41-a108-4177-9978-8db442a8a338"
        />
      )}
      <HeroBanner />
      <RequestDemo />
      <WhatWeOffer />
      <WhoWeAre />
      <WhyChooseUs />
      <Achievement />
      <Client />
      <CaseStudy />
      <GetDemo />
      <Contact />
    </div>
  );
}
