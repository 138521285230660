import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import IntlMessages from "util/IntlMessages";
import Loader from "components/Admin/Loader";
import { getHeroBanner } from "actions/landing";
import IconHeading from "assets/img/csp/ic-heading.png";
import hist from "history.js";
import FadeInSection from "components/_App/FadeInSection";

import styled, { keyframes } from "styled-components";
import { fadeInLeft } from "react-animations";
const FadeInLeftAnimation = keyframes`${fadeInLeft}`;
const FadeInLeftDiv = styled.div`
  animation: 2.5s ${FadeInLeftAnimation};
`;

class HeroBanner extends Component {
  state = {
    banner: "",
    contentAni: "no-animated",
    btnContactAni: "no-animated",
    btnAboutAni: "no-animated"
  };

  componentDidMount() {
    this.subscribe = this.onLoad();
    this.contentAni = setTimeout(() => {
      this.setState({ contentAni: "fadeInUp" });
    }, 2000);
    this.btnAboutAni = setTimeout(() => {
      this.setState({ btnAboutAni: "fadeInUp" });
    }, 3500);
    this.btnContactAni = setTimeout(() => {
      this.setState({ btnContactAni: "fadeInUp" });
    }, 3000);
  }

  componentWillUnmount() {
    this.subscribe = null;
    clearTimeout(this.contentAni);
    clearTimeout(this.btnAboutAni);
    clearTimeout(this.btnContactAni);
    this.contentAni = null;
    this.btnAboutAni = null;
    this.btnContactAni = null;
  }

  async onLoad() {
    await this.props.getHeroBanner();
    this.setState({ banner: this.props.banner });
  }

  render() {
    const { banner, contentAni, btnAboutAni, btnContactAni } = this.state;
    const { locale } = this.props;
    if (!banner) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      );
    }
    return (
      <FadeInSection>
        <section className="hero-banner">
          <div
            style={{ backgroundImage: `url(${banner.background_url})` }}
            className="bg-section"
          >
            <div className="csp-container">
              <div className="hero-banner-wrap">
                <div className="hero-banner-wrap-inner">
                  <div className="hero-banner-img zoomOut">
                    <img
                      src={banner.url}
                      className="img-fluid"
                      alt="hero-banner"
                    />
                  </div>
                  <div className="hero-banner-detail">
                    <FadeInLeftDiv>
                      <h1 className={cx(`hero-banner-title`)}>
                        <img
                          src={IconHeading}
                          className="ic-heading"
                          alt="icon-heading"
                        />
                        <span>{banner[`title_${locale}`]}</span>
                      </h1>
                    </FadeInLeftDiv>
                    <div className={cx(`hero-banner-content ${contentAni}`)}>
                      {banner[`content_${locale}`]}
                    </div>
                    <div className="btn-wrap">
                      <button
                        className={cx(`csp-btn csp-main-blue ${btnContactAni}`)}
                        onClick={() => hist.push("/contact")}
                      >
                        <IntlMessages id="home.banner.contact" />
                      </button>
                      <button
                        className={cx(`csp-btn csp-dark-blue ${btnAboutAni}`)}
                        onClick={() => hist.push("/about/company-profile")}
                      >
                        <IntlMessages id="home.banner.about" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </FadeInSection>
    );
  }
}
const mapStateToProps = ({ landing, settings }) => {
  return {
    banner: landing.banner,
    locale: settings.locale.locale
  };
};
export default connect(
  mapStateToProps,
  {
    getHeroBanner
  }
)(HeroBanner);
