import React, { Component } from "react";
// COMPONENT
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import List from "./List";
import Upload from "./Upload";
import Loader from "components/Admin/Loader";
class Gallery extends Component {
  state = {
    isUploading: false,
    progress: 0
  };

  handleUploading = (isUploading, progress) => {
    this.setState({ isUploading, progress });
  };

  handleProgress = progress => {
    this.setState({ progress });
  };

  render() {
    const { isUploading, progress } = this.state;

    return (
      <div className="dashboard-gallery position-relative">
        {isUploading && (
          <div className="full-loading">
            <Loader>Ảnh được tải lên {progress} %</Loader>
          </div>
        )}
        <Card className="position-relative">
          <CardBody>
            <Upload
              handleUploading={this.handleUploading}
              handleProgress={this.handleProgress}
            />
            <List />
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default Gallery;
