import React, { Component } from "react";
// Material
import { Add } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
// Component
import Heading from "components/Heading";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons";
import ListPost from "./component/ListPost";
import SEOIndex from "./component/SEOIndex";
import history from "history.js";

class Blog extends Component {
  onCreate() {
    history.push("/dashboard/blog/create");
  }
  render() {
    return (
      <Card>
        <CardBody>
          <div className="mb-4">
            <SEOIndex />
          </div>
          <div className="position-relative">
            <Heading title="DANH SÁCH BÀI VIẾT" />
            <Tooltip title="Thêm bài viết">
              <Button
                color="success"
                className="btn-float"
                onClick={this.onCreate}
              >
                <Add />
              </Button>
            </Tooltip>
          </div>
          <ListPost />
        </CardBody>
      </Card>
    );
  }
}

export default Blog;
