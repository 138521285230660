import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import toast from "cogo-toast";
// MATERIAL
import { Add, Close, Save } from "@material-ui/icons";
// COMPONENT
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons";
import { TOAST_OPTIONS } from "helpers";

class HistoryForm extends Component {
  state = {
    disabled: true
  };
  renderField = ({ input, label, inputProps }) => {
    return (
      <Input
        labelText={label}
        formControlProps={{ fullWidth: true }}
        inputProps={{
          ...inputProps,
          ...input
        }}
      />
    );
  };
  onSubmit = dataValue => {
    if (!dataValue.year || dataValue.year === "") {
      toast.warn(`Cần điền thông tin mốc thời gian`, TOAST_OPTIONS);
      return null;
    }
    this.props.onSubmit(dataValue);
  };

  render() {
    const { pristine, initialValues } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <Field
          name="year"
          label="Thời điểm (ex: 2000, 2001, 2003)"
          component={this.renderField}
        />
        <Field name="title" label="Tiêu đề" component={this.renderField} />
        <Field
          name="content"
          label="Nội dung"
          component={this.renderField}
          inputProps={{ multiline: true }}
        />
        <div className="modal-form-footer">
          {initialValues ? (
            <Button simple color="success" type="submit" disabled={pristine}>
              <Save />
              <span className="btn-text">Lưu</span>
            </Button>
          ) : (
            <Button simple color="success" type="submit" disabled={pristine}>
              <Add />
              <span className="btn-text">Thêm</span>
            </Button>
          )}
          <Button
            simple
            color="primary"
            onClick={() => this.props.handleClose()}
          >
            <Close />
            <span className="btn-text">Đóng</span>
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: "HistoryForm"
})(HistoryForm);
