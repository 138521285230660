import React from "react";
import { useSelector } from "react-redux";
import { GoogleMap, Marker, InfoWindow } from "react-google-maps";
import IntlMessages from "util/IntlMessages";

export default function MapLayout(props) {
  const locale = useSelector(({ settings }) => settings.locale.locale);
  const {
    defaultLocation,
    isMarkerShown,
    selectedMarker,
    markers,
    zoom
  } = props;

  const checkDashboard = () => {
    if (window.location.pathname.indexOf("/dashboard/info") !== -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <GoogleMap
      zoom={zoom}
      defaultCenter={{ lat: defaultLocation.lat, lng: defaultLocation.lng }}
    >
      {isMarkerShown &&
        markers &&
        markers.map((marker, key) => {
          const onClick = props.onClick.bind(this, marker);
          return checkDashboard() ? (
            <Marker
              position={{ lat: marker.latLng.lat, lng: marker.latLng.lng }}
              key={key}
              onClick={onClick}
            >
              {selectedMarker === marker && (
                <InfoWindow>
                  <div className="gps-board">
                    <div>Tòa nhà: {marker.building_vi}</div>
                    <div>Điện thoại: {marker.tel}</div>
                    <div>Địa chỉ: {marker[`address_vi`]}</div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ) : (
            <Marker
              position={{ lat: marker.latLng.lat, lng: marker.latLng.lng }}
              key={key}
              onClick={onClick}
            >
              {selectedMarker === marker && (
                <InfoWindow>
                  <div className="gps-board">
                    <div>
                      <IntlMessages id="contact.map.building" />{" "}
                      {marker[`building_${locale}`]}
                    </div>
                    <div>
                      <IntlMessages id="contact.map.phone" /> {marker.tel}
                    </div>
                    <div>
                      <IntlMessages id="contact.map.address" />{" "}
                      {marker[`address_${locale}`]}
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
    </GoogleMap>
  );
}
