// -------- Auth ---------
import Login from "pages/Login";

// ----- Landing Page -------
import Home from "pages/Landing/Home";
// ==== ABOUT LAYOUT ====
import Profile from "pages/Landing/About/Profile";
import History from "pages/Landing/About/History";
import AboutPartner from "pages/Landing/About/Partner";
import AboutTeam from "pages/Landing/About/Team";
// ==== PRODUCT ====
import MainOptitex from "pages/Landing/Product/Optitex";
import DetailOptitex from "pages/Landing/Product/Optitex/Detail";
import OthersProduct from "pages/Landing/Product/Others";

//------ Dashboard ------
// ======= Internal Company
import Info from "pages/Admin/Company/Info";
import HR from "pages/Admin/Company/HR";
import Content from "pages/Admin/Website/Content";
// End Admin
import Gallery from "pages/Admin/Website/Gallery";
// --- Partner ---
import Partner from "pages/Admin/Website/Partner";
import CreatePartner from "pages/Admin/Website/Partner/component/CreatePartner";
import DetailPartner from "pages/Admin/Website/Partner/component/DetailPartner";
// --- Client ---
import Client from "pages/Admin/Website/Client";
import CreateClient from "pages/Admin/Website/Client/component/CreateClient";
import DetailClient from "pages/Admin/Website/Client/component/DetailClient";
// --- Blog ---
import Blog from "pages/Admin/Blog";
import CreatePost from "pages/Admin/Blog/component/CreatePost";
import DetailPost from "pages/Admin/Blog/component/DetailPost";
// --- Product ---
import Product from "pages/Admin/Website/Product";
import CreateProduct from "pages/Admin/Website/Product/component/CreateProduct";
import DetailProduct from "pages/Admin/Website/Product/component/DetailProduct";

const dashRoutes = [
  {
    collapse: true,
    layout: "/dashboard",
    name: `Quản lý`,
    state: "adminState",
    views: [
      {
        layout: "/dashboard",
        component: Info,
        path: "/info",
        name: "Thông tin"
      },
      {
        layout: "/dashboard",
        component: HR,
        path: "/hr",
        name: "Nhân sự"
      }
    ]
  },
  {
    collapse: true,
    layout: "/dashboard",
    name: `Website`,
    state: "webState",
    views: [
      {
        layout: "/dashboard",
        component: Content,
        path: "/content",
        name: "Nội dung"
      },
      {
        layout: "/dashboard",
        component: Gallery,
        path: "/gallery",
        name: "Thư viện"
      },
      {
        layout: "/dashboard",
        component: Client,
        path: "/client",
        name: "Khách hàng"
      },
      {
        layout: "/dashboard",
        component: Partner,
        path: "/partner",
        name: "Đối tác"
      },
      {
        layout: "/dashboard",
        component: Product,
        path: "/product",
        name: "Sản phẩm"
      }
    ]
  },
  {
    collapse: true,
    layout: "/dashboard",
    name: `News & Event`,
    state: "newState",
    views: [
      {
        layout: "/dashboard",
        component: Blog,
        path: "/blog",
        name: "Bài Viết"
      }
    ]
  },
  {
    layout: "/auth",
    component: Login,
    path: "/login",
    name: "ĐĂNG NHẬP"
  },
  {
    layout: "/home",
    component: Home,
    path: "/",
    name: "Home"
  },
  {
    layout: "/about",
    component: Profile,
    path: "/company-profile",
    name: "Profile"
  },
  {
    layout: "/about",
    component: History,
    path: "/history",
    name: "Profile"
  },
  {
    layout: "/about",
    component: AboutPartner,
    path: "/partner",
    name: "AboutPartner"
  },
  {
    layout: "/about",
    component: AboutTeam,
    path: "/team",
    name: "AboutTeam"
  },
  {
    layout: "/product",
    component: DetailOptitex,
    path: "/optitex/:id/:slug",
    name: "DetailOptitex"
  },
  {
    layout: "/product",
    component: MainOptitex,
    path: "/optitex",
    name: "MainOptitex"
  },
  {
    layout: "/product",
    component: OthersProduct,
    path: "/:slug/:id",
    name: "OthersProduct"
  },
  {
    collapse: true,
    hide: true,
    name: `Routes Hidden`,
    state: "hiddenState",
    views: [
      {
        layout: "/dashboard",
        component: CreatePost,
        path: "/blog/create",
        name: "Create Post"
      },
      {
        layout: "/dashboard",
        component: DetailPost,
        path: "/blog/:id/:lang",
        name: "Detail Post"
      },
      {
        layout: "/dashboard",
        component: CreateProduct,
        path: "/product/create",
        name: "Create Product"
      },
      {
        layout: "/dashboard",
        component: DetailProduct,
        path: "/product/:id/:lang",
        name: "Detail Product"
      },
      {
        layout: "/dashboard",
        component: CreateClient,
        path: "/client/create",
        name: "Create Client"
      },
      {
        layout: "/dashboard",
        component: DetailClient,
        path: "/client/:id/:lang",
        name: "Detail Client"
      },
      {
        layout: "/dashboard",
        component: CreatePartner,
        path: "/partner/create",
        name: "Create Partner"
      },
      {
        layout: "/dashboard",
        component: DetailPartner,
        path: "/partner/:id/:lang",
        name: "Detail Partner"
      }
    ]
  }
];

export default dashRoutes;
