/* eslint-disable react/prop-types */
import React from "react";
import { InputAdornment, Icon } from "@material-ui/core";
export default function Adornment({ classes, children, ...props }) {
  return (
    <InputAdornment position="end" {...props} className="item-adornment">
      <Icon className={classes && classes}>{children}</Icon>
    </InputAdornment>
  );
}
