import React, { Component } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTable from "react-table";
import toast from "cogo-toast";
// MATERIAL
import { Delete, Link, Visibility } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
// COMPONENT
import Button from "components/CustomButtons";
import Loader from "components/Admin/Loader";
import { TABLE_CONFIG, TOAST_OPTIONS } from "helpers";
import { getBrochures, deleteBrochure } from "actions/admin/company";

class ListImage extends Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    await this.props.getBrochures();
    this.setState({ loading: false });
  }

  renderList = () => {
    const { list } = this.props;
    if (list) {
      return list.map(prop => {
        return {
          name: (
            <Button className="btn-title" simple color="primary">
              {prop.name}
            </Button>
          ),
          actions: (
            <div className="actions-right">
              <Tooltip title="Xem">
                <Button
                  simple
                  color="primary"
                  href={prop.src}
                  target="_blank"
                  justIcon
                >
                  <Visibility />
                </Button>
              </Tooltip>
              <Tooltip title="Sao chép đường dẫn">
                <CopyToClipboard text={prop.src}>
                  <Button
                    color="success"
                    justIcon
                    simple
                    onClick={() => this.showMessage(prop.name)}
                  >
                    <Link />
                  </Button>
                </CopyToClipboard>
              </Tooltip>
              <Tooltip title="Xóa">
                <Button
                  color="danger"
                  onClick={() => this.handleDelete(prop)}
                  justIcon
                  simple
                >
                  <Delete />
                </Button>
              </Tooltip>
            </div>
          )
        };
      });
    }
  };

  showMessage = imgName => {
    toast.success(
      <div className="message-text">
        <span>Đã sao chép thành công đường dẫn brochure: </span>
        <p>{imgName}</p>
      </div>,
      TOAST_OPTIONS
    );
  };

  handleDelete = imgProps => {
    const { id, src, name } = imgProps;
    this.props
      .deleteBrochure(id, src)
      .then(() => {
        this.props.getBrochures();
        toast.success(
          <div className="message-text">
            <span>Đã xóa tệp {name}</span>
          </div>,
          TOAST_OPTIONS
        );
      })
      .catch(err => {
        toast.error(err, TOAST_OPTIONS);
      });
  };

  render() {
    const columns = [
      { Header: "Tên brochure", accessor: "name", minWidth: 250 },
      { Header: "Thao tác", accessor: "actions", width: 180 }
    ];
    const { loading } = this.state;
    return (
      <ReactTable
        {...TABLE_CONFIG}
        data={this.renderList()}
        columns={columns}
        loading={loading}
        loadingText={<Loader />}
      />
    );
  }
}
const mapStateToProps = ({ company }) => {
  return {
    list: company.brochure
  };
};
export default connect(
  mapStateToProps,
  {
    getBrochures,
    deleteBrochure
  }
)(ListImage);
