import React, { Component, Fragment } from "react";
import PURL from "apis/PublicURL";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import Loader from "components/Admin/Loader";
import { getNews, getEvents } from "actions/landing";
import InNeedBar from "components/_App/InNeedBar";
import ListItem from "./ListItem";
import IconHeading from "assets/img/csp/ic-heading.png";
import ReactPaginate from "react-paginate";
import Prev from "@material-ui/icons/ArrowBackIos";
import Next from "@material-ui/icons/ArrowForwardIos";
import Dots from "@material-ui/icons/MoreHoriz";
import { paginate } from "helpers";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";

// SEO NEW EVENTS
const SEO_ID = "c25Y30EmsWm4MeTzXJmw";

class NewsAndEvents extends Component {
  state = {
    detail: "",
    news: "",
    newsSize: 3,
    newsCount: 1,
    newsPage: 1,
    events: "",
    eventsSize: 3,
    eventsCount: 1,
    eventsPage: 1
  };

  componentDidMount() {
    this.onLoadHead();
    this.onLoadNews();
    this.onLoadEvents();
    ReactGA.pageview(window.location.pathname);
  }

  async onLoadHead() {
    const res = await PURL.get(`company/seo/${SEO_ID}`);
    this.setState({ detail: res.data });
  }

  async onLoadNews() {
    const { newsPage, newsSize } = this.state;
    await this.props.getNews();
    const length = this.props.news.length;
    const list = await paginate(this.props.news, newsSize, newsPage);
    this.setState({
      news: list,
      newsCount: Math.ceil(length / newsSize)
    });
  }

  async onLoadEvents() {
    const { eventsPage, eventsSize } = this.state;
    await this.props.getEvents();
    const length = this.props.events.length;
    const list = await paginate(this.props.events, eventsSize, eventsPage);
    this.setState({
      events: list,
      eventsCount: Math.ceil(length / eventsSize)
    });
  }

  onRenderList = list => {
    const { locale } = this.props;
    return list.map((prop, key) => {
      return (
        <ListItem
          key={key}
          id={prop.id}
          thumbnail={prop.thumbnail}
          title={prop[locale].title}
          desc={prop[locale].desc}
          imageList={prop.images}
        />
      );
    });
  };

  onEventsPage = data => {
    const { selected } = data;
    const eventsPage = selected + 1;
    this.setState({ eventsPage }, () => this.onLoadEvents());
  };

  onNewsPage = data => {
    const { selected } = data;
    const newsPage = selected + 1;
    this.setState({ newsPage }, () => this.onLoadNews());
  };

  render() {
    const { events, eventsCount, news, newsCount, detail } = this.state;
    if (!events || !news) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      );
    }

    return (
      <Fragment>
        <div className="news-events bg-white section-box">
          {detail && (
            <CustomHelmet
              detail={detail}
              imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/wwa-logo.jpeg?alt=media&token=09bf3a41-a108-4177-9978-8db442a8a338"
            />
          )}
          <div className="csp-container">
            <div className="main-title-wrap">
              <h2 className="main-title">
                <img
                  src={IconHeading}
                  className="ic-heading"
                  alt="icon-heading"
                />
                <span>
                  <IntlMessages id="news.title" />
                </span>
              </h2>
            </div>
            <GridContainer justify="center" className="post-list">
              {this.onRenderList(news)}
              <ReactPaginate
                previousLabel={<Prev />}
                nextLabel={<Next />}
                breakLabel={<Dots />}
                breakClassName={"break-me"}
                pageCount={newsCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.onNewsPage}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </GridContainer>
            <div className="main-title-wrap">
              <h2 className="main-title">
                <img
                  src={IconHeading}
                  className="ic-heading"
                  alt="icon-heading"
                />
                <span>
                  <IntlMessages id="events.title" />
                </span>
              </h2>
            </div>
            <GridContainer justify="center" className="post-list">
              {this.onRenderList(events)}
              <ReactPaginate
                previousLabel={<Prev />}
                nextLabel={<Next />}
                breakLabel={<Dots />}
                breakClassName={"break-me"}
                pageCount={eventsCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.onEventsPage}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </GridContainer>
          </div>
        </div>
        <InNeedBar id="need.support.text" className="dark" />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ landing, settings }) => {
  return {
    news: landing.news,
    events: landing.events,
    locale: settings.locale.locale
  };
};

export default connect(
  mapStateToProps,
  { getNews, getEvents }
)(NewsAndEvents);
