import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Loader from "components/Admin/Loader";
import BaseProduct from "components/Admin/Form/BaseForm/Product";
import { getProductDetail, updateProduct } from "actions/admin/product";
import history from "history.js";
import { TOAST_OPTIONS } from "helpers";
import Heading from "components/Heading";

class DetailProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: "",
      id: props.match.params.id,
      lang: props.match.params.lang,
      loading: false
    };
  }

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  onLoad = async () => {
    const { id, lang } = this.state;
    await this.props.getProductDetail(id, lang);
    this.setState({ detail: this.props.detail });
  };

  onSubmit = async dataValue => {
    const { id, lang } = this.state;
    this.onLoader();
    try {
      await this.props.updateProduct(id, lang, dataValue);
      toast.success(`Sản phẩm đã được cập nhật`, TOAST_OPTIONS);
      history.push("/dashboard/product");
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };
  render() {
    const { detail, loading, lang } = this.state;
    return (
      <Card>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        {detail ? (
          <CardBody>
            <Heading
              title={`SẢN PHẨM - ${lang === "vi" ? "TIẾNG VIỆT" : "TIẾNG ANH"}`}
            />
            <BaseProduct submitForm={this.onSubmit} initialValues={detail} />
          </CardBody>
        ) : (
          <CardBody className="p-5">
            <div className="full-loading">
              <Loader />
            </div>
          </CardBody>
        )}
      </Card>
    );
  }
}
const mapStateToProps = ({ product }, ownProps) => {
  return {
    detail: product[ownProps.match.params.id]
  };
};
export default connect(
  mapStateToProps,
  {
    getProductDetail,
    updateProduct
  }
)(DetailProduct);
