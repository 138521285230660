import React from "react";
import { List } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ListClient from "./component/ListClient";
import Optitex from "./Optitex";

import NavPills from "components/NavPills/NavPills";
import Accordion from "components/Accordion/Accordion";

export default function Client() {
  return (
    <NavPills
      color="primary"
      tabs={[
        {
          tabButton: "KHÁCH HÀNG",
          tabIcon: List,
          tabContent: (
            <Card>
              <CardBody>
                <Accordion
                  collapses={[
                    {
                      title: "OPTITEX",
                      content: <Optitex />
                    },
                    {
                      title: "KHÁCH HÀNG KHÁC",
                      content: <ListClient />
                    }
                  ]}
                />
              </CardBody>
            </Card>
          )
        }
      ]}
    />
  );
}