import React, { Component } from "react";
import { connect } from "react-redux";
// COMPONENT
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Heading from "components/Heading";
import { getContact } from "actions/admin/company";

import Map from "./Map";
import Form from "./Form";

class ContactCompany extends Component {
  componentDidMount() {
    this.props.getContact();
  }

  renderPlaces = places => {
    if (places)
      return places.map((place, key) => {
        return (
          <GridItem xs={12} sm={6} key={key}>
            <Heading title={place.city} />
            <Form place={place} />
          </GridItem>
        );
      });
  };

  render() {
    const { contact } = this.props;
    return (
      <Card>
        <CardBody>
          <GridContainer>
            {this.renderPlaces(contact)}
            <GridItem xs={12} className="mb-3">
              <Heading title="Bản đồ" />
              <Card>
                <Map places={contact} />
              </Card>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = ({ company }) => {
  return {
    contact: company.contact
  };
};
export default connect(
  mapStateToProps,
  {
    getContact
  }
)(ContactCompany);
