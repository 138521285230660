import {VIDEO} from "actions/constant";

const initialState = {
    list: ""
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case VIDEO.ADD_VIDEO:
            return {...state}
        
        case VIDEO.GET_VIDEO:
            return { ...state, list: payload};

        case VIDEO.UPDATE_VIDEO:
            return {...state}

        case VIDEO.DELETE_VIDEO:
            return {...state}

        case VIDEO.GET_VIDEO_DETAIL:
            return {...state, [payload.id]: payload}

        default:
            return state;
    }
};