import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "cogo-toast";
import { TOAST_OPTIONS } from "helpers";
import { getContent, updateContent } from "actions/admin/company";
import CardBody from "components/Card/CardBody";
import LineScaleLoader from "components/Admin/Loader";
import ContentForm from "components/Admin/Form/BaseForm/Content";
import Heading from "components/Heading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// WHY CHOOSE US - HOME ID
const WCU_ID = "YHST7BOOmHNZFs6TMkMa";

const slide = ["01", "02", "03"];

export default function Wcu() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const detail = useSelector(({ company }) => company[WCU_ID]);

  useEffect(() => {
    async function onFetch() {
      dispatch(getContent(WCU_ID));
    }
    onFetch();
  }, [dispatch]);

  const onSubmit = async value => {
    setLoading(true);
    try {
      await dispatch(updateContent(WCU_ID, value));
      await dispatch(getContent(WCU_ID));
      toast.success(`Nội dung đã được cập nhật`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    setLoading(false);
  };

  if (!detail) {
    return (
      <CardBody className="p-5">
        <div className="full-loading">
          <LineScaleLoader />
        </div>
      </CardBody>
    );
  }

  return (
    <div id={WCU_ID} style={{ width: "100%" }}>
      {loading ? (
        <div className="full-loading">
          <LineScaleLoader />
        </div>
      ) : null}
      {slide.map(item => (
        <div key={parseInt(item)}>
          <Heading title={`Hình ${item}`} />
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <ContentForm
                label="TIÊU ĐỀ (EN)"
                nameInput={`title_${item}_en`}
                initialValues={detail}
                onSubmit={onSubmit}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <ContentForm
                label="TIÊU ĐỀ (VI)"
                nameInput={`title_${item}_vi`}
                initialValues={detail}
                onSubmit={onSubmit}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <ContentForm
                label="NỘI DUNG (EN)"
                nameInput={`desc_${item}_en`}
                initialValues={detail}
                onSubmit={onSubmit}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <ContentForm
                label="NỘI DUNG (VI)"
                nameInput={`desc_${item}_vi`}
                initialValues={detail}
                onSubmit={onSubmit}
              />
            </GridItem>
          </GridContainer>
        </div>
      ))}
    </div>
  );
}
