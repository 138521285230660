import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

// material-ui components
import { makeStyles, Tab, Tabs } from "@material-ui/core";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/m-dashboard/components/navPillsStyle.js";
import BullmerBG from "assets/img/csp/product-bullmer-only.png";
import IntlMessages from "util/IntlMessages";
import hist from "history.js";

const useStyles = makeStyles(styles);

function ContentDetail(props) {
  const { index, value, content } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={classes.tabContent}
    >
      {value === index && content}
    </div>
  );
}

export default function NavPills(props) {
  const [active, setActive] = React.useState(props.active);
  const handleChange = (event, active) => {
    setActive(active);
  };
  const handleChangeIndex = index => {
    setActive(index);
  };
  const classes = useStyles();
  const { tabs, direction, id, horizontal } = props;
  const flexContainerClasses = classNames({
    [classes.cspAlignCenter]: true
  });
  const tabButtons = (
    <div
      className={classNames(
        `${tabs.length > 1 ? "menu-tab-product" : "tab-line"}`
      )}
    >
      <div className="csp-container">
        <Tabs
          classes={{
            fixed: classes.fixed,
            flexContainer: flexContainerClasses,
            indicator: classes.displayNone
          }}
          className="new-tabs"
          value={active}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          TabScrollButtonProps={{ className: "custom-scroll" }}
        >
          {tabs.length > 1 &&
            tabs.map((prop, key) => {
              return (
                <Tab
                  label={prop.tabButton}
                  key={key}
                  classes={{ selected: "selected" }}
                  className="btn-tab-menu"
                />
              );
            })}
        </Tabs>
      </div>
    </div>
  );
  const tabContent = (
    <div className="csp-container content-tab-product">
      <div className={classes.contentWrapper}>
        <SwipeableViews
          axis={direction === "rtl" ? "x-reverse" : "x"}
          index={active}
          onChangeIndex={handleChangeIndex}
          style={{ overflowY: "hidden" }}
        >
          {tabs.map((prop, key) => {
            return (
              <ContentDetail
                value={active}
                index={key}
                key={key}
                content={prop.tabContent}
              />
            );
          })}
        </SwipeableViews>
      </div>
    </div>
  );
  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </GridContainer>
  ) : (
    <div className="tab-panel-section">
      {tabButtons}
      {id === "R9TqcnxPl3qUC3u6Tn0i" && (
        <div className="bullmer-banner">
          <div className="bullmer-banner-detail">
            <h5 className="title">
              <span>
                <IntlMessages id="bullmer.banner.01" />{" "}
              </span>
              <span>
                <IntlMessages id="bullmer.banner.02" />
              </span>
            </h5>
            <p className="text">
              <IntlMessages id="bullmer.banner.text" />
            </p>
          </div>
          <img src={BullmerBG} alt={id} className="img-fluid" />
          <div className="btn-wrap">
            <button
              className="csp-btn csp-main-blue"
              onClick={() => hist.push("/contact")}
            >
              <IntlMessages id="home.banner.contact" />
            </button>
          </div>
        </div>
      )}
      {tabContent}
    </div>
  );
}

NavPills.defaultProps = {
  active: 0,
  color: "primary"
};

NavPills.propTypes = {
  // index of the default active pill
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsGrid: PropTypes.object,
    contentGrid: PropTypes.object
  })
};
