import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
// MATERIAL
import {
  withStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import selectStyles from "assets/jss/m-dashboard/customSelectStyle";
// COMPONENT
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import Editor from "components/Admin/Form/Wyswyg";
import defaultImage from "assets/img/image_placeholder.jpg";
import { getCateOP } from "actions/admin/product";

class ProductFormOP extends Component {
  state = {
    category: "",
    name: "",
    thumbnail: "",
    content: ""
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.getCateOP();
    if (this.props.initialValues) {
      this.onLoadField(this.props.initialValues);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //   ON LOAD FIELD
  onLoadField = prop => {
    if (this._isMounted) {
      this.setState({
        name: prop.name,
        category: prop.category,
        thumbnail: prop.thumbnail,
        content: prop.content
      });
    }
  };

  //   RENDER COMPONENT
  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };

  renderEditor = ({ dataValue, placeholder }) => {
    return (
      <FormControl fullWidth>
        <Editor
          content={dataValue}
          placeholder={placeholder}
          onChange={editorState => this.props.change("content", editorState)}
        />
      </FormControl>
    );
  };

  renderSelect = ({ input, label, children, dataValue }) => {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel className={classes.selectLabel}>{label}</InputLabel>
        <Select
          inputProps={{ name: input.name, value: dataValue }}
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          onChange={this.handleChange}
        >
          {children}
        </Select>
      </FormControl>
    );
  };

  renderChoices = () => {
    const { classes, cates } = this.props;
    if (cates) {
      return cates.map((prop, key) => (
        <MenuItem
          key={key}
          value={prop.id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
        >
          {prop.name.toUpperCase()}
        </MenuItem>
      ));
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  onSubmit = dataValue => this.props.submitForm(dataValue);
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  render() {
    const { name, category, content, thumbnail } = this.state;
    const { initialValues, handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <img
              src={thumbnail || defaultImage}
              className="img-fluid"
              alt="thumbnail"
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <Field
              component={this.renderField}
              label="TÊN SẢN PHẨM"
              name="name"
              dataValue={name}
              required
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Field
              component={this.renderSelect}
              label="Phân loại"
              name="category"
              dataValue={category}
            >
              {this.renderChoices()}
            </Field>
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              name="thumbnail"
              label="ẢNH ĐẠI DIỆN (LINK)"
              dataValue={thumbnail}
              multiline
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderEditor}
              placeholder="THÔNG TIN"
              name="content"
              dataValue={content}
            />
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap">
          <Button
            simple
            color="danger"
            onClick={() => this.props.handleClose()}
          >
            <Close /> Đóng
          </Button>
          {initialValues ? (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Add /> lưu
            </Button>
          ) : (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Add /> tạo
            </Button>
          )}
        </div>
      </form>
    );
  }
}
const mapStateToProps = ({ product }) => {
  return {
    cates: product.cate_list
  };
};
const styled = withStyles(selectStyles)(ProductFormOP);
const formBlock = reduxForm({ form: "ProductFormOP" })(styled);
export default connect(
  mapStateToProps,
  {
    getCateOP
  }
)(formBlock);
