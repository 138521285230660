import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import history from "history.js";
import reducers from "./reducers";
import Root from "./Root";
// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import "assets/scss/main.scss";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers(history),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
);

const rootElement = document.getElementById("sd-csp");

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <Root />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <Root />
    </Provider>,
    rootElement
  );
}
