import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import dockStyles from "assets/jss/components/dockStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={1200} />;
});

export default function FullScreenDialog(props) {
  const classes = dockStyles();
  const handleClose = () => props.handleClose();
  const { open, children, title, fullScreen, maxWidth } = props;
  return (
    <Dialog
      keepMounted
      open={open}
      fullScreen={fullScreen ? true : false}
      fullWidth
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={maxWidth}
      className={classes.root}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.closeBtn}
            onClick={handleClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{title}</DialogTitle>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
