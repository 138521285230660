import React, { useEffect, useState } from "react";
import PURL from "apis/PublicURL";
import _ from "lodash";
import { Hidden } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import IconHeading from "assets/img/csp/ic-heading.png";
import IntlMessages from "util/IntlMessages";
import FadeInSection from "components/_App/FadeInSection";
import Slider from "react-slick";
import Button from "components/CustomButtons";

const onRenderChunk = list => {
  return list.map((chunk, key) => {
    return (
      <GridContainer key={key} className="client-row">
        {onRender(chunk)}
      </GridContainer>
    );
  });
};

const onRender = chunk => {
  return chunk.map((item, key) => {
    return (
      <GridItem xs={4} className="client-item" key={key}>
        <img src={item.url} alt={key} className="client-logo" />
      </GridItem>
    );
  });
};

export default function Client() {
  const [listDesk, setListDesk] = useState("");
  const [listMob, setListMob] = useState("");

  useEffect(() => {
    async function onFetch() {
      const response = await PURL.get(`/company/clients`);
      const sorted = _.orderBy(response.data, ["created_on"]);
      const listDesktop = _.chunk(sorted, 6);
      setListDesk(listDesktop);
      setListMob(sorted);
    }
    onFetch();
  }, []);

  const settings = {
    infinite: false,
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    speed: 700,
    arrows: true,
    infinite: false,
    nextArrow: (
      <Button justIcon>
        <i className="fas fa-angle-right"></i>
      </Button>
    ),
    prevArrow: (
      <Button justIcon>
        <i className="fas fa-angle-left"></i>
      </Button>
    )
  };

  return (
    <FadeInSection>
      <section className="client-section bg-white section-box">
        <div className="csp-container">
          <div className="main-title-wrap">
            <h2 className="main-title">
              <img
                src={IconHeading}
                className="ic-heading"
                alt="icon-heading"
              />
              <span>
                <IntlMessages id="home.client.title" />
              </span>
            </h2>
          </div>
          <Hidden xsDown implementation="css">
            {listDesk && (
              <Slider {...settings} className="clients-slick">
                {onRenderChunk(listDesk)}
              </Slider>
            )}
          </Hidden>
          <Hidden smUp implementation="css">
            {listMob && (
              <Slider {...settings2} className="clients-slick">
                {listMob.map((item, key) => (
                  <div key={key} className="logo-item">
                    <img src={item.url} alt={item.id} className="client-logo" />
                  </div>
                ))}
              </Slider>
            )}
          </Hidden>
        </div>
      </section>
    </FadeInSection>
  );
}
