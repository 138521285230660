import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Loader from "components/Admin/Loader";
import BasePost from "components/Admin/Form/BaseForm/Post";
import Heading from "components/Heading";
import history from "history.js";
import { TOAST_OPTIONS } from "helpers";
import { getPostDetail, updatePost } from "actions/admin/post";

class DetailPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: "",
      id: props.match.params.id,
      lang: props.match.params.lang,
      loading: false
    };
  }

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    const { id, lang } = this.state;
    await this.props.getPostDetail(id, lang);
    this.setState({ detail: this.props.detail });
  };

  onSubmit = async dataValue => {
    const { id, lang } = this.state;
    this.onLoader();
    try {
      await this.props.updatePost(id, lang, dataValue);
      toast.success(`Bài viết đã được cập nhật`, TOAST_OPTIONS);
      history.push("/dashboard/blog");
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  render() {
    const { detail, loading, lang } = this.state;

    return (
      <Card>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        {detail ? (
          <CardBody>
            <Heading
              title={`THÔNG TIN ${lang === "vi" ? "TIẾNG VIỆT" : "TIẾNG ANH"}`}
            />
            <BasePost submitForm={this.onSubmit} initialValues={detail} />
          </CardBody>
        ) : (
          <CardBody className="p-5">
            <div className="full-loading">
              <Loader />
            </div>
          </CardBody>
        )}
      </Card>
    );
  }
}
const mapStateToProps = ({ post }, ownProps) => {
  return {
    detail: post[ownProps.match.params.id]
  };
};
export default connect(
  mapStateToProps,
  {
    getPostDetail,
    updatePost
  }
)(DetailPost);
