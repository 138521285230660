import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Loader from "components/Admin/Loader";
import ProductOP from "components/Admin/Form/BaseForm/ProductOP";
import { addProductOP, getProductOP } from "actions/admin/product";
import { TOAST_OPTIONS } from "helpers";

class CreateOP extends Component {
  state = {
    loading: false
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onSubmit = async dataValue => {
    if (!dataValue.category) {
      toast.warn(`Cần chọn mục sản phẩm`, TOAST_OPTIONS);
      return null;
    }

    this.onLoader();
    try {
      await this.props.addProductOP(dataValue);
      await this.props.getProductOP();
      toast.success(`Sản phẩm đã được tạo thành công`, TOAST_OPTIONS);
      this.onClose();
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  onClose = () => {
    this.props.handleClose();
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <ProductOP submitForm={this.onSubmit} handleClose={this.onClose} />
      </div>
    );
  }
}
export default connect(
  null,
  { addProductOP, getProductOP }
)(CreateOP);
