import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import _ from "lodash";
// MATERIAL
import { Save, Edit, KeyboardBackspace as Back } from "@material-ui/icons";
// COMPONENT
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons";

class BaseContentForm extends Component {
  state = {
    isEdit: true,
    [this.props.nameInput]: ""
  };

  componentDidMount() {
    if (this.props.initialValues) {
      this.setState({
        [this.props.nameInput]: this.props.initialValues[this.props.nameInput]
      });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  handleBack = () => this.setState({ isEdit: true });
  handleEdit = () => this.setState({ isEdit: false });

  renderField = ({ input, label, disabled, dataValue }) => {
    return (
      <GridItem xs={12}>
        <Input
          labelText={label}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            name: input.name,
            onChange: this.handleChange,
            value: dataValue,
            fullWidth: true,
            multiline: true,
            disabled,
            rowsMax: "5"
          }}
        />
      </GridItem>
    );
  };

  onSubmit = async value => {
    const data = _.pick(value, [this.props.nameInput]);
    await this.props.onSubmit(data);
    this.handleBack();
  };

  render() {
    const { isEdit } = this.state;
    const { label, nameInput } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <GridContainer>
          <Field
            component={this.renderField}
            name={nameInput}
            label={label}
            dataValue={this.state[nameInput]}
            disabled={isEdit}
          />
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          {!isEdit ? (
            <Fragment>
              <Button color="warning" simple onClick={this.handleBack}>
                <Back />
                <span className="btn-text">trở lại</span>
              </Button>
              <Button color="success" simple type="submit">
                <Save />
                <span className="btn-text">Lưu</span>
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button color="primary" simple onClick={this.handleEdit}>
                <Edit />
                <span className="btn-text">chỉnh sửa</span>
              </Button>
            </Fragment>
          )}
        </div>
      </form>
    );
  }
}
BaseContentForm.propTypes = {
  label: PropTypes.string.isRequired,
  nameInput: PropTypes.string.isRequired
};
export default reduxForm({ form: "contentForm" })(BaseContentForm);
