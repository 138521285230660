import React from "react";

export default function ChatSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__zalo"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <circle
        id="prefix__Ellipse_39"
        cx="22"
        cy="22"
        r="22"
        fill="#fff"
        data-name="Ellipse 39"
      />
      <g
        id="prefix__Group_426"
        data-name="Group 426"
        transform="translate(4.022 15.404)"
      >
        <path
          id="prefix__Path_174"
          d="M178.694-140.219a4.59 4.59 0 0 0-.168-1.036 5.164 5.164 0 0 0-6.4-3.61 5.173 5.173 0 0 0-3.7 5.707 5.171 5.171 0 0 0 4.826 4.413v.025h.6v-.026l.327-.033a5.164 5.164 0 0 0 4.515-4.8.091.091 0 0 0 .026 0v-.628a.047.047 0 0 0-.026-.012zm-5.158 3.359a3.054 3.054 0 0 1-3.036-3.04 3.053 3.053 0 0 1 3.046-3.038 3.051 3.051 0 0 1 3.038 3.045 3.051 3.051 0 0 1-3.048 3.033z"
          className="prefix__cls-2"
          data-name="Path 174"
          transform="translate(-141.54 147.911)"
        />
        <path
          id="prefix__Path_175"
          d="M151.081-136.424l6.429-8.069a10.749 10.749 0 0 0 .707-.88 2.809 2.809 0 0 0 .379-1.611c0-.215 0-.216-.214-.216H148.282c-.157 0-.21.042-.207.2.009.607 0 1.214 0 1.821 0 .222 0 .222.226.222h6.864c.053 0 .129-.032.156.032s-.046.088-.078.128l-7 8.659a1.972 1.972 0 0 0-.438 1.008c-.034.267-.012.533-.026.8-.008.14.042.177.178.177q4.854-.006 9.708 0a.891.891 0 0 0 .925-.919v-.958c0-.223 0-.224-.222-.224h-7.319c-.058 0-.079-.024-.041-.077l.073-.093z"
          className="prefix__cls-2"
          data-name="Path 175"
          transform="translate(-147.782 147.225)"
        />
        <path
          id="prefix__Path_176"
          d="M166.514-144.727h-1.821c-.107 0-.152.034-.148.145.007.17 0 .34 0 .513a.279.279 0 0 1-.169-.08 5.058 5.058 0 0 0-3.6-.812 5.1 5.1 0 0 0-4.379 5.382 4.846 4.846 0 0 0 2.311 3.97 4.989 4.989 0 0 0 5.682-.037c.1-.065.144-.086.174.063a.872.872 0 0 0 .895.725h.958c.227 0 .227 0 .227-.234v-9.408a.735.735 0 0 1 0-.094c.018-.099-.03-.133-.13-.133zm-5 7.857a3.016 3.016 0 0 1-3.009-3.022 3 3 0 0 1 3.02-3.008 3.021 3.021 0 0 1 3.017 3 3.027 3.027 0 0 1-3.027 3.03z"
          className="prefix__cls-2"
          data-name="Path 176"
          transform="translate(-145.17 147.925)"
        />
        <path
          id="prefix__Path_177"
          d="M167.783-147.186v-.034h-2.262v12.273a.334.334 0 0 0 .028.168.738.738 0 0 0 .767.627h1.223c.248 0 .248 0 .248-.244V-147c0-.06-.003-.124-.004-.186z"
          className="prefix__cls-2"
          data-name="Path 177"
          transform="translate(-142.336 147.22)"
        />
      </g>
    </svg>
  );
}
