import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gActiveComments } from "actions/landing";
import IconHeading from "assets/img/csp/ic-heading.png";
import IntlMessages from "util/IntlMessages";
import FadeInSection from "components/_App/FadeInSection";
import Slider from "react-slick";

export default function CaseStudy() {
  const locale = useSelector(({ settings }) => settings.locale.locale);
  const list = useSelector(({ landing }) => landing.comments);
  const dispatch = useDispatch();

  useEffect(() => {
    async function onFetch() {
      try {
        dispatch(gActiveComments());
      } catch (error) {
        console.log("OnFetch Faild", error.message);
      }
    }
    onFetch();
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <FadeInSection>
      <section className="case-section section-box">
        <div className="csp-container">
          <div className="main-title-wrap">
            <h2 className="main-title">
              <img
                src={IconHeading}
                className="ic-heading"
                alt="icon-heading"
              />
              <span>
                <IntlMessages id="home.cs.title" />
              </span>
            </h2>
          </div>

          {list && (
            <Slider className="case-study-slick" {...settings}>
              {list.map(item => (
                <div className="case-study-box" key={item.id}>
                  <div className="case-study-logo">
                    <img src={item.url} alt={item.id} />
                  </div>
                  <div
                    className="case-study-desc"
                    dangerouslySetInnerHTML={{ __html: item[locale].comment }}
                  />
                  <div className="case-study-detail">
                    <p className="deputy">{item.deputy}</p>
                    <p>{item.role}</p>
                    <p>{item.name}</p>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </section>
    </FadeInSection>
  );
}
