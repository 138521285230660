import React from "react";
import { compose, withProps } from "recompose";
import { withGoogleMap } from "react-google-maps";
import MapLayout from "./MayLayout";
import Loader from "components/Admin/Loader";
// Map Props
const mapProps = {
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `500px` }} className="map-wrapper" />,
  mapElement: <div style={{ height: `100%` }} />
};
// Map Component
const MapComponent = compose(
  withProps(mapProps),
  withGoogleMap
);
// Initialize Map
const MapWithInfo = MapComponent(MapLayout);

class SimpleMap extends React.PureComponent {
  state = {
    isMarkerShown: true,
    selectedMarker: false,
    zoom: 5,
    defaultLocation: {
      lat: 16.047079,
      lng: 108.20623
    }
  };
  handleClick = marker => {
    this.setState({
      selectedMarker: marker
    });
  };

  render() {
    const { isMarkerShown, defaultLocation, selectedMarker, zoom } = this.state;
    if (!this.props.places) {
      return <Loader />;
    }
    return (
      <MapWithInfo
        isMarkerShown={isMarkerShown}
        defaultLocation={defaultLocation}
        selectedMarker={selectedMarker}
        zoom={zoom}
        onClick={this.handleClick}
        markers={this.props.places}
      />
    );
  }
}

export default SimpleMap;
