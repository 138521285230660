import React, { Component } from "react";
import { Face, Info, Pageview } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import NavPills from "components/NavPills/NavPills";
import HRList from "./component/HRModule/HRList";
import Intro from "./component/Intro";
import SEOIndex from "./component/SEOIndex";
class HR extends Component {
  render() {
    return (
      <NavPills
        color="primary"
        tabs={[
          {
            tabButton: "SEO",
            tabIcon: Pageview,
            tabContent: (
              <Card>
                <CardBody className="p-5">
                  <SEOIndex />
                </CardBody>
              </Card>
            )
          },
          {
            tabButton: "GIỚI THIỆU",
            tabIcon: Info,
            tabContent: (
              <Card>
                <CardBody className="p-5">
                  <Intro />
                </CardBody>
              </Card>
            )
          },
          {
            tabButton: "NHÂN SỰ",
            tabIcon: Face,
            tabContent: <HRList />
          }
        ]}
      />
    );
  }
}

export default HR;
