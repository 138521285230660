import React from "react";
import CardBody from "components/Card/CardBody";
import Heading from "components/Heading";
import VideoList from "./List";

export default function List() {
  return (
    <div className="dashboard-video">
      <CardBody>
        <div className="position-relative">
          <Heading title="THƯ VIỆN MEDIA" />
        </div>
        <VideoList />
      </CardBody>
    </div>
  );
}
