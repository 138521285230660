import { PARTNER } from "../constant";
import ADMIN_URL from "apis/AdminURL";

export const getPartner = () => async dispatch => {
  const response = await ADMIN_URL.get(`/partner/all`);
  dispatch({
    type: PARTNER.GET_PARTNER,
    payload: response.data
  });
};
export const getPartnerDetail = (id, lang) => async dispatch => {
  const response = await ADMIN_URL.get(`/partner/${id}/${lang}`);
  dispatch({
    type: PARTNER.GET_PARTNER_DETAIL,
    payload: response.data
  });
};
export const addPartner = dataValue => async dispatch => {
  await ADMIN_URL.post("/partner/add", dataValue);
  dispatch({ type: PARTNER.ADD_PARTNER });
};
export const updatePartner = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/partner/${id}/${lang}`, dataValue);
  dispatch({ type: PARTNER.UPDATE_PARTNER });
};
export const deletePartner = id => async dispatch => {
  await ADMIN_URL.delete(`/partner/${id}`);
  dispatch({ type: PARTNER.DELETE_PARTNER });
};
