import axios from "axios";
const domain = "https://csp-group-web.appspot.com";
const baseURL = `${domain}/admin`;

const instance = axios.create({ baseURL });
instance.interceptors.request.use(
  config => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken !== null) {
      config.headers["Authorization"] = `Bearer ${jwtToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
