import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Card from "components/Card/Card";
import Loader from "components/Admin/Loader";
import CardBody from "components/Card/CardBody";
import BasePost from "components/Admin/Form/BaseForm/Post";
import { addPost } from "actions/admin/post";
import history from "history.js";
import { TOAST_OPTIONS } from "helpers";
class CreatePost extends Component {
  state = {
    loading: false
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onSubmit = async dataValue => {
    this.onLoader();
    try {
      await this.props.addPost(dataValue);
      toast.success(`Bài viết đã được tạo`, TOAST_OPTIONS);
      history.push("/dashboard/blog");
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  render() {
    const { loading } = this.state;
    return (
      <Card>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <CardBody>
          <BasePost submitForm={this.onSubmit} />
        </CardBody>
      </Card>
    );
  }
}
export default connect(
  null,
  { addPost }
)(CreatePost);
