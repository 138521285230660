import React, { PureComponent } from "react";
import cx from "classnames";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PURL from "apis/PublicURL";
import { Breadcrumbs } from "@material-ui/core";
import { gCateOptitexDetail } from "actions/landing";
import aboutOverlay from "assets/img/csp/about-overlay.png";
import designBg from "assets/img/csp/optitex-design-bg.jpg";
import developBg from "assets/img/csp/optitex-develop.jpg";
import produceBg from "assets/img/csp/optitex-produce.jpg";
import serviceBg from "assets/img/csp/optitex-service.jpg";
import bagBg from "assets/img/csp/optitex-bag.jpg";
import IconHeading from "assets/img/csp/ic-heading.png";
import Loader from "components/Admin/Loader";
import FadeInSection from "components/_App/FadeInSection";
import ReactGA from "react-ga";
import IntlMessages from "util/IntlMessages";
import CustomHelmet from "pages/CustomHelmet";

class DetailOptitex extends PureComponent {
  state = {
    content: "",
    list: []
  };

  componentDidMount() {
    this.onCall = this.onLoad();
    ReactGA.pageview(window.location.pathname);
  }

  componentWillUnmount() {
    this.onCall = null;
  }

  async onLoad() {
    await this.props.gCateOptitexDetail(this.props.match.params.id);
    await this.onFetchList();
    this.setState({ content: this.props.content });
  }

  async onFetchList() {
    const response = await PURL.get(
      `/company/optitex/products/${this.props.match.params.id}`
    );
    const list = _.sortBy(response.data, "created_on");
    this.setState({ list });
  }

  getBgImage = () => {
    if (
      window.location.pathname.indexOf(
        `/product/optitex/wbY2SOyh88GDGicx8c5u`
      ) !== -1
    ) {
      return designBg;
    } else if (
      window.location.pathname.indexOf(
        "/product/optitex/TFD3yR9pXjOawHCcxrOz"
      ) !== -1
    ) {
      return developBg;
    } else if (
      window.location.pathname.indexOf(
        "/product/optitex/8JI0pfYMQYB5YtQRJoR9"
      ) !== -1
    ) {
      return produceBg;
    } else if (
      window.location.pathname.indexOf(
        "/product/optitex/wl7hvzjdTUoVPYZuteIN"
      ) !== -1
    ) {
      return bagBg;
    } else if (
      window.location.pathname.indexOf(
        "/product/optitex/pjBUq48Cr9F35m05gTlg"
      ) !== -1
    ) {
      return serviceBg;
    }
  };

  onCheckBg = () => {
    if (
      window.location.pathname.indexOf(
        "/product/optitex/TFD3yR9pXjOawHCcxrOz"
      ) !== -1
    ) {
      return true;
    }
  };

  onRenderList = list => {
    const { locale } = this.props;
    return list.map((item, key) => {
      return (
        <div className="optitex-product-item" key={key}>
          <div className="optitex-product-image">
            <img src={item.thumbnail} className="img-fluid " alt={item.id} />
          </div>
          <div className="optitex-product-content">
            <div className="optitex-product-detail">
              <div className="blog-title-wrap">
                <h2 className="blog-title">
                  <img
                    src={IconHeading}
                    className="ic-heading"
                    alt="icon-heading"
                  />
                  <span>{item[locale].name}</span>
                </h2>
                <div
                  className="optitex-product-para"
                  dangerouslySetInnerHTML={{ __html: item[locale].content }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const { locale } = this.props;
    const { content, list } = this.state;

    if (!content || !list) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      );
    }

    return (
      <FadeInSection>
        <section className="optitex-product-detail-banner">
          <CustomHelmet detail={content} imageSrc={content.thumbnail} />
          <div
            className="bg-section"
            style={{ backgroundImage: `url(${this.getBgImage()})` }}
          >
            <img
              src={aboutOverlay}
              alt="bg_overlay"
              className={cx(
                "banner-overlay",
                this.onCheckBg() ? "hide" : "show"
              )}
            />
            <div className="csp-container">
              <div className="about-banner-inner">
                <div className="about-content">
                  <h2 className="title">{content[locale].name}</h2>
                </div>
              </div>
              <div className="nav-heading">
                <Breadcrumbs aria-label="breadcrumb" separator=".">
                  <Link to="/" className="nav-heading-menu">
                    <IntlMessages id="home.menu.index" />
                  </Link>
                  <Link to="/about/partner" className="nav-heading-menu">
                    <IntlMessages id="home.menu.product" />
                  </Link>
                  <Link to="/product/optitex" className="nav-heading-menu">
                    Optitex
                  </Link>
                  <div className="nav-heading-breadcrumb">
                    {content[locale].name}
                  </div>
                </Breadcrumbs>
              </div>
            </div>
          </div>
        </section>
        <section className="optitex-product-info section-box bg-white">
          <div className="csp-container">
            <div className="optitex-product-list">
              {this.onRenderList(list)}
            </div>
          </div>
        </section>
      </FadeInSection>
    );
  }
}

const mapStateToProps = ({ settings, landing }, ownProps) => {
  return {
    locale: settings.locale.locale,
    content: landing[ownProps.match.params.id]
  };
};

export default connect(
  mapStateToProps,
  { gCateOptitexDetail }
)(DetailOptitex);
