import _ from "lodash";
import { bucket } from "apis/config";

export const imageSize = (url, callback) => {
  let img = new Image();
  img.onload = function() {
    if (callback) callback(img.width, img.height);
  };
  img.src = url;
};

export const convertTimeStamp = timeStamp => {
  let time = new Date(timeStamp);
  let year = time.getFullYear();
  let month = ("0" + (time.getMonth() + 1)).slice(-2);
  let day = ("0" + time.getDate()).slice(-2);
  let hour = time.getHours();
  let minute = ("0" + time.getMinutes()).slice(-2);
  let period = "AM";
  let hourH = hour;
  let timeString;
  if (hourH > 12) {
    hour = hourH - 12;
    period = "PM";
  } else if (hourH === 12) {
    hour = 12;
    period = "PM";
  } else if (hourH === 0) {
    hour = 12;
  }
  timeString = `${day}/${month}/${year} ${hour}:${minute} ${period}`;
  return timeString;
};

export const TOAST_OPTIONS = {
  hideAfter: 2,
  position: "top-right"
};

export const TABLE_CONFIG = {
  className: "-striped -highlight",
  defaultPageSize: 10,
  showPageSizeOptions: false,
  previousText: "Trước",
  nextText: "Sau",
  pageText: "Trang",
  sortable: false,
  filterable: false,
  resizable: false
};

export const removeDot = value => {
  let result;
  result = value.substring(0, value.indexOf("."));
  return result;
};

export const removeWithDash = string => {
  let str;
  const reg = "/[^a-zA-Z0-9]/g";
  str = _.replace(string, reg, "-");
  return str;
};

export function deleteStorage(url_link) {
  return bucket.refFromURL(url_link).delete();
}

export function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function validateEmail(email) {
  const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
