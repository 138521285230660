import React, { useState, useEffect } from "react";
import PURL from "apis/PublicURL";
import Banner from "./components/Banner";
import DoServices from "./components/DoServices";
import Providing from "./components/Providing";
import SaleService from "./components/SaleService";
import InNeedBar from "components/_App/InNeedBar";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";

// SEO ID SERVICE
const SEO_ID = "Xzdkkh9YP6F8DxIhsiWY";

export default function Services() {
  const [detail, setDetail] = useState("");
  useEffect(() => {
    async function onFetch() {
      try {
        const res = await PURL.get(`company/seo/${SEO_ID}`);
        setDetail(res.data);
      } catch (error) {
        console.log("Failed to fetch list", error.message);
      }
    }
    onFetch();
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="services-page">
      {detail && (
        <CustomHelmet
          detail={detail}
          imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/wwa-logo.jpeg?alt=media&token=09bf3a41-a108-4177-9978-8db442a8a338"
        ></CustomHelmet>
      )}
      <Banner />
      <InNeedBar id="need.service.text" />
      <DoServices />
      <Providing />
      <InNeedBar id="need.parts.text" />
      <SaleService />
    </div>
  );
}
