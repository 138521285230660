import React, { Component } from "react";
import { connect } from "react-redux";
import FileUploader from "react-firebase-file-uploader";
import toast from "cogo-toast";
import _ from "lodash";
import { Publish } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import Heading from "components/Heading";
import Button from "components/CustomButtons";
import { bucket, db } from "apis/config";
import { imageSize, TOAST_OPTIONS, removeDot } from "helpers";
import { getImages } from "actions/admin/photo";

class UploadImage extends Component {
  state = {
    show: false,
    photoDetail: null,
    isUploading: false,
    progress: 0
  };

  handleUploadSuccess = filename => {
    const imgDB = db.collection("gallery");
    const imgBucket = bucket.ref("gallery");
    const createdAt = _.now();
    const name = removeDot(filename.split("_")[1]);
    return imgBucket
      .child(filename)
      .getDownloadURL()
      .then(url => {
        return imageSize(url, (w, h) => {
          let obj = {
            createdAt,
            name,
            src: url,
            alt: name,
            width: Math.floor(w / 100),
            height: Math.floor(h / 100)
          };
          return imgDB.add(obj).then(docRef => {
            const id = docRef.id;
            this.props.getImages();
            toast.success(`Hình ảnh ${name} đã được tải lên`, TOAST_OPTIONS);
            this.props.handleUploading(false, 100);
            return imgDB.doc(id).update({ id });
          });
        });
      })
      .catch(error => {
        this.props.handleUploading(false, 0);
        toast.error(error.message, TOAST_OPTIONS);
      });
  };

  handleUploadStart = () => this.props.handleUploading(true, 0);
  handleProgress = progress => this.props.handleProgress(progress);
  onGenerateFileName = file => {
    let filename;
    const now = _.now();
    filename = `${now}_${file.name}`;
    return filename;
  };

  render() {
    return (
      <div className="position-relative">
        <Heading title="THƯ VIỆN ẢNH" />
        <Tooltip title="Đăng ảnh">
          <Button color="primary" size="sm" className="btn-float">
            <Publish />
            <FileUploader
              className="input-upload"
              accept="image/*"
              name="image"
              multiple
              filename={file => this.onGenerateFileName(file)}
              storageRef={bucket.ref("gallery")}
              onUploadSuccess={this.handleUploadSuccess}
              onUploadStart={this.handleUploadStart}
              onProgress={this.handleProgress}
            />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default connect(
  null,
  { getImages }
)(UploadImage);
