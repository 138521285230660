import React from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";

const aboutMenu = [
  { id: "about.profile.title", path: "company-profile" },
  { id: "about.history.title", path: "history" },
  { id: "about.partner.title", path: "partner" },
  { id: "about.team.title", path: "team" }
];

export default function AboutSideMenu() {
  return (
    <ul className="about-sidebar">
      {aboutMenu.map((item, key) => (
        <li className="menu-button" key={key}>
          <Tooltip title={<IntlMessages id={item.id} />}>
            <NavLink
              activeClassName="active"
              to={`/about/${item.path}`}
              className="menu-link"
            >
              <IntlMessages id={item.id} />
            </NavLink>
          </Tooltip>
        </li>
      ))}
    </ul>
  );
}
