import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import AuthLayout from "./layouts/Auth";
import LandingLayout from "./layouts/Landing";
import AboutLayout from "./layouts/About";
import AdminLayout from "./layouts/Admin";
import ContactLayout from "./layouts/Contact";
import ServiceLayout from "./layouts/Service";
import NewsEvents from "./layouts/NewsEvents";
import ProductLayout from "./layouts/Product";
import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "./SrollToTop";
import history from "history.js";
import ReactGA from "react-ga";
ReactGA.initialize("UA-152928229-1");

const Root = () => {
  return (
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <Switch>
        <Route path="/home" component={LandingLayout} />
        <Route path="/about" component={AboutLayout} />
        <Route path="/contact" component={ContactLayout} />
        <Route path="/services" component={ServiceLayout} />
        <Route path="/news-event" component={NewsEvents} />
        <Route path="/product" component={ProductLayout} />
        <Route path="/auth" component={AuthLayout} />
        <PrivateRoute path="/dashboard" component={AdminLayout} />
        <Redirect from="/" to="/home" />
      </Switch>
    </ConnectedRouter>
  );
};
export default Root;
