import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Loader from "components/Admin/Loader";
import BaseClient from "components/Admin/Form/BaseForm/Client";
import { getClientDetail, updateClient } from "actions/admin/client";
import history from "history.js";
import { TOAST_OPTIONS } from "helpers";
import Heading from "components/Heading";
class DetailClient extends Component {
  state = {
    loading: false,
    detail: "",
    id: this.props.match.params.id,
    lang: this.props.match.params.lang
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  componentDidMount() {
    this.onLoad();
  }

  async onLoad() {
    const { lang, id } = this.state;
    await this.props.getClientDetail(id, lang);
    this.setState({ detail: this.props.detail });
  }

  onSubmit = async dataValue => {
    const { id, lang } = this.state;
    this.onLoader();
    try {
      await this.props.updateClient(id, lang, dataValue);
      toast.success(`Thông tin khách hàng đã được cập nhật`, TOAST_OPTIONS);
      history.push("/dashboard/client");
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  render() {
    const { loading, detail, lang } = this.state;
    return (
      <Card>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        {detail ? (
          <CardBody>
            <Heading
              title={`THÔNG TIN - ${
                lang === "vi" ? "TIẾNG VIỆT" : "TIẾNG ANH"
              }`}
            />
            <BaseClient submitForm={this.onSubmit} initialValues={detail} />
          </CardBody>
        ) : (
          <CardBody className="p-5">
            <div className="full-loading">
              <Loader />
            </div>
          </CardBody>
        )}
      </Card>
    );
  }
}
const mapStateToProps = ({ client }, ownProps) => {
  return {
    detail: client[ownProps.match.params.id]
  };
};
export default connect(
  mapStateToProps,
  {
    getClientDetail,
    updateClient
  }
)(DetailClient);
