import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import IconHeading from "assets/img/csp/ic-heading.png";
import OPTITEX from "assets/img/csp/wwo/optitex.png";
import BULLMER from "assets/img/csp/wwo/bullmer-3.png";
import TDOT from "assets/img/csp/wwo/tdot.png";
import IDIGIT from "assets/img/csp/wwo/idigit.png";
import GRAPHTEC from "assets/img/csp/wwo/graphtec.png";
import ATP from "assets/img/csp/wwo/atp.png";
import ACCESSORIES from "assets/img/csp/wwo/accessories.png";
import IntlMessages from "util/IntlMessages";
import FadeInSection from "components/_App/FadeInSection";

const partners = [
  { name: "OPTITEX", url: OPTITEX, path: "/product/optitex" },
  {
    name: "TDOT",
    url: TDOT,
    path: "/product/tdot/Id9NjztLRCxLld3UDvcN"
  },
  {
    name: "GRAPHTEC",
    url: GRAPHTEC,
    path: "/product/graphtec/gkKI7oeXMPLjJaW8TvYl"
  },
  {
    name: "iDIGIT",
    url: IDIGIT,
    path: "/product/idigit/VqYkzy7x54SDJTXTjt6G"
  },
  {
    name: "BULLMER",
    url: BULLMER,
    path: "/product/bullmer/R9TqcnxPl3qUC3u6Tn0i"
  },
  { name: "ATP", url: ATP, path: "/product/atp/BHaLGtW8SkTNNC8QHdOH" },
  { name: "ACCESSORIES", url: ACCESSORIES, path: "/services" }
];

export default function WhatWeOffer() {
  return (
    <FadeInSection>
      <section className="what-we-offer section-box bg-white">
        <div className="csp-container">
          <div className="main-title-wrap">
            <h2 className="main-title">
              <img
                src={IconHeading}
                className="ic-heading"
                alt="icon-heading"
              />
              <span>
                <IntlMessages id="home.wwo.title" />
              </span>
            </h2>
          </div>
          <GridContainer alignItems="center">
            {partners.map((item, key) => (
              <GridItem
                xs={12}
                sm={6}
                md={item.name === "BULLMER" ? 6 : 3}
                key={key}
                className="mb-4"
              >
                <Tooltip title={item.name.toUpperCase()}>
                  <Link to={item.path}>
                    <img src={item.url} alt={item.name} className="img-fluid" />
                  </Link>
                </Tooltip>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </section>
    </FadeInSection>
  );
}
