import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

export default function SimpleDialog(props) {
  const { onClose, open, children, title, ...rest } = props;
  const handleClose = () => onClose();
  return (
    <Dialog onClose={handleClose} open={open} {...rest}>
      {title ? <DialogTitle>{title.toUpperCase()}</DialogTitle> : null}
      <DialogContent style={{ padding: 24 }}>{children}</DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string
};
