import { COMPANY, SETTINGS } from "./type";
import PublicURL from "apis/PublicURL";
import _ from "lodash";

export function switchLanguage(locale) {
  return {
    type: SETTINGS.SWITCH_LANGUAGE,
    payload: locale
  };
}

// --- BANNER
export const getHeroBanner = () => async dispatch => {
  const response = await PublicURL.get(`/company/hero-banner`);
  dispatch({
    type: COMPANY.PUBLIC_HERO_BANNER,
    payload: response.data
  });
};

// --- CONTACT
export const getContact = () => async dispatch => {
  const response = await PublicURL.get(`/company/contact`);
  dispatch({
    type: COMPANY.PUBLIC_CONTACT,
    payload: response.data
  });
};

// --- CLIENT
export const gActiveClients = () => async dispatch => {
  const response = await PublicURL.get(`/company/clients`);
  const sorted = _.orderBy(response.data, ["created_on"]);
  const clients = _.chunk(sorted, 6);
  dispatch({
    type: COMPANY.PUBLIC_CLIENT,
    payload: clients
  });
};

export const gActiveComments = () => async dispatch => {
  const response = await PublicURL.get(`/company/comments`);
  dispatch({
    type: COMPANY.PUBLIC_COMMENTS,
    payload: response.data
  });
};

// --- PARTNERS
export const gActivePartners = () => async dispatch => {
  const response = await PublicURL.get(`/company/partners`);
  dispatch({
    type: COMPANY.PUBLIC_PARTNER,
    payload: response.data
  });
};

// --- PRODUCT
export const gProducts = id => async dispatch => {
  const response = await PublicURL.get(`/company/products/${id}`);
  const payload = _.orderBy(response.data, "created_on")
  dispatch({
    type: COMPANY.PUBLIC_PRODUCT,
    payload,
    brand_id: id
  });
};

export const gContents = id => async dispatch => {
  const response = await PublicURL.get(`/company/contents/${id}`);
  dispatch({
    type: COMPANY.PUBLIC_CONTENT,
    payload: response.data
  });
};

export const gPVideo = () => async dispatch => {
  const response = await PublicURL.get(`/company/videos`);
  dispatch({
    type: COMPANY.PUBLIC_VIDEOS,
    payload: response.data
  });
};

// EVENTS NEWS
export const getNews = () => async dispatch => {
  const response = await PublicURL.get(`/company/news`);
  dispatch({
    type: COMPANY.PUBLIC_NEWS,
    payload: response.data
  });
};

export const getEvents = () => async dispatch => {
  const response = await PublicURL.get(`/company/events`);
  dispatch({
    type: COMPANY.PUBLIC_EVENTS,
    payload: response.data
  });
};

export const gCateOptitex = () => async dispatch => {
  const response = await PublicURL.get(`/company/optitex/category`);
  dispatch({
    type: COMPANY.PUBLIC_OPTITEX_CATEGORY,
    payload: response.data
  });
};

export const gCateOptitexDetail = id => async dispatch => {
  const response = await PublicURL.get(`/company/optitex/category/${id}`);
  dispatch({
    type: COMPANY.PUBLIC_OPTITEX_CATEGORY_DETAIL,
    payload: response.data
  });
};
