import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Header from "components/landing/Header";
import Footer from "components/landing/Footer";
import routes from "routes";
import AppLocale from "lngProvider";

class ProductLayout extends Component {
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      if (prop.layout === "/product") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <div className="landing-page">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Header />
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer />
        </IntlProvider>
      </div>
    );
  }
}
const mapStateToProps = ({ settings }) => {
  return {
    locale: settings.locale
  };
};
export default connect(mapStateToProps)(ProductLayout);
