import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Header from "components/landing/Header";
import Footer from "components/landing/Footer";
import AboutSideMenu from "components/_App/AboutSideMenu";
import AboutRecentNew from "components/_App/AboutRecentNew";
import AboutRequestContact from "components/_App/AboutRequestContact";
import IntlMessages from "util/IntlMessages";
import aboutProfile from "assets/img/csp/about-profile.jpg";
import aboutTeam from "assets/img/csp/about-team.jpg";
import aboutPartner from "assets/img/csp/about-partner.jpg";
import aboutHist from "assets/img/csp/about-history.jpg";
import aboutOverlay from "assets/img/csp/about-overlay.png";
import routes from "routes";
import AppLocale from "lngProvider";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AboutRqcb from "components/_App/RqCb/About";
import InNeedBar from "components/_App/InNeedBar";
class AboutLayout extends Component {
  getBgImage = () => {
    if (window.location.pathname.indexOf("/about/company-profile") !== -1) {
      return aboutProfile;
    } else if (window.location.pathname.indexOf("/about/team") !== -1) {
      return aboutTeam;
    } else if (window.location.pathname.indexOf("/about/history") !== -1) {
      return aboutHist;
    } else if (window.location.pathname.indexOf("/about/partner") !== -1) {
      return aboutPartner;
    }
  };

  getTitle = () => {
    if (window.location.pathname.indexOf("/about/company-profile") !== -1) {
      return <IntlMessages id="about.profile.title" />;
    } else if (window.location.pathname.indexOf("/about/team") !== -1) {
      return <IntlMessages id="about.team.title" />;
    } else if (window.location.pathname.indexOf("/about/history") !== -1) {
      return <IntlMessages id="about.history.title" />;
    } else if (window.location.pathname.indexOf("/about/partner") !== -1) {
      return <IntlMessages id="about.partner.title" />;
    }
  };

  getSub = () => {
    if (window.location.pathname.indexOf("/about/company-profile") !== -1) {
      return null;
    } else if (window.location.pathname.indexOf("/about/team") !== -1) {
      return <IntlMessages id="about.team.sub" />;
    } else if (window.location.pathname.indexOf("/about/history") !== -1) {
      return <IntlMessages id="about.history.sub" />;
    } else if (window.location.pathname.indexOf("/about/partner") !== -1) {
      return <IntlMessages id="about.partner.sub" />;
    }
  };

  getName = () => {
    if (window.location.pathname.indexOf("/about/company-profile") !== -1) {
      return <IntlMessages id="about.profile.breadcrumbs" />;
    } else if (window.location.pathname.indexOf("/about/team") !== -1) {
      return <IntlMessages id="about.team.title" />;
    } else if (window.location.pathname.indexOf("/about/history") !== -1) {
      return <IntlMessages id="about.history.title" />;
    } else if (window.location.pathname.indexOf("/about/partner") !== -1) {
      return <IntlMessages id="about.partner.title" />;
    }
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      if (prop.layout === "/about") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            matchUrl={this.props.match.url}
          />
        );
      } else {
        return null;
      }
    });
  };

  getRqCb = () => {
    if (window.location.pathname.indexOf("/about/team") !== -1) {
      return <AboutRqcb />;
    } else if (window.location.pathname.indexOf("/about/history") !== -1) {
      return <InNeedBar id="need.readmore.text" className="dark" about />;
    }
  };

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <div className="landing-page">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Header />
          <div
            className="about-banner bg-section"
            style={{ backgroundImage: `url(${this.getBgImage()})` }}
          >
            <img
              src={aboutOverlay}
              alt="bg_overlay"
              className="banner-overlay"
            />
            <div className="csp-container">
              <div className="about-banner-inner">
                <div className="about-content">
                  <h2 className="title">{this.getTitle()}</h2>
                  <p className="sub-title">{this.getSub()}</p>
                </div>
              </div>
              <div className="nav-heading">
                <Breadcrumbs aria-label="breadcrumb" separator=".">
                  <Link to="/" className="nav-heading-menu">
                    <IntlMessages id="home.menu.index" />
                  </Link>
                  <Link to="/about" className="nav-heading-menu">
                    <IntlMessages id="home.menu.about" />
                  </Link>
                  <div className="nav-heading-breadcrumb">{this.getName()}</div>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="about-box bg-white">
            <div className="csp-container">
              <div className="row about-box-inner">
                <Switch>
                  {this.getRoutes(routes)}
                  <Redirect from="/about" to="/about/company-profile" />
                </Switch>
                <div className="col-sm-12 col-lg-3 nav-side-bar">
                  <AboutSideMenu />
                  <AboutRecentNew />
                  <AboutRequestContact />
                </div>
              </div>
            </div>
          </div>
          {this.getRqCb()}
          <Footer />
        </IntlProvider>
      </div>
    );
  }
}
const mapStateToProps = ({ settings, router }) => {
  return {
    locale: settings.locale,
    router: router
  };
};
export default connect(mapStateToProps)(AboutLayout);
