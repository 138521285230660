import React from "react";

export default function LinkedInSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__linkedin"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <circle
        id="prefix__Ellipse_38"
        cx="22"
        cy="22"
        r="22"
        fill="#fff"
        data-name="Ellipse 38"
      />
      <path
        id="prefix__Path_171"
        d="M126.939-138.554a15.865 15.865 0 0 0-.144-2.348 6.624 6.624 0 0 0-.475-1.732 3.719 3.719 0 0 0-1.138-1.483 4.477 4.477 0 0 0-2.233-.835 8.1 8.1 0 0 0-1.028-.051 4.625 4.625 0 0 0-1.848.374 4.048 4.048 0 0 0-1.947 1.676.871.871 0 0 1-.062.086c0-.063-.008-.1-.008-.141v-1.5a.334.334 0 0 0-.005-.11.118.118 0 0 0-.061-.061.164.164 0 0 0-.073 0h-3.706c-.132 0-.139.007-.141.137v12.958c0 .173 0 .173.179.174h3.767c.2 0 .209.033.207-.2v-6.3a8.34 8.34 0 0 1 .078-1.283 3.563 3.563 0 0 1 .284-.986 1.883 1.883 0 0 1 1.292-1.077 3.142 3.142 0 0 1 1.143-.073 1.69 1.69 0 0 1 1.456 1.066 3.646 3.646 0 0 1 .272 1.239c.025.408.032.817.033 1.225v6.209c0 .055.009.111.015.179h3.9c.256 0 .24.036.24-.234z"
        className="prefix__cls-2"
        data-name="Path 171"
        transform="translate(-94.686 163.298)"
      />
      <path
        id="prefix__Path_172"
        d="M113.156-144.654a1.057 1.057 0 0 0-.021-.106h-3.954c-.055 0-.111.008-.169.012v13.248h4.135c0-.075.011-.144.011-.212v-12.832a1.038 1.038 0 0 0-.002-.11z"
        className="prefix__cls-2"
        data-name="Path 172"
        transform="translate(-96.201 163.375)"
      />
      <path
        id="prefix__Path_173"
        d="M111.158-149.74a2.385 2.385 0 0 0-2.4 2.406 2.382 2.382 0 0 0 2.406 2.4 2.39 2.39 0 0 0 2.4-2.4 2.391 2.391 0 0 0-2.406-2.406z"
        className="prefix__cls-2"
        data-name="Path 173"
        transform="translate(-96.282 161.883)"
      />
    </svg>
  );
}
