import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Loader from "components/Admin/Loader";
import { TOAST_OPTIONS } from "helpers";
import ContentForm from "components/Admin/Form/BaseForm/Content";
import { getContent, updateContent } from "actions/admin/company";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const editableId = "Hgg1tCTZulToaJumhSI0";

class Statistics extends Component {
  state = {
    loading: true,
    isEdit: true,
    content: ""
  };

  componentDidMount() {
    this.onLoad();
  }

  async onLoad() {
    await this.props.getContent(editableId);
    this.setState({ loading: false, content: this.props[editableId] });
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleBack = () => this.setState({ isEdit: true });
  handleEdit = () => this.setState({ isEdit: false });

  onSubmit = async value => {
    this.setState({ loading: true });
    try {
      await this.props.updateContent(editableId, value);
      this.onLoad();
      toast.success(`Nội dung đã được cập nhật`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.setState({ loading: false });
  };
  render() {
    const { loading, content } = this.state;
    return (
      <Fragment>
        {loading ? (
          <div className="full-loading">
            <Loader />
          </div>
        ) : null}
        {content ? (
          <div id={editableId} style={{ width: "100%" }}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <ContentForm
                  label="KHÁCH HÀNG"
                  nameInput="customers"
                  initialValues={content}
                  onSubmit={this.onSubmit}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ContentForm
                  label="NGƯỜI DÙNG"
                  nameInput="users"
                  initialValues={content}
                  onSubmit={this.onSubmit}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ContentForm
                  label="NĂM - OPTITEX"
                  nameInput="years"
                  initialValues={content}
                  onSubmit={this.onSubmit}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ContentForm
                  label="NHÀ MÁY"
                  nameInput="factories"
                  initialValues={content}
                  onSubmit={this.onSubmit}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <ContentForm
                  label="NĂM - CSP HỢP TÁC"
                  nameInput="csp_years"
                  initialValues={content}
                  onSubmit={this.onSubmit}
                />
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <div className="m-3" />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ company }) => {
  return {
    [editableId]: company[editableId]
  };
};
export default connect(
  mapStateToProps,
  { getContent, updateContent }
)(Statistics);
