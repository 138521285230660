import React from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import EnForm from "./EnForm";
import ViForm from "./ViForm";
import SEOIndex from "./SEOIndex";
import Accordion from "components/Accordion/Accordion";

export default function AboutUs() {
  return (
    <Card>
      <CardBody>
        <Accordion
          collapses={[
            {
              title: "TIẾNG ANH",
              content: <EnForm />
            },
            {
              title: "TIẾNG VIỆT",
              content: <ViForm />
            },
            {
              title: "NỘI DUNG SEO",
              content: <SEOIndex />
            }
          ]}
        />
      </CardBody>
    </Card>
  );
}
