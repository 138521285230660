import { COMPANY } from "actions/constant";

const initialState = {
  detail: "",
  contact: "",
  brochure: "",
  employee: "",
  banner: "",
  history_anchor: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // COMPANY INFO
    case COMPANY.GET_COMPANY_INFO:
      return { ...state, detail: payload };
    case COMPANY.UPDATE_COMPANY_INFO:
      return { ...state };

    // HISTORY
    case COMPANY.GET_HISTORY:
      return { ...state, history_anchor: payload };
    case COMPANY.UPDATE_HISTORY:
      return { ...state };
    case COMPANY.ADD_HISTORY:
      return { ...state };
    case COMPANY.DELETE_HISTORY:
      return { ...state };

    // CONTACT
    case COMPANY.GET_CONTACT:
      return { ...state, contact: payload };
    case COMPANY.UPDATE_CONTACT:
      return { ...state };

    // BROCHURE
    case COMPANY.GET_BROCHURE:
      return { ...state, brochure: payload };
    case COMPANY.DELETE_BROCHURE:
      return { ...state };

    // BANNER
    case COMPANY.GET_HERO_BANNER:
      return { ...state, banner: payload };
    case COMPANY.UPDATE_HERO_BANNER:
      return { ...state };

    // EMPLOYEE
    case COMPANY.GET_EMPLOYEE:
      return { ...state, employee: payload };
    case COMPANY.ADD_EMPLOYEE:
      return { ...state };
    case COMPANY.UPDATE_EMPLOYEE:
      return { ...state };
    case COMPANY.DELETE_EMPLOYEE:
      return { ...state };

    // CONTENT
    case COMPANY.GET_CONTENT:
      return { ...state, [payload.id]: payload };
    case COMPANY.UPDATE_CONTENT:
      return { ...state };

    default:
      return state;
  }
};
