import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "cogo-toast";
import Loader from "components/Admin/Loader";
import ClientOP from "components/Admin/Form/BaseForm/ClientOP";
import { getOPClient, updateClientOP } from "actions/admin/client";
import { TOAST_OPTIONS } from "helpers";

export default function DetailOP(props) {
  const [loading, setLoading] = useState(false);
  const [detail] = useState(props.detail);
  const [lang] = useState(props.lang);
  const dispatch = useDispatch();
  function onClose() {
    props.handleClose();
  }

  const onSubmit = async dataValue => {
    setLoading(true);
    try {
      await dispatch(updateClientOP(detail.id, lang, dataValue));
      await dispatch(getOPClient());
      toast.success(
        `Thông tin khách hàng được cập nhật thành công`,
        TOAST_OPTIONS
      );
      onClose();
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="full-loading">
          <Loader />
        </div>
      )}
      <ClientOP
        submitForm={onSubmit}
        handleClose={onClose}
        initialValues={detail}
      />
    </div>
  );
}
