import React, { Component } from "react";
// MATERIAL
import { PhotoAlbum, Album } from "@material-ui/icons";
// COMPONENT
import Card from "components/Card/Card";
import Image from "./component/Image";
import Video from "./component/Video";
import NavPills from "components/NavPills/NavPills";

class Category extends Component {
  render() {
    return (
      <NavPills
        color="primary"
        tabs={[
          {
            tabButton: "HÌNH ẢNH",
            tabIcon: PhotoAlbum,
            tabContent: <Image />
          },
          {
            tabButton: "VIDEO",
            tabIcon: Album,
            tabContent: (
              <Card>
                <Video />
              </Card>
            )
          }
        ]}
      />
    );
  }
}
export default Category;
