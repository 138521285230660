import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PublicURL from "apis/PublicURL";
import IntlMessages from "util/IntlMessages";
import RecentItem from "./RecentItem";
import IconHeading from "assets/img/csp/ic-heading.png";

class AboutRecentNew extends PureComponent {
  state = {
    list: []
  };
  componentDidMount() {
    this.onCall = this.onLoad();
  }
  componentWillUnmount() {
    this.onCall = null;
  }

  async onLoad() {
    const response = await PublicURL.get(`/company/news/limit/5`);
    const list = await response.data;
    this.setState({ list });
  }

  render() {
    const { list } = this.state;
    const { locale } = this.props;

    return (
      <div className="recent-news-bar">
        <div className="side-title-wrap">
          <h2 className="side-title">
            <img src={IconHeading} className="ic-heading" alt="icon-heading" />
            <span>
              <IntlMessages id="recent.news" />
            </span>
          </h2>
        </div>
        <div className="recent-item-wrap">
          {list &&
            list.map((item, key) => {
              return (
                <RecentItem
                  key={key}
                  src={item.images[0] && item.images[0]}
                  content={item[locale].desc}
                  refRouter={`/news-event`}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
const maoStateToProps = ({ settings }) => {
  return {
    locale: settings.locale.locale
  };
};
export default connect(maoStateToProps)(AboutRecentNew);
