import { PARTNER } from "actions/constant";

const initialState = {
  list: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PARTNER.ADD_PARTNER:
      return { ...state };

    case PARTNER.GET_PARTNER:
      return { ...state, list: payload };

    case PARTNER.GET_PARTNER_DETAIL:
      return { ...state, [payload.id]: payload };

    case PARTNER.DELETE_PARTNER:
      return { ...state };

    case PARTNER.UPDATE_PARTNER:
      return { ...state };

    default:
      return state;
  }
};
