// Auth
const AUTH = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGOUT: "LOGOUT",
  AUTH_STATE: "AUTH_STATE",
  AUTH_STATE_SUCCESS: "AUTH_STATE_SUCCESS",
  AUTH_STATE_FAILED: "AUTH_STATE_FAILED"
};
// Partner
const PARTNER = {
  GET_PARTNER: "GET_PARTNER",
  GET_PARTNER_DETAIL: "GET_PARTNER_DETAIL",
  ADD_PARTNER: "ADD_PARTNER",
  DELETE_PARTNER: "DELETE_PARTNER",
  UPDATE_PARTNER: "UPDATE_PARTNER"
};
// Post
const POST = {
  GET_POST: "GET_POST",
  GET_POST_DETAIL: "GET_POST_DETAIL",
  GET_POST_WITH_CATEGORY: "GET_POST_WITH_CATEGORY",
  ADD_POST: "ADD_POST",
  DELETE_POST: "DELETE_POST",
  UPDATE_POST: "UPDATE_POST"
};
// Image
const IMAGE = {
  GET_IMAGE: "GET_IMAGE",
  DELETE_IMAGE: "DELETE_IMAGE"
};
// Video
const VIDEO = {
  ADD_VIDEO: "ADD_VIDEO",
  GET_VIDEO: "GET_VIDEO",
  UPDATE_VIDEO: "UPDATE_VIDEO",
  DELETE_VIDEO: "DELETE_VIDEO",
  GET_VIDEO_DETAIL: "GET_VIDEO_DETAIL"
};
//Category
const CATEGORY = {
  GET_CATEGORY_POST: "GET_CATEGORY_POST",
  ADD_CATEGORY_POST: "ADD_CATEGORY_POST",
  UPDATE_CATEGORY_POST: "UPDATE_CATEGORY_POST",
  DELETE_CATEGORY_POST: "DELETE_CATEGORY_POST",
  GET_CATEGORY_BRAND: "GET_CATEGORY_BRAND",
  ADD_CATEGORY_BRAND: "ADD_CATEGORY_BRAND",
  UPDATE_CATEGORY_BRAND: "UPDATE_CATEGORY_BRAND",
  DELETE_CATEGORY_BRAND: "DELETE_CATEGORY_BRAND"
};
// Product
const PRODUCT = {
  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_DETAIL: "GET_PRODUCT_DETAIL",
  ADD_PRODUCT: "ADD_PRODUCT",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  GET_OP_PRODUCT: "GET_OP_PRODUCT",
  GET_OP_PRODUCT_DETAIL: "GET_OP_PRODUCT_DETAIL",
  ADD_OP_PRODUCT: "ADD_OP_PRODUCT",
  UPDATE_OP_PRODUCT: "UPDATE_OP_PRODUCT",
  DELETE_OP_PRODUCT: "DELETE_OP_PRODUCT",
  GET_OP_CATE: "GET_OP_CATE",
  GET_OP_CATE_DETAIL: "GET_OP_CATE_DETAIL",
  ADD_OP_CATE: "ADD_OP_CATE",
  UPDATE_OP_CATE: "UPDATE_OP_CATE",
  DELETE_OP_CATE: "DELETE_OP_CATE"
};

const PRODUCT_PART = {
  GET_PARTS: "GET_PARTS",
  ADD_PART: "ADD_PART",
  DELETE_PART: "DELETE_PART",
  UPDATE_PART: "UPDATE_PART"
};

const CLIENT = {
  GET_CLIENT: "GET_CLIENT",
  GET_CLIENT_DETAIL: "GET_CLIENT_DETAIL",
  ADD_CLIENT: "ADD_CLIENT",
  DELETE_CLIENT: "DELETE_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  GET_OP_CLIENT: "GET_OP_CLIENT",
  ADD_OP_CLIENT: "ADD_OP_CLIENT",
  DELETE_OP_CLIENT: "DELETE_OP_CLIENT",
  UPDATE_OP_CLIENT: "UPDATE_OP_CLIENT"
};
const COMPANY = {
  GET_COMPANY_INFO: "GET_COMPANY_INFO",
  UPDATE_COMPANY_INFO: "UPDATE_COMPANY_INFO",
  GET_HISTORY: "GET_HISTORY",
  UPDATE_HISTORY: "UPDATE_HISTORY",
  ADD_HISTORY: "ADD_HISTORY",
  DELETE_HISTORY: "DELETE_HISTORY",
  GET_CONTACT: "GET_CONTACT",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  GET_EMPLOYEE: "GET_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
  GET_BROCHURE: "GET_BROCHURE",
  DELETE_BROCHURE: "DELETE_BROCHURE",
  GET_HERO_BANNER: "GET_HERO_BANNER",
  UPDATE_HERO_BANNER: "UPDATE_HERO_BANNER",
  GET_CONTENT: "GET_CONTENT",
  UPDATE_CONTENT: "UPDATE_CONTENT"
};

const SEO = {
  GET_SEO_LIST: "GET_SEO_LIST",
  UPDATE_SEO_LIST: "UPDATE_SEO_LIST"
};

export {
  AUTH,
  POST,
  IMAGE,
  VIDEO,
  CATEGORY,
  PRODUCT,
  CLIENT,
  SEO,
  PARTNER,
  COMPANY,
  PRODUCT_PART
};
