import React from "react";

export default function FBIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__facebook"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <circle
        id="prefix__Ellipse_40"
        cx="22"
        cy="22"
        r="22"
        fill="#fff"
        data-name="Ellipse 40"
      />
      <g
        id="prefix__Group_428"
        data-name="Group 428"
        transform="translate(16.325 11.039)"
      >
        <path
          id="prefix__Path_178"
          fill="#009edf"
          d="M212.468-150.524c-1.109.038-2.22.086-3.33.061a4.064 4.064 0 0 0-4.229 3.925c-.049.929-.066 1.861-.069 2.793 0 .367-.086.541-.5.5s-.82.005-1.227-.018-.6.107-.578.556c.033.67.009 1.344.009 2.017 0 1.532.086 1.606 1.63 1.4.635-.086.636-.086.636.542 0 3.328.007 6.656-.007 9.984 0 .465.145.639.626.627 1.2-.028 2.394-.021 3.59 0 .426.008.585-.141.575-.571-.022-1.032-.008-2.065-.009-3.1v-7.461c.889 0 1.722-.009 2.554.005.354.007.495-.128.513-.484.026-.519.112-1.033.157-1.551.057-.641.1-1.284.147-1.959-1.074 0-2.056-.009-3.037 0-.317.005-.45-.1-.434-.425.018-.393-.025-.789.009-1.18.107-1.194.132-1.782 1.844-1.783h1.689c0-1.151-.016-2.23.008-3.31.013-.432-.14-.582-.567-.568z"
          data-name="Path 178"
          transform="translate(-202.537 150.525)"
        />
      </g>
    </svg>
  );
}
