import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Flags from "country-flag-icons/react/3x2";
// MATERIAL
import { Card, Tooltip } from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
// COMPONENT
import Button from "components/CustomButtons";
import Loader from "components/Admin/Loader";
import history from "history.js";
import { getProduct, deleteProduct } from "actions/admin/product";
import defaultImage from "assets/img/image_placeholder.jpg";
import { TABLE_CONFIG, TOAST_OPTIONS } from "helpers";

class ListProduct extends Component {
  state = {
    loading: true
  };

  btnConfig = {
    justIcon: true,
    simple: true
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  onLoad = async () => {
    await this.props.getProduct();
    this.setState({ loading: false });
  };

  onCreate() {
    history.push("product/create");
  }

  handleDelete = async prop => {
    try {
      await this.props.deleteProduct(prop.id, prop.url_link);
      await this.props.getProduct();
      toast.success("Sản phẩm đã được xóa", TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
  };

  handleEdit = (id, lang) => history.push(`/dashboard/product/${id}/${lang}`);
  renderList = () => {
    const { list } = this.props;
    if (list) {
      return list.map(prop => {
        return {
          logo: (
            <Card>
              <img
                src={prop.thumbnail || defaultImage}
                alt={`logo-${prop.name}`}
                className="img-fluid"
              />
            </Card>
          ),
          product_name: (
            <Button className="btn-title" color="primary" simple>
              {prop["vi"].name}
            </Button>
          ),
          brand_name: (
            <Button className="btn-title" color="warning" simple>
              {prop.brand_name}
            </Button>
          ),
          language: (
            <div className="actions-left">
              <Tooltip title="Tiếng anh">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop.id, "en")}
                >
                  <Flags.US />
                </Button>
              </Tooltip>
              <Tooltip title="Tiếng việt">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop.id, "vi")}
                >
                  <Flags.VN />
                </Button>
              </Tooltip>
            </div>
          ),
          actions: (
            <div className="actions-right">
              <Tooltip title="Xóa sản phẩm">
                <Button
                  {...this.btnConfig}
                  color="danger"
                  onClick={() => this.handleDelete(prop)}
                >
                  <Delete />
                </Button>
              </Tooltip>
            </div>
          )
        };
      });
    }
  };

  render() {
    const columns = [
      { Header: "Logo", accessor: "logo", width: 180 },
      { Header: "Tên sản phẩm", accessor: "product_name", minWidth: 150 },
      { Header: "Tên hãng", accessor: "brand_name", minWidth: 150 },
      { Header: "Ngôn Ngữ", accessor: "language", width: 150 },
      { Header: "Thao tác", accessor: "actions", width: 110 }
    ];
    const { loading } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <div className="mb-2 text-right">
          <Tooltip title="Thêm sản phẩm">
            <Button
              justIconRec
              size="sm"
              color="success"
              onClick={this.onCreate}
            >
              <Add />
            </Button>
          </Tooltip>
        </div>
        <ReactTable
          {...TABLE_CONFIG}
          data={this.renderList()}
          columns={columns}
          loading={loading}
          loadingText={<Loader />}
          defaultPageSize={5}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ product }) => {
  return {
    list: product.list
  };
};

export default connect(
  mapStateToProps,
  {
    getProduct,
    deleteProduct
  }
)(ListProduct);
