import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import { gContents, gPVideo } from "actions/landing";
import Modal from "components/Admin/Modal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import wwaLogo from "assets/img/csp/wwa-logo.jpeg";
import wwaHist from "assets/img/csp/wwa-history.jpg";
import wwaPart from "assets/img/csp/wwa-partner.jpg";
import wwaTeam from "assets/img/csp/wwa-team.jpg";
import vidBullmer from "assets/img/csp/vid-bullmer.jpg";
import vidGraphtec from "assets/img/csp/vid-graphtec.jpg";
import vidOptitex from "assets/img/csp/vid-optitex.jpg";
import vidTdot from "assets/img/csp/vid-tdot.jpg";
import IconHeading from "assets/img/csp/ic-heading.png";
import Player from "components/Admin/Player";
import IntlMessages from "util/IntlMessages";
import FadeInSection from "components/_App/FadeInSection";
const wwaId = "mvzLSS63pnLQENWSLd2H";

class WhoWeAre extends Component {
  state = {
    content: "",
    video: "",
    videoItem: null,
    open: false
  };

  componentDidMount() {
    this.subscribe = this.onLoad();
  }

  componentWillUnmount() {
    this.subscribe = null;
  }

  async onLoad() {
    await this.props.gContents(wwaId);
    await this.props.gPVideo();
    this.setState({
      content: this.props.content,
      video: this.props.video
    });
  }

  openModal = prop => {
    this.setState({ open: true, videoItem: prop });
  };

  closeModal = () => {
    this.setState({ open: false }, () => this.setState({ videoItem: null }));
  };

  onRenderVideo = video => {
    return video.map((prop, key) => {
      let image = this.onRenderImage(key);
      return (
        <GridItem
          xs={12}
          sm={6}
          md={3}
          key={key}
          className={`wwa-item wwa-vid-${key}`}
        >
          <div className="wwa-video" onClick={() => this.openModal(prop)}>
            <img src={image} className="img-fluid" alt={prop.id} />
            <Tooltip title={prop.name.toUpperCase()}>
              <div className="ic-play">
                <PlayArrow />
                <span>{prop.name}</span>
              </div>
            </Tooltip>
          </div>
        </GridItem>
      );
    });
  };

  onRenderImage = key => {
    switch (key) {
      case 0:
        return vidGraphtec;
      case 1:
        return vidTdot;
      case 2:
        return vidOptitex;
      case 3:
        return vidBullmer;
      default:
        return null;
    }
  };

  render() {
    const { content, video, open, videoItem } = this.state;
    const { locale } = this.props;

    if (!content) {
      return null;
    }

    return (
      <FadeInSection>
        <section className="wwa-section">
          <Modal open={open} onClose={this.closeModal} fullWidth>
            {videoItem && (
              <div className="video-showing">
                <Player url={videoItem.url} height={360} playing={false} />
                <GridContainer justify="center">
                  <Button
                    simple
                    size="lg"
                    color="danger"
                    onClick={this.closeModal}
                  >
                    Đóng
                  </Button>
                </GridContainer>
              </div>
            )}
          </Modal>
          <GridContainer>
            <GridItem xs={12} lg={6} className="position-relative">
              <div className="edge-box wwa-text">
                <div className="main-title-wrap rear-left">
                  <h2 className="main-title wwa-title">
                    <span className="icon-heading" />
                    <span>
                      <IntlMessages id="home.wwa.title" />
                    </span>
                  </h2>
                </div>
                <div className="row contact-box_inner" id={wwaId}>
                  <div className="col-md-12 col-lg-10">
                    <p className="text">{content[`content_${locale}`]}</p>
                  </div>
                </div>
                <div className="row contact-box_inner" id={wwaId}>
                  <div className="col-12 ">
                    <Tooltip title="read more">
                      <Link
                        to="/about/company-profile"
                        className="btn-in-link btn-about"
                      >
                        <IntlMessages id="btn.readmore" />
                        <span>
                          <i className="fas fa-angle-right"></i>
                        </span>
                      </Link>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} lg={6}>
              <GridContainer className="wwa-box">
                <GridItem xs={12} sm={6} className="wwa-item wwa-logo">
                  <Link to="/about/company-profile">
                    <WWAThumb src={wwaLogo}>
                      <IntlMessages id="home.wwa.company" />
                    </WWAThumb>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={6} className="wwa-item wwa-history">
                  <Link to="/about/history">
                    <WWAThumb src={wwaHist}>
                      <IntlMessages id="home.wwa.history" />
                    </WWAThumb>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={6} className="wwa-item wwa-partner">
                  <Link to="/about/partners">
                    <WWAThumb src={wwaPart}>
                      <IntlMessages id="home.wwa.partner" />
                    </WWAThumb>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={6} className="wwa-item wwa-team">
                  <Link to="/about/team">
                    <WWAThumb src={wwaTeam}>
                      <IntlMessages id="home.wwa.team" />
                    </WWAThumb>
                  </Link>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer className="wwa-box">
            {video && this.onRenderVideo(video)}
          </GridContainer>
        </section>
      </FadeInSection>
    );
  }
}
const mapStateToProps = ({ landing, settings }) => {
  return {
    content: landing[wwaId],
    locale: settings.locale.locale,
    video: landing.video
  };
};
export default connect(
  mapStateToProps,
  { gContents, gPVideo }
)(WhoWeAre);

const WWAThumb = ({ src, children }) => {
  return (
    <div className="wwa-thumbnail">
      <img src={src} className="wwa-image" alt="wwa-logo" />
      <div className="wwa-thumbnail_overlay">
        <div className="icon-wrap">
          <span>{children}</span>
          <img src={IconHeading} alt="icon" />
        </div>
      </div>
    </div>
  );
};
