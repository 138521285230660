import React, { useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import IntlMessages from "util/IntlMessages";
import Location from "assets/img/csp/ic-location.png";
import PURL from "apis/PublicURL";

export default function RequestDemo() {
  const [mail, setMail] = useState("");
  const locale = useSelector(({ settings }) => settings.locale.locale);

  const handleChange = e => {
    const { value } = e.target;
    setMail(value);
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!mail) {
      swal({
        text: "Xin nhập email để có thể nhận demo",
        icon: "info",
        button: "OK"
      });
      return null;
    }
    return PURL.post("/email/get-demo", { email: mail }).then(() => {
      setMail("");
      swal({
        text: `Email ${mail} đã đăng ký nhận bản mẫu thành công`,
        icon: "success",
        button: "OK"
      });
    });
  };

  return (
    <div className="request-box">
      <div className="csp-container">
        <div className="request-box-inner module-box">
          <div className="request-box-heading">
            <div className="icon-location">
              <img src={Location} alt="location" className="img-fluid" />
            </div>
            <span>
              <IntlMessages id="request.demo.title" />
            </span>
          </div>
          <form className="request-form" onSubmit={onSubmit}>
            <input
              placeholder={locale === "en" ? "Your email" : "Email của bạn"}
              type="email"
              name="mail"
              autoComplete="off"
              onChange={handleChange}
              value={mail}
            />
            <button className="csp-btn csp-main-blue" type="submit">
              <IntlMessages id="btn.subscribe" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
