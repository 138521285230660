import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles, FormControl, Switch } from "@material-ui/core";
import styles from "assets/jss/m-dashboard/customSelectStyle";
import { Close, Add, Save } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import Editor from "components/Admin/Form/Wyswyg";
import { validURL } from "helpers";
import defaultImage from "assets/img/image_placeholder.jpg";

class ClientOPForm extends Component {
  state = {
    logo: "",
    name: "",
    comment: "",
    role: "",
    deputy: "",
    active: false
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  async onLoad() {
    const { initialValues } = this.props;
    if (initialValues) {
      this.onLoadField(initialValues);
    }
  }

  //   ---- ON LOAD FIELD
  onLoadField = prop => {
    this.setState({
      logo: prop.logo,
      name: prop.name,
      comment: prop.comment || "",
      role: prop.role || "",
      deputy: prop.deputy || "",
      active: prop.active || false
    });
  };

  renderEditor = ({ input, dataValue, placeholder }) => {
    return (
      <FormControl fullWidth>
        <Editor
          content={dataValue}
          placeholder={placeholder}
          onChange={editorState => this.props.change(input.name, editorState)}
        />
      </FormControl>
    );
  };

  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  handleSwitch = event => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked }, () => this.props.change(name, checked));
  };

  onSubmit = dataValue => this.props.submitForm(dataValue);
  render() {
    const { name, logo, comment, deputy, role, active } = this.state;
    const { initialValues, handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className="text-center">
              <img
                className="img-fluid"
                src={validURL(logo) ? logo : defaultImage}
                alt="logo"
              />
              <Field
                component={this.renderField}
                name="logo"
                label="ẢNH ĐẠI DIỆN"
                dataValue={logo}
                multiline
              />
            </div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Field
                  component={this.renderField}
                  label="KHÁCH HÀNG"
                  name="name"
                  dataValue={name}
                  required
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Field
                  component={this.renderField}
                  label="ĐẠI DIỆN"
                  name="deputy"
                  dataValue={deputy}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  component={this.renderField}
                  label="CHỨC VỤ"
                  name="role"
                  dataValue={role}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <label>HIỂN THỊ NHẬN XÉT</label>
                <div className="switch-wrap">
                  <Switch
                    checked={active}
                    onChange={this.handleSwitch}
                    name="active"
                  />
                </div>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  component={this.renderEditor}
                  placeholder="NHẬN XÉT KHÁCH HÀNG"
                  name="comment"
                  dataValue={comment}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          <Button
            simple
            color="danger"
            onClick={() => this.props.handleClose()}
          >
            <Close /> Đóng
          </Button>
          {initialValues ? (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Save /> lưu
            </Button>
          ) : (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Add /> tạo
            </Button>
          )}
        </div>
      </form>
    );
  }
}
const styled = withStyles(styles)(ClientOPForm);
export default reduxForm({ form: "baseClientOP" })(styled);
