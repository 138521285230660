import {POST} from "actions/constant";

const initialState = {
    list: ""
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case POST.ADD_POST:
            return {...state};
        
        case POST.GET_POST:
            return { ...state, list: payload};
        
        case POST.GET_POST_DETAIL:
            return {...state, [payload.id]: payload};

        case POST.DELETE_POST:
            return {...state};
            
        case POST.UPDATE_POST:
            return {...state};

        default:
            return state;
    }
};