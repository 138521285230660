import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
// MATERIAL
import { Add, Clear, Save } from "@material-ui/icons";
// COMPONENT
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import defaultImage from "assets/img/image_placeholder.jpg";
import { validURL } from "helpers";

class HRForm extends Component {
  state = {
    img_url: "",
    name: "",
    nickname: "",
    role: "",
    phone: "",
    email: "",
    desc: "",
    checked: false
  };

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues) {
      this.onLoad(initialValues);
    }
  }

  onLoad(prop) {
    this.setState({
      img_url: prop.img_url,
      name: prop.name || "",
      nickname: prop.nickname || "",
      role: prop.role || "",
      phone: prop.phone || "",
      email: prop.email || "",
      desc: prop.desc || "",
      checked: prop.checked || false
    });
  }

  renderField = ({ input, label, dataValue }) => {
    return (
      <GridItem xs={12}>
        <Input
          labelText={label}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            name: input.name,
            onChange: this.handleChange,
            value: dataValue,
            fullWidth: true,
            multiline: true
          }}
        />
      </GridItem>
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  handleChecked = event => {
    const { checked, name } = event.target;
    this.setState({ [name]: checked }, () => this.props.change(name, checked));
  };

  onSubmit = value => {
    this.props.handleSubmitForm(value);
  };

  render() {
    const { img_url, name, role, phone, email, desc, nickname } = this.state;
    const { initialValues } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <img
              src={validURL(img_url) ? img_url : defaultImage}
              alt="info"
              className="img-fluid"
            />
          </GridItem>
          <Field
            component={this.renderField}
            name="img_url"
            dataValue={img_url}
            label="HÌNH ẢNH"
          />
          <Field
            component={this.renderField}
            name="name"
            dataValue={name}
            label="TÊN THÀNH VIÊN"
          />
          <Field
            component={this.renderField}
            name="nickname"
            dataValue={nickname}
            label="NICKNAME"
          />
          <Field
            component={this.renderField}
            name="role"
            dataValue={role}
            label="CHỨC VỤ"
          />
          <Field
            component={this.renderField}
            name="phone"
            dataValue={phone}
            label="ĐIỆN THOẠI"
          />
          <Field
            component={this.renderField}
            name="email"
            dataValue={email}
            label="EMAIL"
          />
          <Field
            component={this.renderField}
            name="desc"
            dataValue={desc}
            label="MÔ TẢ"
          />
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          <Button
            color="primary"
            simple
            onClick={() => this.props.handleClose()}
          >
            <Clear />
            <span className="btn-text">đóng</span>
          </Button>
          {initialValues ? (
            <Button type="submit" color="success" simple>
              <Save />
              <span className="btn-text">Lưu</span>
            </Button>
          ) : (
            <Button type="submit" color="success" simple>
              <Add />
              <span className="btn-text">tạo</span>
            </Button>
          )}
        </div>
      </form>
    );
  }
}
export default reduxForm({ form: "HRForm" })(HRForm);
