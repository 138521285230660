import React, { useState } from "react";
import { useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import swal from "sweetalert";
import PURL from "apis/PublicURL";

export default function RQCBContact() {
  const locale = useSelector(({ settings }) => settings.locale.locale);
  const [inputValues, setInputValues] = useState({
    name: "",
    phone: "",
    messages: ""
  });

  const { name, phone, messages } = inputValues;

  const handleChange = event => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();
    const { name, phone, messages } = inputValues;
    return PURL.post("/email/request", { name, phone, messages }).then(() => {
      setInputValues({
        name: "",
        phone: "",
        messages: ""
      });
      swal({
        text: "Chúng tôi sẽ phản hồi lại yêu cầu của bạn sớm nhất có thể",
        icon: "success",
        button: "OK"
      });
    });
  };

  return (
    <div className="quote-box section-box">
      <div className="csp-container">
        <div className="quote-wrap">
          <GridContainer>
            <GridItem xs={12} md={6}>
              <div className="quote-intro">
                <h4 className="title">
                  <IntlMessages id="contact.request.title" />
                </h4>
                <p className="text">
                  <IntlMessages id="contact.request.text" />
                </p>
              </div>
            </GridItem>
            <GridItem xs={12} md={6}>
              <form className="quote-form" onSubmit={onSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={6} className="form-row">
                    <input
                      placeholder={locale === "en" ? "Full name" : "Họ và tên"}
                      type="text"
                      name="name"
                      autoComplete="off"
                      onChange={handleChange}
                      value={name}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} className="form-row">
                    <input
                      placeholder={
                        locale === "en" ? "Phone Number" : "Số điện thoại"
                      }
                      type="text"
                      name="phone"
                      autoComplete="off"
                      onChange={handleChange}
                      value={phone}
                    />
                  </GridItem>
                  <GridItem xs={12} className="form-row">
                    <textarea
                      placeholder={
                        locale === "en"
                          ? "Your messages & email"
                          : "Email và yêu cầu của bạn"
                      }
                      type="text"
                      name="messages"
                      autoComplete="off"
                      onChange={handleChange}
                      value={messages}
                    />
                  </GridItem>
                  <GridItem xs={12} className="form-row">
                    <button type="submit">
                      <IntlMessages id="button.submit" />
                    </button>
                  </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
