import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import svCall from "assets/img/csp/sv-call.jpg";
import FadeInSection from "components/_App/FadeInSection";
import IntlMessages from "util/IntlMessages";

class SaleService extends Component {
  render() {
    return (
      <FadeInSection>
        <section className="sale-service bg-white">
          <div className="csp-container">
            <div className="blue-heading">
              <p>
                <IntlMessages id="service.customer.title" />
              </p>
              <span>
                <IntlMessages id="service.customer.sub" />
              </span>
            </div>
          </div>
          <div className="service-contact">
            <GridContainer>
              <GridItem xs={12} md={6} className="service-contact-col">
                <img src={svCall} alt="sale" />
              </GridItem>
              <GridItem xs={12} md={6} className="service-contact-col">
                <GridItem xs={12} md={10}>
                  <div className="service-detail">
                    <div className="blue-heading service-detail-heading">
                      <p>
                        <IntlMessages id="service.customer.247" />
                      </p>
                      <span>
                        <IntlMessages id="service.customer.247.sub" />
                      </span>
                    </div>
                    <p className="text">
                      <IntlMessages id="service.customer.247.sub2" />
                    </p>
                    <p className="urgent-phone">+ 84 (0) 911 087 091</p>
                    <Tooltip title="see all contacts">
                      <Link
                        to="/contact"
                        className="btn-in-link btn-to-contact"
                      >
                        <IntlMessages id="service.customer.247.contact" />{" "}
                        <span>
                          <i className="fas fa-angle-right"></i>
                        </span>
                      </Link>
                    </Tooltip>
                  </div>
                </GridItem>
              </GridItem>
            </GridContainer>
          </div>
        </section>
      </FadeInSection>
    );
  }
}
export default SaleService;
