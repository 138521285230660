import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "cogo-toast";
import Loader from "components/Admin/Loader";
import ClientOP from "components/Admin/Form/BaseForm/ClientOP";
import { addClientOP, getOPClient } from "actions/admin/client";
import { TOAST_OPTIONS } from "helpers";

export default function CreateOP(props) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  function onClose() {
    props.handleClose();
  }

  const onSubmit = async dataValue => {
    setLoading(true);
    try {
      await dispatch(addClientOP(dataValue));
      await dispatch(getOPClient());
      toast.success(`Khách hàng được thêm vào thành công`, TOAST_OPTIONS);
      onClose();
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="full-loading">
          <Loader />
        </div>
      )}
      <ClientOP submitForm={onSubmit} handleClose={onClose} />
    </div>
  );
}
