import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import toast from "cogo-toast";
import { Card, Tooltip } from "@material-ui/core";
import Flags from "country-flag-icons/react/3x2";
import { Delete, Visibility, VisibilityOff, Add } from "@material-ui/icons";
import Button from "components/CustomButtons";
import Loader from "components/Admin/Loader";
import history from "history.js";
import defaultImage from "assets/img/image_placeholder.jpg";
import { getClient, deleteClient } from "actions/admin/client";
import { TABLE_CONFIG, TOAST_OPTIONS } from "helpers";
class ListClient extends Component {
  state = {
    loading: true
  };

  btnConfig = {
    justIcon: true,
    simple: true
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  onLoad = async () => {
    await this.props.getClient();
    this.endLoader();
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onCreate = () => {
    history.push("client/create");
  };

  handleDelete = async prop => {
    this.onLoader();
    try {
      await this.props.deleteClient(prop.id);
      await this.props.getClient();
      toast.success(`Khách hàng ${prop.name} đã được xóa`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  handleEdit = (id, lang) => history.push(`/dashboard/client/${id}/${lang}`);

  renderList = () => {
    const { list } = this.props;
    if (list) {
      return list.map(prop => {
        return {
          logo: (
            <Card>
              <img
                src={prop.url || defaultImage}
                alt={`logo-${prop.name}`}
                className="img-fluid"
              />
            </Card>
          ),
          name: (
            <Button color="primary" simple className="btn-title">
              {prop.name}
            </Button>
          ),
          comment: prop.active ? "HIỂN THỊ" : "KHÔNG HIỂN THỊ",
          status:
            prop.status === "active" ? (
              <Tooltip title="Hiển thị">
                <Button color="success" justIcon simple>
                  <Visibility />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Không hiển thị">
                <Button color="danger" justIcon simple>
                  <VisibilityOff />
                </Button>
              </Tooltip>
            ),
          language: (
            <div className="actions-left">
              <Tooltip title="Tiếng anh">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop.id, "en")}
                >
                  <Flags.US />
                </Button>
              </Tooltip>
              <Tooltip title="Tiếng việt">
                <Button
                  {...this.btnConfig}
                  onClick={() => this.handleEdit(prop.id, "vi")}
                >
                  <Flags.VN />
                </Button>
              </Tooltip>
            </div>
          ),
          actions: (
            <div className="actions-right">
              <Tooltip title="Xóa khách hàng">
                <Button
                  {...this.btnConfig}
                  color="danger"
                  onClick={() => this.handleDelete(prop)}
                >
                  <Delete />
                </Button>
              </Tooltip>
            </div>
          )
        };
      });
    }
  };

  render() {
    const columns = [
      { Header: "Logo", accessor: "logo", width: 180 },
      { Header: "Tên khách hàng", accessor: "name", minWidth: 150 },
      { Header: "NHẬN XÉT", accessor: "comment", width: 160 },
      { Header: "Trạng thái", accessor: "status", width: 160 },
      { Header: "ngôn ngữ", accessor: "language", width: 160 },
      { Header: "Thao tác", accessor: "actions", width: 110 }
    ];
    const { loading } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <div className="mb-2 text-right">
          <Tooltip title="Thêm khách hàng">
            <Button
              justIconRec
              size="sm"
              color="success"
              onClick={this.onCreate}
            >
              <Add />
            </Button>
          </Tooltip>
        </div>
        <ReactTable
          {...TABLE_CONFIG}
          data={this.renderList()}
          defaultPageSize={10}
          columns={columns}
          loading={loading}
          loadingText={<Loader />}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ client }) => {
  return {
    list: client.list
  };
};

export default connect(
  mapStateToProps,
  {
    getClient,
    deleteClient
  }
)(ListClient);
