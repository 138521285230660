import { PRODUCT } from "actions/constant";

const initialState = {
  list: "",
  op_list: "",
  cate_list: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // ===== PRODUCT =====
    case PRODUCT.ADD_PRODUCT:
      return { ...state };

    case PRODUCT.GET_PRODUCT:
      return { ...state, list: payload };

    case PRODUCT.GET_PRODUCT_DETAIL:
      return { ...state, [payload.id]: payload };

    case PRODUCT.DELETE_PRODUCT:
      return { ...state };

    case PRODUCT.UPDATE_PRODUCT:
      return { ...state };

    // ==== OPTITEX ====
    // == CATEGORY ==
    case PRODUCT.GET_OP_CATE:
      return { ...state, cate_list: payload };

    case PRODUCT.ADD_OP_CATE:
      return { ...state };

    case PRODUCT.DELETE_OP_CATE:
      return { ...state };

    case PRODUCT.UPDATE_OP_CATE:
      return { ...state };

    // == PRODUCT ==
    case PRODUCT.GET_OP_PRODUCT:
      return { ...state, op_list: payload };

    case PRODUCT.ADD_OP_PRODUCT:
      return { ...state };

    case PRODUCT.DELETE_OP_PRODUCT:
      return { ...state };

    case PRODUCT.UPDATE_OP_PRODUCT:
      return { ...state };

    default:
      return state;
  }
};
