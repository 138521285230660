import React, { Component } from "react";
import ADMIN_URL from "apis/AdminURL";
import toast from "cogo-toast";
import Loader from "components/Admin/Loader";
import Form from "./Form";
import { TOAST_OPTIONS } from "helpers";

class AboutUsEnForm extends Component {
  state = {
    loading: true,
    detail: "",
    lang: "en"
  };

  async componentDidMount() {
    this.onCall = this.onLoad();
  }

  componentWillUnmount() {
    this.onCall = null;
  }
  async onLoad() {
    const { lang } = this.state;
    const response = await ADMIN_URL.get(`/company/info/${lang}`);
    this.setState({ detail: response.data, loading: false });
  }

  updateInfo = (value, lang) => {
    return ADMIN_URL.put(`/company/info/${lang}`, value);
  };

  onSubmit = async value => {
    const { lang } = this.state;
    this.setState({ loading: true });
    try {
      await this.updateInfo(value, lang);
      this.onLoad();
      toast.success(`Thông tin công ty đã được cập nhật`, TOAST_OPTIONS);
    } catch (error) {
      this.setState({ loading: false });
      toast.error(error.message, TOAST_OPTIONS);
    }
  };

  render() {
    const { loading, detail } = this.state;

    if (!detail) {
      return (
        <div className="full-loading">
          <Loader />
        </div>
      );
    }

    return (
      <div>
        {loading ? (
          <div className="full-loading">
            <Loader />
          </div>
        ) : null}
        <Form handleSubmitForm={this.onSubmit} initialValues={detail} />
      </div>
    );
  }
}
export default AboutUsEnForm;
