import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toast from "cogo-toast";
import { TOAST_OPTIONS } from "helpers";
import { getContent, updateContent } from "actions/admin/company";
import CardBody from "components/Card/CardBody";
import LineScaleLoader from "components/Admin/Loader";
import ContentForm from "components/Admin/Form/BaseForm/Content";
// WHO WE ARE - HOME ID
const WWA_ID = "mvzLSS63pnLQENWSLd2H";

export default function Wwa() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const detail = useSelector(({ company }) => company[WWA_ID]);

  useEffect(() => {
    async function onFetch() {
      dispatch(getContent(WWA_ID));
    }
    onFetch();
  }, [dispatch]);

  const onSubmit = async value => {
    setLoading(true);
    try {
      await dispatch(updateContent(WWA_ID, value));
      await dispatch(getContent(WWA_ID));
      toast.success(`Nội dung đã được cập nhật`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    setLoading(false);
  };

  if (!detail) {
    return (
      <CardBody className="p-5">
        <div className="full-loading">
          <LineScaleLoader />
        </div>
      </CardBody>
    );
  }

  return (
    <div id={WWA_ID} style={{ width: "100%" }}>
      {loading ? (
        <div className="full-loading">
          <LineScaleLoader />
        </div>
      ) : null}
      <ContentForm
        label="NỘI DUNG (EN)"
        nameInput="content_en"
        initialValues={detail}
        onSubmit={onSubmit}
      />
      <ContentForm
        label="NỘI DUNG (VI)"
        nameInput="content_vi"
        initialValues={detail}
        onSubmit={onSubmit}
      />
    </div>
  );
}
