import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Link } from "react-router-dom";
import { IntlProvider } from "react-intl";
import IntlMessages from "util/IntlMessages";
import Header from "components/landing/Header";
import Footer from "components/landing/Footer";
import AppLocale from "lngProvider";
import NewsAndEvents from "pages/Landing/NewsAndEvents";
import aboutOverlay from "assets/img/csp/about-overlay.png";
import neBg from "assets/img/csp/ne-bg.jpg";
import { Breadcrumbs } from "@material-ui/core";

class NaELayout extends Component {
  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <div className="landing-page">
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Header />
          <div
            className="about-banner bg-section"
            style={{ backgroundImage: `url(${neBg})` }}
          >
            <img
              src={aboutOverlay}
              alt="bg_overlay"
              className="banner-overlay"
            />
            <div className="csp-container">
              <div className="about-banner-inner">
                <div className="about-content">
                  <h2 className="title">
                    <IntlMessages id="home.menu.newsevent" />
                  </h2>
                  <p className="sub-title">
                    <IntlMessages id="newsevent.subtext" />
                  </p>
                </div>
              </div>
              <div className="nav-heading">
                <Breadcrumbs aria-label="breadcrumb" separator=".">
                  <Link to="/" className="nav-heading-menu">
                    <IntlMessages id="home.menu.index" />
                  </Link>
                  <div className="nav-heading-breadcrumb">
                    <IntlMessages id="home.menu.newsevent" />
                  </div>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <Switch>
            <Route path="/" component={NewsAndEvents} />
          </Switch>
          <Footer />
        </IntlProvider>
      </div>
    );
  }
}
const mapStateToProps = ({ settings }) => {
  return {
    locale: settings.locale
  };
};
export default connect(mapStateToProps)(NaELayout);
