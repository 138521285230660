import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Banner from "components/Admin/Form/BaseForm/Banner";
import CardBody from "components/Card/CardBody";
import Loader from "components/Admin/Loader";
import { TOAST_OPTIONS } from "helpers";
import { getHeroBanner, updateHeroBanner } from "actions/admin/company";

class HeroBanner extends Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    await this.props.getHeroBanner();
    this.setState({ loading: false });
  }

  onSubmit = value => {
    this.setState({ loading: true });
    this.props
      .updateHeroBanner(value)
      .then(() => {
        this.setState({ loading: false });
        this.props.getHeroBanner();
        toast.success(
          `Thông tin ảnh đại diện trang chủ đã được cập nhật`,
          TOAST_OPTIONS
        );
      })
      .catch(err => {
        this.setState({ loading: false });
        toast.error(err.message, TOAST_OPTIONS);
      });
  };

  render() {
    const { loading } = this.state;
    const { detail } = this.props;

    if (!detail) {
      return (
        <CardBody className="p-5">
          <div className="full-loading">
            <Loader />
          </div>
        </CardBody>
      );
    }

    return (
      <CardBody>
        {loading ? (
          <div className="full-loading">
            <Loader />
          </div>
        ) : null}
        <Banner
          handleSubmitForm={this.onSubmit}
          initialValues={this.props.detail}
        />
      </CardBody>
    );
  }
}
const mapStateToProps = ({ company }) => {
  return {
    detail: company.banner
  };
};
export default connect(
  mapStateToProps,
  {
    getHeroBanner,
    updateHeroBanner
  }
)(HeroBanner);
