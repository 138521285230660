import React, { Component } from "react";
import { connect } from "react-redux";
// Material
import { withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/m-dashboard/views/loginPageStyle";
// COMPONENT
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/index.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import logo from "assets/img/sd-logo.png";
import { onSignIn } from "actions/admin/auth";
class LoginPage extends Component {
  state = {
    cardAnimation: "cardHidden"
  };

  componentDidMount() {
    this.timeOutFunction = setTimeout(() => {
      this.setState({ cardAnimation: "" });
    }, 700);
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  onSignIn = () => this.props.onSignIn();

  render() {
    const { classes } = this.props;
    const { cardAnimation } = this.state;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card login className={classes[cardAnimation]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <img src={logo} alt="logo" style={{ maxWidth: 80 }} />
              </CardHeader>
              <CardBody>
                <div className="text-center">
                  <Button color="google" onClick={this.onSignIn}>
                    <span>
                      <i className="fab fa-google"></i>
                    </span>
                    <span className="mt-1">Đăng nhập với Google</span>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default connect(
  null,
  { onSignIn }
)(withStyles(styles)(LoginPage));
