import { dangerColor, whiteColor } from "assets/jss/color";
// const drawerWidth = 240;
const topBarStyles = theme => ({
  appBar: {
    // [theme.breakpoints.up("lg")]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   marginLeft: drawerWidth
    // }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up("lg")]: {
    //   display: "none"
    // }
  },
  fontBar: {
    color: "#fff",
    textTransform: "uppercase"
  },
  root: {
    position: "relative"
  },
  btnLogout: {
    position: "absolute",
    margin: 0,
    right: 16,
    "&:hover": {
      backgroundColor: whiteColor,
      color: dangerColor[0]
    },
    [theme.breakpoints.up("md")]: {
      right: 24
    }
  }
});

export default topBarStyles;
