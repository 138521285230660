import React, { PureComponent } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { gContents } from "actions/landing";
import opGirl from "assets/img/csp/optitex-girl.png";
import Loader from "components/Admin/Loader";
import hist from "history.js";
import FadeInSection from "components/_App/FadeInSection";

const OptitexWWAId = "GwbIJpbQCD3gMsaOBsG6";

class WhoWeAre extends PureComponent {
  state = {
    content: ""
  };

  componentDidMount() {
    this.onCall = this.onLoad();
  }

  componentWillUnmount() {
    this.onCall = null;
  }

  async onLoad() {
    await this.props.gContents(OptitexWWAId);
    this.setState({ content: this.props.content });
  }
  render() {
    const { content } = this.state;
    const { locale } = this.props;
    if (!content) {
      return (
        <div className="page-loading">
          <Loader />
        </div>
      );
    }
    return (
      <FadeInSection>
        <section className="wwa-optitex">
          <div className="csp-container">
            <GridContainer justify="center">
              <GridItem md={12} lg={10}>
                <GridContainer>
                  <GridItem xs={12} md={7}>
                    <div className="wwa-op-wrapper">
                      <div className="blue-heading">
                        <p>
                          <IntlMessages id="optitex.whoweare.title" />
                        </p>
                        <span>
                          <IntlMessages id="optitex.whoweare.sub" />
                        </span>
                      </div>
                      <p className="wwa-optitex-content">
                        {content[`content_${locale}`]}
                      </p>
                      <button
                        className="csp-btn csp-main-blue"
                        onClick={() => hist.push("/contact")}
                      >
                        <IntlMessages id="home.banner.contact" />
                      </button>
                    </div>
                  </GridItem>
                  <GridItem xs={12} md={5}>
                    <img
                      src={opGirl}
                      alt="optitex-girl"
                      className="img-fluid wwa-op-image"
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </section>
      </FadeInSection>
    );
  }
}
const mapStateToProps = ({ settings, landing }) => {
  return {
    locale: settings.locale.locale,
    content: landing[OptitexWWAId]
  };
};

export default connect(
  mapStateToProps,
  { gContents }
)(WhoWeAre);
