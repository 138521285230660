import { POST } from "../constant";
import ADMIN_URL from "apis/AdminURL";
//Post
export const getPost = () => async dispatch => {
  const response = await ADMIN_URL.get("/post/all");
  dispatch({
    type: POST.GET_POST,
    payload: response.data
  });
};
export const addPost = dataValue => async dispatch => {
  await ADMIN_URL.post("/post/add", dataValue);
  dispatch({ type: POST.ADD_POST });
};
export const deletePost = id => async dispatch => {
  await ADMIN_URL.delete(`/post/${id}`);
  dispatch({ type: POST.DELETE_POST });
};
export const updatePost = (id, lang, dataValue) => async dispatch => {
  await ADMIN_URL.put(`/post/${id}/${lang}`, dataValue);
  dispatch({ type: POST.UPDATE_POST });
};
export const getPostDetail = (id, lang) => async dispatch => {
  const response = await ADMIN_URL.get(`/post/${id}/${lang}`);
  dispatch({
    type: POST.GET_POST_DETAIL,
    payload: response.data
  });
};
