import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PURL from "apis/PublicURL";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Phone, Mail } from "@material-ui/icons";
import TopImage from "assets/img/csp/team-top.jpg";
import IconHeading from "assets/img/csp/ic-heading.png";
import IntlMessages from "util/IntlMessages";

export default function ListTop() {
  const [list, setList] = useState("");
  const locale = useSelector(({ settings }) => settings.locale.locale);

  useEffect(() => {
    async function onFetch() {
      try {
        const res = await PURL.get(`/company/employee-top`);
        setList(res.data);
      } catch (error) {
        console.log("Failed to fetch list", error.message);
      }
    }
    onFetch();
  }, []);

  return (
    <section>
      <GridContainer>
        <GridItem xs={12}>
          <div
            className="about-team bg-section"
            style={{ backgroundImage: `url("${TopImage}")` }}
          >
            <div className="main-title-wrap">
              <h2 className="main-title">
                <img
                  src={IconHeading}
                  className="ic-heading"
                  alt="icon-heading"
                />
                <span>
                  <IntlMessages id="about.team.top.title" />
                </span>
              </h2>
              <p className="sub-title">
                <IntlMessages id="about.team.top.sub" />
              </p>
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {list &&
          list.map(item => (
            <GridItem xs={12} key={item.id} className="team-item">
              <div className="team-item-inner">
                <div className="image">
                  <img src={item.img_url} className="img-fluid" alt={item.id} />
                </div>
                <div className="detail">
                  <div className="detail-inner">
                    <div className="name">{item[locale].name}</div>
                    <div className="role">{item[locale].role}</div>
                    <div className="desc">{item[locale].desc}</div>
                    <div className="info">
                      <div className="phone">
                        <Phone />
                        {item.phone}
                      </div>
                      <div className="email">
                        <Mail />
                        {item.email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          ))}
      </GridContainer>
    </section>
  );
}
