import React, { Component, Fragment } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import IconHeading from "assets/img/csp/ic-heading.png";
import Slider from "react-slick";
import Button from "components/CustomButtons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      current: ""
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  handleClickImage = image => {
    this.setState({
      current: image
    });
  };

  handleCloseModal = () => {
    this.setState({ current: "" });
  };

  renderImageNav = list => {
    return list.map((img, key) => {
      return (
        <div key={key} className="slick-img">
          <div
            className="preview-thumbnail"
            style={{ backgroundImage: `url(${img})` }}
          />
        </div>
      );
    });
  };

  renderImageList = list => {
    return list.map((img, key) => {
      return (
        <div key={key} className="slick-img">
          <div
            className="preview-thumbnail"
            style={{ backgroundImage: `url(${img})` }}
            onClick={() => this.handleClickImage(img)}
          />
        </div>
      );
    });
  };

  render() {
    const { title, desc, imageList } = this.props;
    const { current, nav1, nav2 } = this.state;

    const nav = {
      asNavFor: nav1,
      ref: slider => {
        this.slider2 = slider;
      },
      slidesToShow: 5,
      swipeToSlide: true,
      focusOnSelect: true,
      infinite: false,
      arrows: false,
      speed: 700
    };

    const view = {
      asNavFor: nav2,
      ref: slider => {
        this.slider1 = slider;
      },
      speed: 700,
      arrows: true,
      infinite: false,
      nextArrow: (
        <Button>
          <i className="fas fa-angle-right"></i>
        </Button>
      ),
      prevArrow: (
        <Button>
          <i className="fas fa-angle-left"></i>
        </Button>
      )
    };

    return (
      <GridItem xs={12} className="post-box">
        {current && (
          <Lightbox mainSrc={current} onCloseRequest={this.handleCloseModal} />
        )}
        <GridContainer>
          <GridItem xs={12} sm={6} className="post-image">
            {imageList && (
              <Fragment>
                <Slider {...view} className="custom-slick-thumbnail">
                  {this.renderImageList(imageList)}
                </Slider>
                <Slider {...nav} className="custom-slick-nav">
                  {this.renderImageNav(imageList)}
                </Slider>
              </Fragment>
            )}
          </GridItem>
          <GridItem xs={12} sm={6} className="post-content">
            <div className="blog-title-wrap">
              <h2 className="blog-title">
                <img
                  src={IconHeading}
                  className="ic-heading"
                  alt="icon-heading"
                />
                <span>{title && title}</span>
              </h2>
            </div>
            <div className="post-desc">{desc && desc}</div>
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  }
}
