import React, { useState, useEffect } from "react";
import PURL from "apis/PublicURL";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip, Breadcrumbs } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";
import svBg from "assets/img/csp/sv-bg.png";
import FadeInSection from "components/_App/FadeInSection";
import Loader from "components/Admin/Loader";
// BANNER_SERVICE - HOME ID
const BANNER_SERVICE_ID = "ky3NuABPeLXB19BMskMd";

export default function ServiceBanner() {
  const [detail, setDetail] = useState("");
  const locale = useSelector(({ settings }) => settings.locale.locale);

  useEffect(() => {
    async function onFetch() {
      const response = await PURL.get(`/company/contents/${BANNER_SERVICE_ID}`);
      setDetail(response.data);
    }
    onFetch();
  }, []);

  if (!detail) {
    return (
      <div className="page-loading">
        <Loader />
      </div>
    );
  }
  return (
    <FadeInSection>
      <section className="service-banner">
        <div style={{ backgroundImage: `url(${svBg})` }} className="bg-section">
          <div className="csp-container">
            <div className="service-title-wrap">
              <div className="banner-wrap">
                <div className="banner-wrap-inner">
                  <div className="banner-detail">
                    <div className="banner-detail-head">
                      <h2 className="service-title">
                        <IntlMessages id="service.banner.title" />
                      </h2>
                      <span className="sub-text">
                        <IntlMessages id="service.banner.sub" />
                      </span>
                    </div>
                    <div className="banner-content">
                      {detail[`content_${locale}`]}
                    </div>
                    <Tooltip title={<IntlMessages id="btn.readmore" />}>
                      <Link
                        to="/about/history"
                        className="btn-in-link btn-to-contact"
                      >
                        <IntlMessages id="btn.readmore" />{" "}
                        <span>
                          <i className="fas fa-angle-right"></i>
                        </span>
                      </Link>
                    </Tooltip>
                  </div>
                </div>
                <div className="banner-img">
                  <img
                    src={detail.url}
                    className="img-fluid"
                    alt="service-banner"
                  />
                </div>
              </div>
              <div className="nav-heading">
                <Breadcrumbs aria-label="breadcrumb" separator=".">
                  <Link to="/" className="nav-heading-menu">
                    <IntlMessages id="home.menu.index" />
                  </Link>
                  <div className="nav-heading-breadcrumb">
                    <IntlMessages id="home.menu.service" />
                  </div>
                </Breadcrumbs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}
