import React, { Component } from "react";
// MATERIAL
import { Assignment, Panorama } from "@material-ui/icons";
// COMPONENT
import NavPills from "components/NavPills/NavPills";
import Content from "./component/Content";
import Brochure from "./component/Brochure";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
class HR extends Component {
  render() {
    return (
      <NavPills
        color="primary"
        tabs={[
          {
            tabButton: "Nội Dung",
            tabIcon: Assignment,
            tabContent: (
              <Card>
                <CardBody>
                  <Content />
                </CardBody>
              </Card>
            )
          },
          {
            tabButton: "Brochure",
            tabIcon: Panorama,
            tabContent: <Brochure />
          }
        ]}
      />
    );
  }
}

export default HR;
