import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Loader from "components/Admin/Loader";
import { TOAST_OPTIONS } from "helpers";
import ContentForm from "components/Admin/Form/BaseForm/Content";
import ContentWImg from "components/Admin/Form/BaseForm/ContentWImg";
import { getContent, updateContent } from "actions/admin/company";

const editableId = "KMdMPB9RnLZOEI531z6Q";

class Info extends Component {
  state = {
    loading: true,
    isEdit: true,
    content: ""
  };

  componentDidMount() {
    this.onLoad();
  }

  async onLoad() {
    await this.props.getContent(editableId);
    this.setState({ loading: false, content: this.props[editableId] });
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleBack = () => this.setState({ isEdit: true });
  handleEdit = () => this.setState({ isEdit: false });

  onSubmit = async value => {
    this.setState({ loading: true });
    try {
      await this.props.updateContent(editableId, value);
      this.onLoad();
      toast.success(`Nội dung đã được cập nhật`, TOAST_OPTIONS);
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.setState({ loading: false });
  };
  render() {
    const { loading, content } = this.state;
    return (
      <Fragment>
        {loading ? (
          <div className="full-loading">
            <Loader />
          </div>
        ) : null}
        {content ? (
          <div id={editableId} style={{ width: "100%" }}>
            <ContentWImg
              label="HÌNH ẢNH"
              nameInput="logo"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="Nội dung (VI)"
              nameInput="content_vi"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="Nội dung (EN)"
              nameInput="content_en"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="SLOGAN (VI)"
              nameInput="slogan_vi"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="SLOGAN (EN)"
              nameInput="slogan_en"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="MÔ TẢ (VI)"
              nameInput="desc_vi"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="MÔ TẢ (EN)"
              nameInput="desc_en"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
            <ContentForm
              label="URL"
              nameInput="url"
              initialValues={content}
              onSubmit={this.onSubmit}
            />
          </div>
        ) : (
          <div className="m-3" />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ company }) => {
  return {
    [editableId]: company[editableId]
  };
};
export default connect(
  mapStateToProps,
  { getContent, updateContent }
)(Info);
