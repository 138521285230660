import { COMPANY } from "actions/landing/type";

const initialState = {
  banner: "",
  contact: "",
  client: "",
  comments: "",
  product: "",
  partner: "",
  video: "",
  news: "",
  events: "",
  cate_op: ""
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // DISPLAY BANNER
    case COMPANY.PUBLIC_HERO_BANNER:
      return { ...state, banner: payload };
    // DISPLAY CONTACT
    case COMPANY.PUBLIC_CONTACT:
      return { ...state, contact: payload };
    // DISPLAY CLIENT
    case COMPANY.PUBLIC_CLIENT:
      return { ...state, client: payload };
    // DISPLAY COMMENT
    case COMPANY.PUBLIC_COMMENTS:
      return { ...state, comments: payload };
    // DISPLAY PARTNER
    case COMPANY.PUBLIC_PARTNER:
      return { ...state, partner: payload };
    // DISPLAY PRODUCT
    case COMPANY.PUBLIC_PRODUCT:
      return { ...state, [`product-${action.brand_id}`]: payload };
    // DISPLAY CONTENT
    case COMPANY.PUBLIC_CONTENT:
      return { ...state, [payload.id]: payload };
    // DISPLAY VIDEO
    case COMPANY.PUBLIC_VIDEOS:
      return { ...state, video: payload.reverse() };

    case COMPANY.PUBLIC_NEWS:
      return { ...state, news: payload };

    case COMPANY.PUBLIC_EVENTS:
      return { ...state, events: payload };

    case COMPANY.PUBLIC_OPTITEX_CATEGORY:
      return { ...state, cate_op: payload.reverse() };

    case COMPANY.PUBLIC_OPTITEX_CATEGORY_DETAIL:
      return { ...state, [payload.id]: payload };

    default:
      return state;
  }
};
