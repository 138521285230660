import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import {
  withStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Switch
} from "@material-ui/core";
import styles from "assets/jss/m-dashboard/customSelectStyle";
import { Save, Add, KeyboardBackspace as Back } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import Editor from "components/Admin/Form/Wyswyg";
import history from "history.js";
import defaultImage from "assets/img/image_placeholder.jpg";
import { validURL } from "helpers";

class ClientForm extends Component {
  state = {
    url: "",
    name: "",
    status: "inactive",
    comment: "",
    role: "",
    deputy: "",
    active: false
  };

  status = [
    { name: "Ẩn", value: "inactive" },
    { name: "Hiển thị", value: "active" }
  ];

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  onLoad = async () => {
    const { initialValues } = this.props;
    if (initialValues) {
      this.onLoadField(initialValues);
    }
  };

  //   ---- ON LOAD FIELD
  onLoadField = prop => {
    this.setState({
      url: prop.url,
      name: prop.name,
      status: prop.status || "inactive",
      comment: prop.comment || "",
      role: prop.role || "",
      deputy: prop.deputy || "",
      active: prop.active || false
    });
  };

  renderEditor = ({ input, dataValue, placeholder }) => {
    return (
      <FormControl fullWidth>
        <Editor
          content={dataValue}
          placeholder={placeholder}
          onChange={editorState => this.props.change(input.name, editorState)}
        />
      </FormControl>
    );
  };

  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };

  renderSelect = ({ input, label, children, dataValue }) => {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          inputProps={{ name: input.name, value: dataValue }}
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          onChange={this.handleChange}
        >
          {children}
        </Select>
      </FormControl>
    );
  };

  renderStatus = () => {
    const { classes } = this.props;
    return this.status.map((prop, key) => (
      <MenuItem
        key={key}
        value={prop.value}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
      >
        {prop.name}
      </MenuItem>
    ));
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  handleSwitch = event => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked }, () => this.props.change(name, checked));
  };

  onSubmit = dataValue => this.props.submitForm(dataValue);
  onBack = () => history.goBack();
  render() {
    const { name, status, url, comment, deputy, role, active } = this.state;
    const { initialValues, handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className="text-center">
              <img
                className="img-fluid"
                src={validURL(url) ? url : defaultImage}
                alt="logo"
              />
              <Field
                component={this.renderField}
                name="url"
                label="ẢNH ĐẠI DIỆN"
                dataValue={url}
                multiline
              />
            </div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Field
                  component={this.renderField}
                  label="KHÁCH HÀNG"
                  name="name"
                  dataValue={name}
                  required
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Field
                  component={this.renderField}
                  label="ĐẠI DIỆN"
                  name="deputy"
                  dataValue={deputy}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Field
                  component={this.renderField}
                  label="CHỨC VỤ"
                  name="role"
                  dataValue={role}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <Field
                  component={this.renderSelect}
                  name="status"
                  label="Trạng thái"
                  dataValue={status}
                >
                  {this.renderStatus()}
                </Field>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <label>HIỂN THỊ NHẬN XÉT</label>
                <div className="switch-wrap">
                  <Switch
                    checked={active}
                    onChange={this.handleSwitch}
                    name="active"
                  />
                </div>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  component={this.renderEditor}
                  placeholder="NHẬN XÉT KHÁCH HÀNG"
                  name="comment"
                  dataValue={comment}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          <Button color="warning" simple onClick={this.onBack}>
            <Back />
            <span className="btn-text">quay lại</span>
          </Button>
          {initialValues ? (
            <Button
              type="submit"
              simple
              color="success"
              disabled={pristine && true}
            >
              <Save />
              <span className="btn-text">Lưu</span>
            </Button>
          ) : (
            <Button
              type="submit"
              simple
              color="success"
              disabled={pristine && true}
            >
              <Add />
              <span className="btn-text">tạo</span>
            </Button>
          )}
        </div>
      </form>
    );
  }
}
const styled = withStyles(styles)(ClientForm);
export default reduxForm({ form: "baseClient" })(styled);
