import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { Save, Edit, KeyboardBackspace } from "@material-ui/icons";
import _ from "lodash";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons";

class BaseSEOForm extends Component {
  state = {
    isEdit: true,
    title: "",
    meta_desc: "",
    meta_key: ""
  };

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues) {
      this.onLoad(initialValues);
    }
  }

  onLoad(prop) {
    this.setState({
      title: prop.title || "",
      meta_desc: prop.meta_desc || "",
      meta_key: prop.meta_key || ""
    });
  }

  renderField = ({ input, dataValue, label, disabled }) => {
    return (
      <Input
        labelText={label}
        formControlProps={{ fullWidth: true }}
        inputProps={{
          name: input.name,
          value: dataValue,
          onChange: this.handleChange,
          fullWidth: true,
          multiline: true,
          disabled
        }}
      />
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  handleBack = () => this.setState({ isEdit: true });
  handleEdit = () => this.setState({ isEdit: false });

  onSubmit = dataValue => {
    const data = _.omit(dataValue, ["id", "index"]);
    this.props.submitForm(data);
    this.handleBack();
  };

  render() {
    const { isEdit, title, meta_desc, meta_key } = this.state;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              label="TIÊU ĐỀ"
              name="title"
              dataValue={title}
              disabled={isEdit}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              label="META DESCRIPTION"
              name="meta_desc"
              dataValue={meta_desc}
              disabled={isEdit}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              label="META KEYWORD"
              name="meta_key"
              dataValue={meta_key}
              disabled={isEdit}
            />
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          {!isEdit ? (
            <Fragment>
              <Button color="warning" simple onClick={this.handleBack}>
                <KeyboardBackspace />
                <span className="btn-text">Quay lại</span>
              </Button>
              <Button color="success" simple type="submit">
                <Save />
                <span className="btn-text">Lưu</span>
              </Button>
            </Fragment>
          ) : (
            <Button color="primary" simple onClick={this.handleEdit}>
              <Edit />
              <span className="btn-text">Chỉnh sửa</span>
            </Button>
          )}
        </div>
      </form>
    );
  }
}

export default reduxForm({ form: "BaseSEOForm" })(BaseSEOForm);
