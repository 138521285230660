import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
// Material
import { withStyles } from "@material-ui/core/styles";
import sideBarStyles from "assets/jss/components/sideBarStyles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
// import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Text from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      miniActive: true,
      ...this.getCollapseStates(props.routes)
    };
  }
  toggleDrawer = () => {
    this.props.toggleDrawer();
  };
  getDrawer = () => {
    const { classes, userInfo, routes } = this.props;
    return (
      <div className="sidebar-drawer">
        <div className={classes.toolbar}>
          <Text className={classes.labelText}>
            Welcome {userInfo.claims.email}
          </Text>
        </div>
        <Divider />
        <List className={classes.list}>{this.getDrawerLink(routes)}</List>
      </div>
    );
  };
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  getDrawerLink = routes => {
    const { classes } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect || prop.hide || prop.layout !== "/dashboard") {
        return null;
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses = `${classes.itemLink} ${cx({
          [classes.collapseActive]: this.getCollapseInitialState(prop.views)
        })}`;
        const itemText = `${classes.itemText} ${cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
        })}`;
        const collapseItemText = `${classes.collapseItemText} ${cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive
        })}`;
        const caret = classes.caret;
        const secondary = (
          <b
            className={`${caret} ${
              this.state[prop.state] ? classes.caretActive : ""
            }`}
          />
        );
        const onClick = e => {
          e.preventDefault();
          this.setState(st);
        };
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink className={navLinkClasses} to="#" onClick={onClick}>
              <ListItemText
                primary={prop.name}
                secondary={secondary}
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                {this.getDrawerLink(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses = classes.collapseItemLink;
      const navLinkClasses = classes.itemLink;
      const itemText = `${classes.itemText} ${cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
      })}`;
      const collapseItemText = `${classes.collapseItemText} ${cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive
      })}`;
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName={classes.active}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            <ListItemText
              primary={prop.name}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };

  render() {
    const { classes, mobileOpen } = this.props;
    return (
      <nav className={classes.drawer} aria-label="sidebar">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={this.toggleDrawer}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {this.getDrawer()}
        </Drawer>
        {/* <Hidden lgUp implementation="css">
        </Hidden> */}
        {/* <Hidden mdDown implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            {this.getDrawer()}
          </Drawer>
        </Hidden> */}
      </nav>
    );
  }
}

export default withStyles(sideBarStyles)(SideBar);
