import React, { useEffect, useState } from "react";
import PURL from "apis/PublicURL";
import Banner from "./Banner";
import WhoWeAre from "./WhoWeAre";
import Widget from "./Widget";
import List from "./List";
import Client from "./Client";
import Design from "./Design";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";

// SEO OPTITEX MAIN LIST
const SEO_ID = "PvVZRtX9i2YSoIH9WwNA";

export default function OptitexMain() {
  const [detail, setDetail] = useState("");
  useEffect(() => {
    async function onFetch() {
      try {
        const res = await PURL.get(`company/seo/${SEO_ID}`);
        setDetail(res.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    onFetch();
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <section className="optitex-main">
      {detail && (
        <CustomHelmet
          detail={detail}
          imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/gallery%2Foptitex.png?alt=media&token=c78242a1-92b6-4848-8dc0-0f1cdf677258"
        />
      )}
      <Banner />
      <WhoWeAre />
      <Widget />
      <List />
      <Client />
      <Design />
    </section>
  );
}
