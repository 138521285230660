import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
import _ from "lodash";
// Material
import Save from "@material-ui/icons/Save";
import Edit from "@material-ui/icons/Edit";
import Back from "@material-ui/icons/KeyboardBackspace";
// Component
import Input from "components/Admin/Form/Input";
import Button from "components/CustomButtons";
import AutoComplete from "components/Admin/Form/AutoComplete";
import { updateContact, getContact } from "actions/admin/company";
class ContactForm extends Component {
  state = {
    city: "",
    building_vi: "",
    building_en: "",
    address_vi: "",
    address_en: "",
    tel: "",
    fax: "",
    email: "",
    tax_number: "",
    latLng: {},
    isEdit: false
  };

  componentDidMount() {
    const { place } = this.props;
    if (place) {
      this.setState({
        city: place.city,
        fax: place.fax || "",
        building_vi: place.building_vi || "",
        building_en: place.building_en || "",
        address_vi: place.address_vi,
        address_en: place.address_en || "",
        tel: place.tel || "",
        email: place.email || "",
        latLng: place.latLng,
        tax_number: place.tax_number || ""
      });
    }
  }

  handleEdit = () => this.setState({ isEdit: true });
  handleBack = () => this.setState({ isEdit: false });

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleGetAddress = address => this.setState({ address_vi: address });
  handleGetLatLng = latLng => this.setState({ latLng });

  onSubmit = e => {
    e.preventDefault();
    const value = _.omit(this.state, "isEdit");
    this.props
      .updateContact(this.props.place.id, value)
      .then(() => {
        toast.success(
          `Thông tin ở thành phố ${this.state.city} đã được cập nhật`
        );
        this.handleBack();
        this.props.getContact();
      })
      .catch(() => {
        this.handleBack();
        toast.error("Thông tin chưa được cập nhật");
      });
  };

  render() {
    const {
      address_vi,
      address_en,
      tel,
      building_vi,
      building_en,
      email,
      fax,
      tax_number,
      isEdit
    } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <Input
          label="TÊN TÒA NHÀ - VIỆT"
          name="building_vi"
          onChange={this.handleChange}
          value={building_vi}
          disabled={!isEdit}
        />
        <Input
          label="TÊN TÒA NHÀ - ANH"
          name="building_en"
          onChange={this.handleChange}
          value={building_en}
          disabled={!isEdit}
        />
        <AutoComplete
          label="ĐỊA CHỈ - VIỆT"
          address={address_vi}
          handleGetAddress={this.handleGetAddress}
          handleGetLatLng={this.handleGetLatLng}
          disabled={!isEdit}
        />
        <Input
          label="ĐỊA CHỈ - ANH"
          name="address_en"
          onChange={this.handleChange}
          value={address_en}
          disabled={!isEdit}
        />
        <Input
          label="ĐIỆN THỌAI"
          name="tel"
          onChange={this.handleChange}
          value={tel}
          disabled={!isEdit}
        />
        <Input
          label="SỐ FAX"
          name="fax"
          onChange={this.handleChange}
          value={fax}
          disabled={!isEdit}
        />
        <Input
          label="MÃ SỐ THUẾ"
          name="tax_number"
          onChange={this.handleChange}
          value={tax_number}
          disabled={!isEdit}
        />
        <Input
          label="EMAIL"
          name="email"
          onChange={this.handleChange}
          value={email}
          disabled={!isEdit}
        />
        <div className="mBtn-wrap actions-wrap">
          {isEdit ? (
            <Fragment>
              <Button color="warning" simple onClick={this.handleBack}>
                <Back />
                <span className="btn-text">Quay lại</span>
              </Button>
              <Button color="success" simple type="submit">
                <Save />
                <span className="btn-text">Lưu</span>
              </Button>
            </Fragment>
          ) : (
            <Button color="primary" simple onClick={this.handleEdit}>
              <Edit />
              <span className="btn-text">Chỉnh sửa</span>
            </Button>
          )}
        </div>
      </form>
    );
  }
}

export default connect(
  null,
  {
    updateContact,
    getContact
  }
)(ContactForm);
