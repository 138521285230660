import React from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";

const isExpired = token => {
  let isExpired = false;
  let decodedToken = jwt.decode(token, { complete: true });
  let dateNow = Math.floor(new Date().getTime() / 1000);
  if (decodedToken.payload.exp > dateNow) {
    isExpired = true;
  }
  return isExpired;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = localStorage.getItem("token");
      return token && isExpired(token) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/auth",
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);
export default PrivateRoute;
