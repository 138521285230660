import React, { Component, Fragment } from "react";
import { reduxForm, Field } from "redux-form";
// MATERIAL
import Save from "@material-ui/icons/Save";
import Back from "@material-ui/icons/KeyboardBackspace";
import Edit from "@material-ui/icons/Edit";
// COMPONENT
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Input from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons";

class AboutUsForm extends Component {
  state = {
    disabled: true
  };
  renderField = ({ input, label, disabled, inputProps }) => {
    return (
      <Input
        labelText={label}
        formControlProps={{ fullWidth: true }}
        inputProps={{
          ...inputProps,
          ...input,
          fullWidth: true,
          multiline: true,
          disabled
        }}
      />
    );
  };
  onSubmit = dataValue => {
    this.props.handleSubmitForm(dataValue);
    this.handleToggle();
  };
  handleToggle = () => {
    this.setState({ disabled: !this.state.disabled });
  };
  render() {
    const { disabled } = this.state;
    return (
      <div>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <GridContainer>
            <GridItem xs={12}>
              <Field
                name="name"
                label="Tên công ty"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <Field
                name="ceo"
                label="Tổng giám đốc"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <Field
                name="year"
                label="Năm thành lập"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <Field
                name="employees"
                label="Số lượng nhân viên"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>

            <GridItem xs={12}>
              <Field
                name="hq"
                label="Văn phòng chính"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Field
                component={this.renderField}
                name="hq_tel"
                label="Điện thoại"
                disabled={disabled}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Field
                component={this.renderField}
                name="hq_mail"
                label="Email"
                disabled={disabled}
              />
            </GridItem>

            <GridItem xs={12}>
              <Field
                name="br"
                label="Chi nhánh"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Field
                name="br_tel"
                label="Điện thoại"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Field
                name="br_mail"
                label="Email"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
            <GridItem xs={12} md={9}>
              <Field
                name="major"
                label="Chuyên ngành"
                disabled={disabled}
                component={this.renderField}
              />
            </GridItem>
          </GridContainer>
          <div className="mBtn-wrap actions-wrap">
            {!disabled ? (
              <Fragment>
                <Button color="warning" onClick={this.handleToggle} simple>
                  <Back />
                  <span className="btn-text">quay lại</span>
                </Button>
                <Button color="success" type="submit" simple>
                  <Save />
                  <span className="btn-text">Lưu</span>
                </Button>
              </Fragment>
            ) : (
              <Button color="primary" onClick={this.handleToggle} simple>
                <Edit />
                <span className="btn-text">chỉnh sửa</span>
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: "AboutUSFormVi"
})(AboutUsForm);
