import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { withStyles, FormControl } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import selectStyles from "assets/jss/m-dashboard/customSelectStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import Editor from "components/Admin/Form/Wyswyg";
import defaultImage from "assets/img/image_placeholder.jpg";
import Heading from "components/Heading";

class CateOPForm extends Component {
  state = {
    name: "",
    thumbnail: "",
    content: "",
    title: "",
    meta_desc: "",
    meta_key: ""
  };

  componentDidMount() {
    const { initialValues } = this.props;
    if (initialValues) {
      this.onLoad(initialValues);
    }
  }

  onLoad = prop => {
    this.setState({
      name: prop.name,
      thumbnail: prop.thumbnail || "",
      content: prop.content || "",
      title: prop.title || "",
      meta_desc: prop.meta_desc || "",
      meta_key: prop.meta_key || ""
    });
  };

  //   RENDER COMPONENT
  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };

  renderEditor = ({ dataValue, placeholder }) => {
    return (
      <FormControl fullWidth>
        <Editor
          content={dataValue}
          placeholder={placeholder}
          onChange={editorState => this.props.change("content", editorState)}
        />
      </FormControl>
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  onSubmit = dataValue => this.props.submitForm(dataValue);
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  render() {
    const { name, content, thumbnail, meta_desc, meta_key, title } = this.state;
    const { initialValues, handleSubmit, pristine } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <img
              src={thumbnail ? thumbnail : defaultImage}
              alt="preview"
              className="img-fluid"
            />
          </GridItem>
          <GridItem xs={12} sm={8}>
            <Field
              component={this.renderField}
              name="thumbnail"
              label="ẢNH ĐẠI DIỆN (LINK)"
              dataValue={thumbnail}
              multiline
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              label="TÊN PHÂN LOẠI"
              name="name"
              dataValue={name}
              required
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderEditor}
              placeholder="THÔNG TIN"
              name="content"
              dataValue={content}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <div className="mt-4">
              <Heading title="NỘI DUNG SEO" />
            </div>
            <Field
              component={this.renderField}
              label="TIÊU ĐỀ"
              name="title"
              dataValue={title}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              label="TỪ KHÓA"
              name="meta_key"
              dataValue={meta_key}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={this.renderField}
              label="MÔ TẢ"
              name="meta_desc"
              dataValue={meta_desc}
            />
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          <Button
            simple
            color="danger"
            onClick={() => this.props.handleClose()}
          >
            <Close /> Đóng
          </Button>
          {initialValues ? (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Add /> lưu
            </Button>
          ) : (
            <Button
              simple
              color="success"
              disabled={pristine && true}
              type="submit"
            >
              <Add /> tạo
            </Button>
          )}
        </div>
      </form>
    );
  }
}
const styled = withStyles(selectStyles)(CateOPForm);
const formBlock = reduxForm({ form: "CateOPForm" })(styled);
export default formBlock;
