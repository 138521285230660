import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";
const API_KEY = `AIzaSyAj8DYA3wdATzT7FzjfqiQLkxY1HklgptY`;
const config = {
  apiKey: API_KEY,
  authDomain: "csp-group-web.firebaseapp.com",
  databaseURL: "https://csp-group-web.firebaseio.com",
  projectId: "csp-group-web",
  storageBucket: "gs://csp-group-web.appspot.com"
};
firebase.initializeApp(config);

const db = firebase.firestore();
const auth = firebase.auth();
const bucket = firebase.storage();
const func = firebase.functions();
const PROVIDER = {
  GOOGLE: new firebase.auth.GoogleAuthProvider()
};

export { bucket, auth, db, func, PROVIDER };
export default firebase;
