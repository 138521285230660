import React from "react";
import PropsTypes from "prop-types";
import IntlMessages from "util/IntlMessages";
import hist from "history.js";
import cx from "classnames";

InNeedBar.propTypes = {
  id: PropsTypes.string,
  className: PropsTypes.string,
  about: PropsTypes.bool
};

InNeedBar.defaultProps = {
  about: false
};

export default function InNeedBar({ id, className, about }) {
  return (
    <div className={cx("request-box", className)}>
      <div className="csp-container">
        <div className="get-demo-box module-box">
          <p>
            <IntlMessages id={id} />
          </p>
          {about ? (
            <button
              className="csp-btn csp-main-blue"
              onClick={() => hist.push("/about/company-profile")}
            >
              <IntlMessages id="btn.readmore" />
            </button>
          ) : (
            <button
              className="csp-btn csp-main-blue"
              onClick={() => hist.push("/contact")}
            >
              <IntlMessages id="home.banner.contact" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
