import React, { useEffect, useState } from "react";
import PURL from "apis/PublicURL";
import Intro from "./Intro";
import ListTop from "./ListTop";
import ListBottom from "./ListBottom";
import FadeInSection from "components/_App/FadeInSection";
import ReactGA from "react-ga";
import CustomHelmet from "pages/CustomHelmet";

// SEO ID OUR TEAM
const SEO_ID = "6Y26i42l842nH4j0kt2g";

export default function OurTeam() {
  const [detail, setDetail] = useState("");
  useEffect(() => {
    async function onFetch() {
      try {
        const res = await PURL.get(`company/seo/${SEO_ID}`);
        setDetail(res.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    onFetch();
    ReactGA.pageview(window.location.pathname);
    // return () => analytics();
  }, []);
  return (
    <section className="col-sm-12 col-lg-9 company-team">
      {detail && (
        <CustomHelmet
          detail={detail}
          imageSrc="https://firebasestorage.googleapis.com/v0/b/csp-group-web.appspot.com/o/wwa-logo.jpeg?alt=media&token=09bf3a41-a108-4177-9978-8db442a8a338"
        />
      )}
      <FadeInSection>
        <Intro />
        <ListTop />
        <ListBottom />
      </FadeInSection>
    </section>
  );
}
