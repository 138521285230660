import React, { Component } from "react";
// import ReactTable from "react-table";
import { connect } from "react-redux";
// import toast from "cogo-toast";
import Flags from "country-flag-icons/react/3x2";
// MATERIAL
import { Tooltip } from "@material-ui/core";
// import { Edit, Delete, VisibilityOff, Visibility } from "@material-ui/icons";
// COMPONENT
import Loader from "components/Admin/Loader";
import Button from "components/CustomButtons";
import history from "history.js";
// import defaultImage from "assets/img/image_placeholder.jpg";
import { getPartner, deletePartner } from "actions/admin/partner";
// import { TABLE_CONFIG, TOAST_OPTIONS } from "helpers";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridItem from "components/Grid/GridItem";
class ListPartner extends Component {
  state = {
    loading: true,
    list: ""
  };

  btnConfig = {
    justIcon: true,
    simple: true
  };

  componentDidMount() {
    this.subscriber = this.onLoad();
  }

  componentWillUnmount() {
    this.subscriber = null;
  }

  onLoad = async () => {
    await this.props.getPartner();
    this.setState({ loading: false, list: this.props.list });
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  handleEdit = (id, lang) => history.push(`/dashboard/partner/${id}/${lang}`);

  onRenderList = list => {
    return list.map((item, key) => {
      return (
        <GridItem xs={12} sm={6} md={4} key={key} className="partner-item">
          <Card className="list-box partner-box">
            <CardBody>
              <div className="title">
                <Button className="btn-title" color="white" simple size="lg">
                  {item.name}
                </Button>
              </div>
              <div className="language">
                <Tooltip title="Tiếng anh">
                  <Button
                    {...this.btnConfig}
                    onClick={() => this.handleEdit(item.id, "en")}
                  >
                    <Flags.US />
                  </Button>
                </Tooltip>
                <Tooltip title="Tiếng việt">
                  <Button
                    {...this.btnConfig}
                    onClick={() => this.handleEdit(item.id, "vi")}
                  >
                    <Flags.VN />
                  </Button>
                </Tooltip>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      );
    });
  };

  render() {
    const { loading, list } = this.state;
    if (loading) {
      return (
        <CardBody className="mb-2 mt-2">
          <Loader />
        </CardBody>
      );
    }
    return <GridContainer>{list && this.onRenderList(list)}</GridContainer>;
  }
}
const mapStateToProps = ({ partner }) => {
  return {
    list: partner.list
  };
};

export default connect(
  mapStateToProps,
  {
    getPartner,
    deletePartner
  }
)(ListPartner);
