import React, { Component } from "react";
import Accordion from "components/Accordion/Accordion";
import WhoWeAre from "./WhoWeAre";
import BannerContent from "./BannerContent";
import Info from "./Info";
import Category from "./Category";
import Stats from "./Stats";
import SEOIndex from "./SEOIndex";

class Optitex extends Component {
  render() {
    return (
      <Accordion
        collapses={[
          {
            title: "NỘI DUNG SEO",
            content: <SEOIndex />
          },
          {
            title: "THÔNG TIN",
            content: <Info />
          },
          {
            title: "WHO WE ARE || About Optitex - CSP",
            content: (
              <div style={{ width: "100%" }}>
                <BannerContent />
                <WhoWeAre />
              </div>
            )
          },
          {
            title: "THỐNG KÊ OPTITEX",
            content: (
              <div style={{ width: "100%" }}>
                <Stats />
              </div>
            )
          },
          {
            title: "MỤC OPTITEX",
            content: <Category />
          }
        ]}
      />
    );
  }
}
export default Optitex;
