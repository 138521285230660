import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PURL from "apis/PublicURL";
import IntlMessages from "util/IntlMessages";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { gCateOptitex } from "actions/landing";
import IconHeading from "assets/img/csp/ic-heading.png";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import FadeInSection from "components/_App/FadeInSection";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
// STATISTICS ID
const STATISTICS_ID = "Hgg1tCTZulToaJumhSI0";

const flow = [
  { title: "optitex.flow.01.title", text: "optitex.flow.01.text" },
  { title: "optitex.flow.02.title", text: "optitex.flow.02.text" },
  { title: "optitex.flow.03.title", text: "optitex.flow.03.text" },
  { title: "optitex.flow.04.title", text: "optitex.flow.04.text" }
];

export default function ListOptitex(props) {
  const dispatch = useDispatch();
  const locale = useSelector(({ settings }) => settings.locale.locale);
  const list = useSelector(({ landing }) => landing.cate_op);
  const [stats, setStats] = useState("");

  useEffect(() => {
    async function onFetch() {
      try {
        dispatch(gCateOptitex());
      } catch (error) {
        console.log("On Fetch failed", error.message);
      }
    }
    onFetch();
  }, [dispatch]);

  useEffect(() => {
    async function onFetchStats() {
      try {
        const response = await PURL.get(`/company/contents/${STATISTICS_ID}`);
        setStats(response.data);
      } catch (error) {
        console.log("On Fetch failed", error.message);
      }
    }
    onFetchStats();
  }, []);

  const renderFlowBox = () => {
    return flow.map((item, key) => (
      <GridItem xs={12} sm={6} lg={3} key={key} className="flow-box-wrap">
        <div className="flow-box">
          <div className="title">
            <IntlMessages id={item.title} />
          </div>
          <div className="text">
            <IntlMessages id={item.text} />
          </div>
        </div>
      </GridItem>
    ));
  };

  const renderCateOp = list => {
    return list.map((item, key) => {
      const newKey = key + 1;
      return (
        <div className="optitex-item" key={newKey}>
          <div className="optitex-index">
            {newKey > 9 ? newKey : `0${newKey}`}
          </div>
          <div className="optitex-detail">
            <GridContainer className="optitex-detail-row">
              <GridItem xs={12} sm={6} lg={5} className="optitex-detail-item">
                <div className="optitex-detail-content">
                  <h5 className="title">{item[locale].name}</h5>
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: item[locale].content }}
                  />
                  <Tooltip title={<IntlMessages id="btn.readmore" />}>
                    {newKey === 1 ? (
                      <Link
                        to={`/product/optitex/${item.id}/${item.slug}`}
                        className="btn-in-link btn-to-contact"
                      >
                        <IntlMessages id="btn.readmore" />{" "}
                        <span>
                          <i className="fas fa-angle-right"></i>
                        </span>
                      </Link>
                    ) : (
                      <Link
                        to={`/product/optitex/${item.id}/${item.slug}`}
                        className="btn-in-link btn-to-contact"
                      >
                        <IntlMessages id="btn.readmore" />{" "}
                        <span>
                          <i className="fas fa-angle-right"></i>
                        </span>
                      </Link>
                    )}
                  </Tooltip>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} lg={5} className="optitex-detail-item">
                <img src={item.thumbnail} alt={item.id} className="img-fluid" />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      );
    });
  };

  if (!list || !stats) {
    return null;
  }

  return (
    <FadeInSection>
      <section className="list-optitex bg-white section-box">
        <div className="csp-container">
          <div className="main-title-wrap optitex-flow-title">
            <h2 className="main-title">
              <img
                src={IconHeading}
                className="ic-heading"
                alt="icon-heading"
              />
              <span>
                <IntlMessages id="optitex.list.title" />
              </span>
            </h2>
          </div>
          <div className="work-flow-wrap">
            <GridContainer>{renderFlowBox()}</GridContainer>
          </div>
          <div className="stats-wrap">
            {stats && (
              <GridContainer>
                <GridItem xs={12} lg={7}>
                  <div className="stats-optitex">
                    <h4 className="stats-head-title">
                      <IntlMessages id="optitex.stats.world" />
                    </h4>
                    <div className="detail">
                      <GridContainer>
                        <StatsItem
                          colProps={{
                            xs: 12,
                            sm: 3
                          }}
                          number={stats.customers}
                          text={
                            <IntlMessages id="optitex.stats.world.customer" />
                          }
                        />
                        <StatsItem
                          colProps={{
                            xs: 12,
                            sm: 6
                          }}
                          number={stats.users}
                          text={<IntlMessages id="optitex.stats.world.user" />}
                        />
                        <StatsItem
                          colProps={{
                            xs: 12,
                            sm: 3
                          }}
                          number={stats.years}
                          text={<IntlMessages id="optitex.stats.world.year" />}
                        />
                      </GridContainer>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} lg={5}>
                  <div className="stats-csp">
                    <h4 className="stats-head-title">
                      <IntlMessages id="optitex.stats.csp" />
                    </h4>
                    <div className="detail">
                      <GridContainer>
                        <StatsItem
                          colProps={{
                            xs: 12,
                            sm: 6,
                            lg: 7
                          }}
                          number={stats.factories}
                          text={<IntlMessages id="optitex.stats.csp.factory" />}
                        />
                        <StatsItem
                          colProps={{
                            xs: 12,
                            sm: 6,
                            lg: 5
                          }}
                          number={stats.csp_years}
                          text={<IntlMessages id="optitex.stats.csp.year" />}
                        />
                      </GridContainer>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            )}
          </div>
          <div className="list-optitex">{renderCateOp(list)}</div>
        </div>
      </section>
    </FadeInSection>
  );
}

const StatsItem = ({ number, text, colProps }) => {
  return (
    <GridItem {...colProps}>
      <div className="detail-box">
        <h4 className="title">
          <CountUp end={parseInt(number)} redraw={true}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
        </h4>
        <p className="text">{text}</p>
      </div>
    </GridItem>
  );
};
