import {IMAGE} from "actions/constant";

const initialState = {
    list: ""
};

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case IMAGE.GET_IMAGE:            
            return { ...state, list: payload };

        case IMAGE.DELETE_IMAGE:
            return { ...state };
            
        default:
            return state;
    }
};
