import React from "react";

export default function ChatSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Chat"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      className="ic-chat"
    >
      <circle
        id="prefix__Ellipse_42"
        cx="22"
        cy="22"
        r="22"
        fill="#fff"
        data-name="Ellipse 42"
      />
      <g
        id="prefix__Group_432"
        data-name="Group 432"
        transform="translate(9.26 10.886)"
      >
        <path
          id="prefix__Path_181"
          d="M311.717-138.305c0-3.715-3.5-6.727-7.813-6.727s-7.814 3.012-7.814 6.727 3.5 6.727 7.814 6.727a8.806 8.806 0 0 0 3.405-.68l2.642 1.9-.623-3.115a6.3 6.3 0 0 0 2.389-4.832zm-10.26-1.263a.877.877 0 0 1-.876-.878.876.876 0 0 1 .876-.876.877.877 0 0 1 .878.876.878.878 0 0 1-.877.878zm4.954 0a.878.878 0 0 1-.878-.878.877.877 0 0 1 .878-.876.876.876 0 0 1 .876.876.877.877 0 0 1-.875.878z"
          className="prefix__cls-2"
          data-name="Path 181"
          transform="translate(-286.238 152.439)"
        />
        <path
          id="prefix__Path_182"
          d="M306.291-144.081a9.907 9.907 0 0 1 1.424.111c-.75-3.784-4.7-6.674-9.476-6.674-5.311 0-9.615 3.57-9.615 7.973a7.366 7.366 0 0 0 2.939 5.73l-.765 3.691 3.251-2.254a11.213 11.213 0 0 0 3.841.788 6.607 6.607 0 0 1-.256-1.795c0-4.174 3.883-7.57 8.657-7.57zm-4.933-3.013a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1 1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1zm-6.219 2.2a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1 1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.103z"
          className="prefix__cls-2"
          data-name="Path 182"
          transform="translate(-288.625 150.644)"
        />
      </g>
    </svg>
  );
}
