import React from "react";
import Flags from "country-flag-icons/react/3x2";

const LanguageItem = ({ language, switchLanguage, handleRequestClose }) => {
  const { icon, name } = language;
  return (
    <li
      className="pointer"
      onClick={() => {
        handleRequestClose();
        switchLanguage(language);
      }}
    >
      <div className="menu-language">
        {icon === "vn" ? <Flags.VN /> : <Flags.US />}
        <p>{name}</p>
      </div>
    </li>
  );
};

export default LanguageItem;
