import { makeStyles } from "@material-ui/core/styles";
const dockStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  closeBtn: {
    color: "#fff"
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px"
    }
  }
}));

export default dockStyles;
