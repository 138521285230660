import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import toast from "cogo-toast";
import FileUploader from "react-firebase-file-uploader";
import { bucket } from "apis/config";
import {
  withStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  LinearProgress
} from "@material-ui/core";
import {
  Save,
  Add,
  KeyboardBackspace as Back,
  Close
} from "@material-ui/icons";
import selectStyles from "assets/jss/m-dashboard/customSelectStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons";
import Input from "components/CustomInput/CustomInput";
import history from "history.js";
import { TOAST_OPTIONS } from "helpers";

const category = [
  { name: "News", id: "news" },
  { name: "Events", id: "events" }
];

class PostForm extends Component {
  state = {
    category: "",
    title: "",
    content: "",
    desc: "",
    images: [],
    isUploading: false,
    uploadProgress: 0
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    if (this.props.initialValues) {
      this.onLoadField(this.props.initialValues);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //   ---- ON LOAD FIELD
  onLoadField = prop => {
    if (this._isMounted) {
      this.setState({
        category: prop.category,
        title: prop.title,
        content: prop.content,
        desc: prop.desc || "",
        images: prop.images || []
      });
    }
  };

  // ------ RENDER COMPONENT
  renderField = ({ input, label, dataValue, ...rest }) => {
    return (
      <Input
        formControlProps={{ fullWidth: true }}
        labelText={label}
        inputProps={{
          ...rest,
          name: input.name,
          value: dataValue,
          onChange: this.handleChange
        }}
      />
    );
  };
  renderSelect = ({ input, label, children, dataValue }) => {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          inputProps={{ name: input.name, value: dataValue }}
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          onChange={this.handleChange}
        >
          {children}
        </Select>
      </FormControl>
    );
  };
  renderCategory = () => {
    const { classes } = this.props;
    return category.map((prop, key) => (
      <MenuItem
        key={key}
        value={prop.id}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected
        }}
      >
        {prop.name.toUpperCase()}
      </MenuItem>
    ));
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => this.props.change(name, value));
  };

  // === ON SUBMIT ===
  onSubmit = dataValue => {
    const { title, category, images } = this.state;
    const init_data = {
      ...dataValue,
      images
    };

    if (!title) {
      toast.warn(`Tiêu đề bài viết không được để trống`, TOAST_OPTIONS);
      return;
    }

    if (!category) {
      toast.warn(`Mục phân loại bài viết cần được chọn`, TOAST_OPTIONS);
      return;
    }
    this.props.submitForm(init_data);
  };

  // UPLOAD IMAGE
  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0
    });

  handleProgress = progress =>
    this.setState({
      uploadProgress: progress
    });
  handleUploadSuccess = async filename => {
    const imgURL = await bucket
      .ref("news-events")
      .child(filename)
      .getDownloadURL();
    this.setState(oldState => ({
      images: [...oldState.images, imgURL],
      uploadProgress: 100,
      isUploading: false
    }));
  };

  onDeletePhoto = async url => {
    const { images } = this.state;
    const index = images.findIndex(x => x === url);
    if (index < 0) return;
    const update_images = [...images];
    update_images.splice(index, 1);
    this.setState({ images: update_images });
  };

  onBack = () => history.goBack();
  render() {
    const {
      category,
      title,
      images,
      desc,
      isUploading,
      uploadProgress
    } = this.state;
    const { initialValues } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="mt-3">
        <GridContainer justify="center">
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={12} md={8}>
                <Field
                  component={this.renderField}
                  name="title"
                  label="TIÊU ĐỀ"
                  dataValue={title}
                  multiline
                />
              </GridItem>
              <GridItem xs={12} md={4}>
                <Field
                  component={this.renderSelect}
                  name="category"
                  label="MỤC"
                  dataValue={category}
                >
                  {this.renderCategory()}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  component={this.renderField}
                  name="desc"
                  label="NỘI DUNG"
                  dataValue={desc}
                  multiline
                />
              </GridItem>
              <GridItem xs={12}>
                <label className="btn-labelImg">
                  Hình ảnh bổ sung
                  <FileUploader
                    hidden
                    multiple
                    accept="image/*"
                    name="images"
                    randomizeFilename
                    storageRef={bucket.ref("news-events")}
                    onUploadSuccess={this.handleUploadSuccess}
                    onUploadStart={this.handleUploadStart}
                    onProgress={this.handleProgress}
                  />
                </label>
                {isUploading && (
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                )}
                <GridContainer>
                  {images &&
                    images.map((item, key) => (
                      <GridItem xs={12} md={3} key={key}>
                        <div className="pr-img text-center mb-4">
                          <div
                            className="preview-thumbnail"
                            style={{ backgroundImage: `url(${item})` }}
                          />
                          <Button
                            simple
                            color="danger"
                            onClick={() => this.onDeletePhoto(item)}
                          >
                            <Close /> xóa ảnh
                          </Button>
                        </div>
                      </GridItem>
                    ))}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <div className="mBtn-wrap actions-wrap">
          <Button color="warning" simple onClick={this.onBack}>
            <Back />
            <span className="btn-text">quay lại</span>
          </Button>
          {initialValues ? (
            <Button type="submit" simple color="success">
              <Save />
              <span className="btn-text">Lưu</span>
            </Button>
          ) : (
            <Button type="submit" simple color="success">
              <Add />
              <span className="btn-text">tạo</span>
            </Button>
          )}
        </div>
      </form>
    );
  }
}
const styleBlock = withStyles(selectStyles)(PostForm);
export default reduxForm({ form: "basePost" })(styleBlock);
