import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Card from "components/Card/Card";
import Loader from "components/Admin/Loader";
import CardBody from "components/Card/CardBody";
import BasePartner from "components/Admin/Form/BaseForm/Partner";
import { addPartner } from "actions/admin/partner";
import history from "history.js";
import { TOAST_OPTIONS } from "helpers";

class CreatePartner extends Component {
  state = {
    loading: false
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onSubmit = async dataValue => {
    this.onLoader();
    try {
      await this.props.addPartner(dataValue);
      toast.success(`Đối tác đã được thêm vào`, TOAST_OPTIONS);
      history.push("/dashboard/partner");
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  render() {
    const { loading } = this.state;
    return (
      <Card>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <CardBody>
          <BasePartner submitForm={this.onSubmit} />
        </CardBody>
      </Card>
    );
  }
}
export default connect(
  null,
  { addPartner }
)(CreatePartner);
