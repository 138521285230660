import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import photoReducer from "./admin/photoReducer";
import videoReducer from "./admin/videoReducer";
import authReducer from "./admin/authReducer";
import postReducer from "./admin/postReducer";
import productReducer from "./admin/productReducer";
import partReducer from "./admin/partReducer";
import clientReducer from "./admin/clientReducer";
import partnerReducer from "./admin/partnerReducer";
import companyReducer from "./admin/companyReducer";
import seoReducer from "./admin/seoReducer";
import landingReducer from "./landingReducer";
import settingReducer from "./admin/settingReducer";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    photo: photoReducer,
    video: videoReducer,
    auth: authReducer,
    post: postReducer,
    product: productReducer,
    product_part: partReducer,
    client: clientReducer,
    partner: partnerReducer,
    company: companyReducer,
    seo: seoReducer,
    landing: landingReducer,
    settings: settingReducer
  });

export default rootReducer;
