import React from "react";
import Loader from "react-loaders";
export default function LineScaleLoader(props) {
  return (
    <div className="loader-wrapper">
      <Loader type="line-scale" />
      {props.children && <p>{props.children}</p>}
    </div>
  );
}
