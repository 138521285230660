import React, { Component } from "react";
// Material Core
import { List } from "@material-ui/icons";
// Component
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Accordion from "components/Accordion/Accordion";
import NavPills from "components/NavPills/NavPills";
import ListProduct from "./component/ListProduct";
import Optitex from "./Optitex";
import Parts from "./Parts";

class Product extends Component {
  render() {
    return (
      <NavPills
        color="primary"
        tabs={[
          {
            tabButton: "Sản phẩm",
            tabIcon: List,
            tabContent: (
              <Card>
                <CardBody>
                  <Accordion
                    collapses={[
                      {
                        title: "OPTITEX",
                        content: <Optitex />
                      },
                      {
                        title: "MÁY",
                        content: <ListProduct />
                      },
                      {
                        title: "LINH KIỆN",
                        content: <Parts />
                      }
                    ]}
                  />
                </CardBody>
              </Card>
            )
          }
        ]}
      />
    );
  }
}

export default Product;
