import React from "react";
import Accordion from "components/Accordion/Accordion";
import Banner from "./Banner";
import Wwa from "./Wwa";
import Wcu from "./Wcu";
import Achievement from "./Achievement";
import BannerService from "./BannerService";
import SEOIndex from "./SEOIndex";
import SEOService from "./SEOService";

export default function Content() {
  return (
    <Accordion
      collapses={[
        {
          title: "BANNER TRANG CHỦ",
          content: <Banner />
        },
        {
          title: "WHO WE ARE",
          content: <Wwa />
        },
        {
          title: "WHY CHOOSE US",
          content: <Wcu />
        },
        {
          title: "THÀNH TỰU",
          content: <Achievement />
        },
        {
          title: "SEO TRANG CHỦ",
          content: <SEOIndex />
        },
        {
          title: "BANNER DỊCH VỤ",
          content: <BannerService />
        },
        {
          title: "SEO DỊCH VỤ",
          content: <SEOService />
        },
      ]}
    />
  );
}
