import React, { useState, useEffect } from "react";
import PURL from "apis/PublicURL";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import bgAchievement from "assets/img/csp/bg-achievement.jpg";
import IconAccess from "assets/img/csp/ic-access.png";
import IconPerson from "assets/img/csp/ic-person.png";
import IconFlag from "assets/img/csp/ic-flag.png";
import IconDollar from "assets/img/csp/ic-dollar.png";
import IconHeading from "assets/img/csp/ic-heading.png";
import IntlMessages from "util/IntlMessages";
import FadeInSection from "components/_App/FadeInSection";
// ACHIEVEMENT - HOME ID
const ACHIEVEMENT_ID = "C1QEOgseyhg8haPIQXTK";
export default function Achievement() {
  const [detail, setDetail] = useState("");

  const locale = useSelector(({ settings }) => settings.locale.locale);

  useEffect(() => {
    async function onFetch() {
      const response = await PURL.get(`/company/contents/${ACHIEVEMENT_ID}`);
      setDetail(response.data);
    }
    onFetch();
  }, []);

  if (!detail) {
    return null;
  }

  return (
    <FadeInSection>
      <section
        className="achievement-section section-box bg-section"
        style={{ backgroundImage: `url(${bgAchievement})` }}
      >
        <div className="csp-container">
          <div className="main-title-wrap">
            <h2 className="main-title">
              <img
                src={IconHeading}
                className="ic-heading"
                alt="icon-heading"
              />
              <span>
                <IntlMessages id="home.achievement.title" />
              </span>
            </h2>
          </div>
          <div className="achievement_content">
            <p>{detail[`content_${locale}`]}</p>
          </div>
          <div className="achievement_symbol">
            <GridContainer alignItems="center">
              <GridItem xs={12} sm={6} md={3}>
                <AchievementItem
                  src={IconAccess}
                  number={detail.years}
                  text={<IntlMessages id="home.achievement.year" />}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <AchievementItem
                  src={IconPerson}
                  number={detail.clients}
                  text={<IntlMessages id="home.achievement.client" />}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <AchievementItem
                  src={IconDollar}
                  number={detail.deals}
                  text={<IntlMessages id="home.achievement.deal" />}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <AchievementItem
                  src={IconFlag}
                  number={detail.countries}
                  text={<IntlMessages id="home.achievement.country" />}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </section>
    </FadeInSection>
  );
}

const AchievementItem = ({ src, number, text }) => {
  return (
    <div className="achievement_item">
      <img src={src} className="img-fluid" alt={`${number}-${text}`} />
      <p>
        <CountUp end={parseInt(number)} redraw={true}>
          {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
              <span ref={countUpRef} />
            </VisibilitySensor>
          )}
        </CountUp>
        <span>{text}</span>
      </p>
    </div>
  );
};
