import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
// COMPONENT
import Loader from "components/Admin/Loader";
import ProductOP from "components/Admin/Form/BaseForm/ProductOP";
import { updateProductOP, getProductOP } from "actions/admin/product";
import { TOAST_OPTIONS } from "helpers";

class DetailOP extends Component {
  state = {
    loading: false,
    detail: this.props.detail,
    lang: this.props.lang
  };

  // --- LOADING
  onLoader = () => this.setState({ loading: true });
  endLoader = () => this.setState({ loading: false });
  // ---- END LOADING

  onSubmit = async dataValue => {
    const { detail, lang } = this.state;
    this.onLoader();
    try {
      await this.props.updateProductOP(detail.id, lang, dataValue);
      await this.props.getProductOP();
      toast.success(`Sản phẩm đã được cập nhật thành công`, TOAST_OPTIONS);
      this.onClose();
    } catch (error) {
      toast.error(error.message, TOAST_OPTIONS);
    }
    this.endLoader();
  };

  onClose = () => {
    this.props.handleClose();
  };

  render() {
    const { loading, detail } = this.state;
    return (
      <div>
        {loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <ProductOP
          submitForm={this.onSubmit}
          handleClose={this.onClose}
          initialValues={detail}
        />
      </div>
    );
  }
}
export default connect(
  null,
  { updateProductOP, getProductOP }
)(DetailOP);
