import React, { Component } from "react";
import { connect } from "react-redux";
import toast from "cogo-toast";
import Loader from "components/Admin/Loader";
import HRForm from "./Form";
import { updateEmployee, getEmployees } from "actions/admin/company";

class DetailHR extends Component {
  state = {
    loading: false
  };

  handleSubmitForm = value => {
    const { detail, lang } = this.props;
    this.setState({ loading: true });
    this.props
      .updateEmployee(detail.id, lang, value)
      .then(() => {
        this.props.getEmployees();
        this.handleClose();
        toast.success(`Thông tin nhân sự được cập nhật`);
      })
      .catch(err => {
        toast.error(err.message);
      });
  };

  handleClose = () => {
    this.setState({ loading: false });
    this.props.handleClose();
  };

  render() {
    return (
      <div className="position-relative">
        {this.state.loading && (
          <div className="full-loading">
            <Loader />
          </div>
        )}
        <HRForm
          handleSubmitForm={this.handleSubmitForm}
          initialValues={this.props.detail}
          handleClose={this.handleClose}
        />
      </div>
    );
  }
}
export default connect(
  null,
  {
    updateEmployee,
    getEmployees
  }
)(DetailHR);
